import { Grid, Link } from "@material-ui/core";
import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import React, { useContext } from "react";
import { iconHouse, iconcommercial } from "../../../Content/EmbeddedSVGs";

import { StoresInstance } from "../../Stores";

interface IProps {
	propertyChoice: (chosenVal: string) => void;
}

export const Choices: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;
	let activeValue: string = domainStores.QuoteRequestViewModel.getValue("buildingType") as string;

	const panelActive = (panelValue: any) => {
		let retVal: string = "panelIcon";

		if (activeValue === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: string) => {
		domainStores.QuoteRequestViewModel.setValue("buildingType", clickVal);
		props.propertyChoice(clickVal);
	};

	return (
		<>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={2} md={2} className={panelActive("residential")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("residential")}>
							{iconHouse}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("residential")} className="panelLink">
						Residential
					</Link>
				</GridSpaced>
				{/* <GridSpaced item xs={3} md={3} className={panelActive("complex")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("complex")}>
							<img src={iconmultiFloorComplex} alt={"No"} />
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("complex")} className="panelLink">
						Multi-Floor Complex
					</Link>
				</GridSpaced> */}
				<GridSpaced item xs={2} md={2} className={panelActive("commercial")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("commercial")}>
							{iconcommercial}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("commercial")} className="panelLink">
						Commercial
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);
};
