import { action, computed, observable, runInAction } from "mobx";
import { FloodDamageModel, FloodDamageModelDTO } from "../Models/FloodDamageModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class FloodDamageViewModel extends ViewModelBase<FloodDamageModel> {
	constructor() {
		super(new FloodDamageModel());
		this.setDecorators(FloodDamageModel);
	}

	public isFieldValid(fieldName: keyof FloodDamageModel & string, value: any): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		//You need to this two properties after validation
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}
}
