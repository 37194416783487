import { AltSelect, SterlingForm, SterlingText } from "Shoothill.Components/StylesAppSpecific/FormStyling";
import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Link, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { iconCommercial4Tile, iconcommercial } from "../../../Content/EmbeddedSVGs";

import { EditableInput } from "../../../Core/Components/EditableInput";
import { KeyValuePair } from "../../../Core/Models/KeyValuePair";
import { QuoteRequestModel } from "Custom/Models";
import { StoresInstance } from "../../Stores";
import { useMediaQuery } from "../../../Core/Utils/Media";
import { useObserver } from "mobx-react-lite";

interface IProps {
	commericalChoice: (chosenVal: string) => void;
}
export const CommercialList: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;
	const [commericalItems, setCommercialItems] = useState<JSX.Element[]>([]);

	const [commericalChoice, setCommercialChoice] = useState<string>(domainStores.QuoteRequestViewModel.getValue(
		"buildingUse",
	) as string);

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	let commercialArr: KeyValuePair[] = [
		{ value: "Airport", key: "Airport" },
		{
			value: "AmusementParkorFairground",
			key: "Amusement Park or Fairground",
		},
		{ value: "AuditoriumorArena", key: "Auditorium or Arena" },
		{ value: "AutoDealerServiceRepair", key: "Auto Dealer Service Repair" },
		{
			value: "AutoDealerShowroomOffice",
			key: "Auto Dealer Showroom Office",
		},
		{ value: "Bakery", key: "Bakery" },
		{ value: "BarorTavern", key: "Bar or Tavern" },
		{ value: "BeautySalon", key: "Beauty Salon" },
		{ value: "BoatDealer", key: "Boat Dealer" },
		{ value: "BoatYardorMarina", key: "Boat Yard or Marina" },
		{ value: "BowlingAlley", key: "Bowling Alley" },
		{ value: "BreweryorDistillery", key: "Brewery or Distillery" },
		{ value: "BuildingSupplies", key: "Building Supplies" },
		{ value: "Camp", key: "Camp" },
		{ value: "Casino", key: "Casino" },
		{ value: "CementPlant", key: "Cement Plant" },
		{
			value: "ChemicalorFertilizerRisks",
			key: "Chemical or Fertilizer Risks",
		},
		{ value: "Church", key: "Church" },
		{
			value: "ClothingRetailorWholesale",
			key: "Clothing Retail or Wholesale",
		},
		{ value: "ClubHealthSocialSport", key: "Club Health Social Sport" },
		{
			value: "ContractorEquipmentDealer",
			key: "Contractor Equipment Dealer",
		},
		{ value: "ConvenienceStore", key: "Convenience Store" },
		{ value: "ConventionCenter", key: "Convention Center" },
		{ value: "DayCareCenter", key: "Day Care Center" },
		{ value: "DepartmentStore", key: "Department Store" },
		{ value: "DrugsStore", key: "Drugs Store" },
		{ value: "DryCleaner", key: "Dry Cleaner" },
		{ value: "ExhibitionHall", key: "Exhibition Hall" },
		{ value: "FineArtsDealers", key: "Fine Arts Dealers" },
		{ value: "FoodProcessing", key: "Food Processing" },
		{ value: "FuneralHomeorMortuary", key: "Funeral Home or Mortuary" },
		{
			value: "FurnitureRetailorWholesale",
			key: "Furniture Retail or Wholesale",
		},
		{ value: "GasolineStations", key: "Gasoline Stations" },
		{ value: "GrocersSmallRetail", key: "Grocers Small Retail" },
		{ value: "GrocerySupermarket", key: "Grocery Supermarket" },
		{ value: "GroceryWarehouse", key: "Grocery Warehouse" },
		{ value: "HabitationalApartments", key: "Habitational Apartments" },
		{ value: "HabitationalCondominium", key: "Habitational Condominium" },
		{ value: "HabitationalMultiFamily", key: "Habitational Multi Family" },
		{
			value: "HardwareRetailorWholesale",
			key: "Hardware Retail or Wholesale",
		},
		{ value: "Hospital", key: "Hospital" },
		{ value: "HotelorMotel", key: "Hotel or Motel" },
		{
			value: "JewelryRetailorWholesale",
			key: "Jewelry Retail or Wholesale",
		},
		{ value: "Kennels", key: "Kennels" },
		{ value: "LaboratoryResearch", key: "Laboratory Research" },
		{
			value: "LaboratoryEquipmentorSupplies",
			key: "Laboratory Equipment or Supplies",
		},
		{ value: "Laundry", key: "Laundry" },
		{ value: "Library", key: "Library" },
		{ value: "ManufacturingGeneral", key: "Manufacturing General" },
		{ value: "ManufacturingElectronics", key: "Manufacturing Electronics" },
		{
			value: "ManufacturingAutoorBoats",
			key: "Manufacturing Auto or Boats",
		},
		{ value: "Winery", key: "Winery" },
		{ value: "Woodworkers", key: "Woodworkers" },
	];

	const onClickDecide = (clickVal: string) => {
		// should be a submit button rather than use click event
		props.commericalChoice(domainStores.QuoteRequestViewModel.getValue("buildingUse") as string);
	};

	const handleSelectChange = (clickVal: any) => {
		let chosenType: string = clickVal as string;
		domainStores.QuoteRequestViewModel.setValue("buildingUse", chosenType);
		props.commericalChoice(chosenType);
	};

	const rowOne = useObserver(() => (
		<>
			<SterlingForm onSubmit={onClickDecide} id="flooddamageForm">
				<GridSpaced container>
					<GridSpaced item xs={12}>
						<GridSpaced container margin={"0px 0px 15px"}>
							<GridSpaced item xs={12} additionalcss={"text-align:center;"}>
								<p>Please select the primary business activity at the commercial property</p>
							</GridSpaced>
							<GridSpaced
								item
								xs={12}
								margin={"25px 0px 0px"}
								additionalcss={"text-align:center;"}
								className={"commercial-select-parent"}
							>
								<SterlingText<QuoteRequestModel>
									viewModel={domainStores.QuoteRequestViewModel}
									type="select"
									fieldName="buildingUse"
									selectItems={commercialArr}
									fullwidth={true}
									onChange={handleSelectChange}
								/>
							</GridSpaced>
						</GridSpaced>
					</GridSpaced>
				</GridSpaced>
			</SterlingForm>
		</>
	));

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={
					isMobile
						? "text-align: center; font-weight: bold;"
						: "text-align: center; svg {width: 64px;} display: flex; align-items: center; justify-content: center; font-weight: bold;"
				}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 30px"}
			>
				<div
					style={{
						width: "38px",
						height: "57px",
						padding: "0px",
						marginRight: "32px",
					}}
				>
					{iconCommercial4Tile}
				</div>
				What kind of business takes place in the commercial property?
			</H1TitleAndImg>
		</>
	);

	return (
		<GridSpaced container>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={12}>
					{headingTitle}
				</GridSpaced>
			</GridSpaced>
			<GridSpaced container direction="row" justify="center" alignItems="center" padding={"10px;"}>
				<GridSpaced item xs={12} md={12}>
					{rowOne}
				</GridSpaced>
			</GridSpaced>
		</GridSpaced>
	);
};
