/*global google*/
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useObserver } from "mobx-react-lite";
import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";
import MapMarkerSvg from "../../../Content/icon-mapPin.svg";
import { StoresInstance } from "../../Stores/Stores";

const image: HTMLImageElement = new Image(42, 64);
image.src = MapMarkerSvg;
const apiKey: string = "AIzaSyCO3sgeE-AaHuEdPFhfvQ61lsqyK5bUF0o";
const DefaultZoom: number = 18;

const useStyles: any = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: "#FFFFFF",
		width: "100%",
		"@media (min-width: 768px)": {
			minHeight: "100%",
		},
		borderBottomLeftRadius: "5px",
		borderTopLeftRadius: "5px",
	},
	map: {
		overflow: "none",
		width: "1020px",
		minHeight: "550px",
		"@media (max-width: 767px)": {
			minHeight: "190px",
		},
		borderTopLeftRadius: "5px",
		borderBottomLeftRadius: "5px",
	},
}));

export const GoogleMap: React.FC = () => {
	const classes: Record<"root" | "map", string> = useStyles("map");
	const zoom: number = DefaultZoom;
	const domainStores = StoresInstance.domain;
	const [lat, setLat] = React.useState<any>(
		domainStores.QuoteRequestViewModel.getValue("latitude")
			? (domainStores.QuoteRequestViewModel.getValue("latitude") as number)
			: 42.317877,
	);
	const [lng, setLng] = React.useState<any>(
		domainStores.QuoteRequestViewModel.getValue("longitude")
			? (domainStores.QuoteRequestViewModel.getValue("longitude") as number)
			: -71.2376827,
	);
	const [latLng, setLatLng] = React.useState<any>(
		new google.maps.LatLng(
			domainStores.QuoteRequestViewModel.getValue("latitude")
				? (domainStores.QuoteRequestViewModel.getValue("latitude") as number)
				: 42.317877,
			domainStores.QuoteRequestViewModel.getValue("longitude")
				? (domainStores.QuoteRequestViewModel.getValue("longitude") as number)
				: -71.2376827,
		),
	);

	var geocoder: google.maps.Geocoder = new google.maps.Geocoder();

	function handleLatChange(lat: any): void {
		setLat(lat as string);
	}

	function handleLngChange(lng: any): void {
		setLng(lng as string);
	}

	function handleOnDragEnd(event: google.maps.MouseEvent): void {
		if (event) {
			var latLng = new google.maps.LatLng(
				parseFloat(event.latLng.lat().toString()),
				parseFloat(event.latLng.lng().toString()),
			);
			setLatLng(latLng);
		}
	}

	useEffect(() => {
		geocoder.geocode({ location: latLng }, function(
			// tslint:disable-next-line: typedef
			results,
		) {
			if (results[0]) {
				const lat: any = results[0].geometry.location.lat();
				const lng: any = results[0].geometry.location.lng();
				domainStores.QuoteRequestViewModel.setValue("latitude", lat);
				domainStores.QuoteRequestViewModel.setValue("longitude", lng);
				handleLatChange(lat);
				handleLngChange(lng);
			} else {
				window.alert("No results found");
			}
		});
	}, [latLng]);

	var img: google.maps.Icon = {
		url: MapMarkerSvg,
		scaledSize: new google.maps.Size(58, 72),
	};

	return useObserver(() => (
		<div className={classes.root + " mapRoot"}>
			<GoogleMapProvider key="mainmap">
				<MapBox
					apiKey={apiKey}
					className={classes.map + " mobMap"}
					opts={{
						zoom: zoom,
						center: {
							lat: parseFloat(lat),
							lng: parseFloat(lng),
						},
						fullscreenControl: false,
						mapTypeControl: false,
						streetViewControl: false,
						mapTypeId: google.maps.MapTypeId.SATELLITE,
					}}
					onCenterChanged={() => {
						// console.log("The center of the map has changed.");
					}}
				/>
				<Marker
					id="marker"
					opts={{
						draggable: true,
						position: {
							lat: parseFloat(lat),
							lng: parseFloat(lng),
						},
						optimized: false,
						icon: img,
					}}
					onDragEnd={handleOnDragEnd}
				/>
			</GoogleMapProvider>
		</div>
	));
};
