import { React, styled, useRouter, useObserver, useState, useEffect, StoresInstance } from "../../Globals/Base";
import * as Mui from "@material-ui/core";
import UserViewModel from "../../../Custom/ViewModels/UserViewModel";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { User } from "../../Models/User";

const domainStores = StoresInstance.domain;
export const Container = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
	position: relative;
`;
const userViewModel = new UserViewModel();

export const UserDetails: React.FC = () => {
	const { history, match } = useRouter();
	const [newUser, setNewUser] = useState(false);

	let { id } = match.params as any;

	React.useEffect(() => {
		if (id === "0") {
			setNewUser(true);
		}
	}, []);

	React.useEffect(() => {
		if (history.location.pathname === "/userlist/user/0") {
			setNewUser(true);
		}
	}, [history.location.pathname]);

	return useObserver(() => (
		<Container>
			<Mui.Box marginTop="45px" marginBottom="10px" display="flex">
				<Mui.Box flexGrow={1}>
					<Mui.Typography variant="h1" color="textPrimary">
						User
					</Mui.Typography>
				</Mui.Box>
			</Mui.Box>
			<hr />
			<DetailPanel newUser={newUser} id={id} />
		</Container>
	));
};

interface DetailPanelProps {
	newUser: boolean;
	id: string;
}
export const DetailPanel: React.FC<DetailPanelProps> = props => {
	let user: User = new User("");

	//const [editable, seteditable] = useState(false);
	const [loader, setLoader] = useState(false);
	const { history } = useRouter();
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
		if (props.id !== "0") {
			let currentUser = userViewModel.getUser(props.id);
			if (currentUser) {
				userViewModel.setUser(currentUser, false);
			}
		} else {
			userViewModel.setUser(user, true);
		}
	}, []);

	const doSubmit = (e: any) => {
		e.preventDefault();
		if (!userViewModel.isModelValid()) {
			setErrorMessage("Problem");
		} else {
			setErrorMessage("");
			setLoader(true);
			userViewModel.postUserDetailAsync().then((data: User) => {
				setLoader(false);
				domainStores.ShowSnackBar("User saved successfully");
				history.push("/admin");
			});
		}
	};

	return useObserver(() => (
		<form onSubmit={doSubmit} id="detailsPanelForm">
			<DetailContainer>
				{errorMessage !== "" && (
					<Mui.Typography variant="caption" style={{ color: "red" }}>
						{errorMessage}
					</Mui.Typography>
				)}
				<Mui.Box marginTop="-70px" display="flex" textAlign="right">
					<Mui.Box flexGrow={1}>
						<Mui.Box display="flex">
							<Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
								<Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
									{!loader ? "Save" : "Saving"}
								</Mui.Button>
								<span style={{ paddingRight: "20px" }} />
								<Mui.Button
									id="cancel"
									variant="contained"
									color="secondary"
									onClick={() => {
										history.push("/admin");
									}}
								>
									Cancel
								</Mui.Button>
							</Mui.Box>
						</Mui.Box>
					</Mui.Box>
				</Mui.Box>

				<>
					<Mui.Grid container>
						<Mui.Grid item md={4}>
							<Mui.FormControl>
								<EditableInput viewModel={userViewModel} label="Username" fieldName="email" />
							</Mui.FormControl>
						</Mui.Grid>
					</Mui.Grid>
				</>
				<Mui.Grid container>
					<Mui.Grid md={4} item>
						<Mui.FormControl>
							<EditableInput viewModel={userViewModel} label="First name" fieldName="firstName" />
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid container>
					<Mui.Grid md={4} item>
						<Mui.FormControl>
							<EditableInput viewModel={userViewModel} label="Last name" fieldName="lastName" />
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid container>
					<Mui.Grid md={4} item>
						<Mui.FormControl>
							<EditableInput viewModel={userViewModel} label="Password" fieldName="password" />
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
			</DetailContainer>
		</form>
	));
};
