import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { iconHouse4Tile, iconRental, iconSeasonal, iconSecondary, iconVacant } from "../../../Content/EmbeddedSVGs";

import { Link } from "@material-ui/core";
import { ProcessFlowStore } from "../../Stores/Domain";
import { QuoteRequestViewModel } from "Custom/ViewModels";
import { useMediaQuery } from "../../../Core/Utils/Media";

let quoteRequestViewModel = new QuoteRequestViewModel();

interface IProps {
	residenceUsage: (chosenVal: number) => void;
	propertyTitlePart: string;
}

export const ResidenceUsage: React.FC<IProps> = props => {
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	let activeValue: string = ProcessFlowStoresContext.ResidenceUsage;

	const panelActive = (panelValue: any) => {
		let retVal: string = "panelIcon";

		if (activeValue === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: string) => {
		ProcessFlowStoresContext.SetResidenceUsage(clickVal);

		//quoteRequestViewModel.setBuildingUse(clickVal.toLocaleLowerCase());

		props.residenceUsage(3);
	};

	const rowOne = (
		<>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={6} sm={3} className={panelActive("Secondary")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Secondary")}>
							{iconSecondary}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Secondary")} className="panelLink">
						Secondary
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Seasonal")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Seasonal")}>
							{iconSeasonal}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Seasonal")} className="panelLink">
						Seasonal
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Rental")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Rental")}>
							{iconRental}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Rental")} className="panelLink">
						Rental
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Vacant")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Vacant")}>
							{iconVacant}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Vacant")} className="panelLink">
						Vacant
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={isMobile ? "text-align: center;" : "text-align: center; svg {width: 64px;}"}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 30px"}
			>
				{iconHouse4Tile}
				What is the use of this residence?
			</H1TitleAndImg>
		</>
	);

	return (
		<>
			<GridSpaced item xs={12}>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12}>
						{headingTitle}
					</GridSpaced>
				</GridSpaced>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={12}>
						{rowOne}
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	);
};
