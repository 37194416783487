import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";
/* import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator"; */

export class QuoteModel extends ModelBase<QuoteModel, QuoteModelDTO> {
	@MobX.observable
	public id: string = "";

	@MobX.observable
	public quoteRequestInternalId: string = "";

	@MobX.observable
	public quoteResponseInternalId: string = "";

	@MobX.observable
	public quoteId: string = "";

	@MobX.observable
	public externalQuoteId: string = "";

	@MobX.observable
	public coverageDeductible: number = 0;

	@MobX.observable
	public coverageBuilding: number = 0;

	@MobX.observable
	public coverageContents: number = 0;

	@MobX.observable
	public coverageLossOfUse: number = 0;

	@MobX.observable
	public premiumBuilding: string = "";

	@MobX.observable
	public premiumContents: string = "";

	@MobX.observable
	public premiumLossOfUse: string = "";

	@MobX.observable
	public primaryCredit: string = "";

	@MobX.observable
	public primaryTotal: string = "";

	@MobX.observable
	public excessTotal: string = "";

	@MobX.observable
	public excessCredit: string = "";

	@MobX.observable
	public premiumTotal: string = "";

	@MobX.observable
	public feeTotal: string = "";

	@MobX.observable
	public stampTotal: string = "";

	@MobX.observable
	public taxTotal: string = "";

	@MobX.observable
	public quoteTotal: string = "";

	@MobX.observable
	public contractNumber: string = "";

	@MobX.observable
	public createdDate: string = "";

	public fromDto = (model: QuoteModelDTO) => {};
	public toDto = (model: QuoteModel) => {};
}

export interface QuoteModelDTO {
	id: string;
	quoteRequestInternalId: string;
	quoteResponseInternalId: string;
	quoteId: string;
	externalQuoteId: string;
	coverageDeductible: number;
	coverageBuilding: number;
	coverageContents: number;
	coverageLossOfUse: number;
	premiumBuilding: string;
	premiumContents: string;
	premiumLossOfUse: string;
	primaryCredit: string;
	primaryTotal: string;
	excessTotal: string;
	excessCredit: string;
	premiumTotal: string;
	feeTotal: string;
	stampTotal: string;
	taxTotal: string;
	quoteTotal: string;
	contractNumber: string;
	createdDate: string;
}
