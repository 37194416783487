import { BaseStore } from "./BaseStore";
import { observable, action, computed } from "mobx";
import { GeocodeResult } from "../../Models/GeocodeResult";
import { QuoteResponseModel, QuoteRequestModel, QuoteModel } from "Custom/Models";
import { ProcessFlowStore, MapStore } from "Custom/Stores/Domain/";

export class QuoteStore extends BaseStore {
	@observable private quoteResponse: QuoteResponseModel = new QuoteResponseModel();
	@observable private quoteRequest: QuoteRequestModel = new QuoteRequestModel();

	@observable public chosenQuote: QuoteModel = new QuoteModel();
	@observable public chosenQuoteId: string = "";

	@observable public bindResponseSuccess: boolean = false;

	private mapStore: MapStore = new MapStore();

	public constructor() {
		super();
	}

	// The following actions are used to pass the Quote stuff to the Selected Policy screen
	@action
	public setChosenQuiteId(quoteId: string) {
		this.chosenQuoteId = quoteId;
	}

	@action
	public setChosenQuote(selectedQuote: QuoteModel) {
		this.chosenQuote = selectedQuote;
	}

	@action
	public resetChosenQuote() {
		this.chosenQuote = new QuoteModel();
	}

	@action
	public setBindResponseSuccess(msg: boolean) {
		this.bindResponseSuccess = msg;
	}

	@action
	public resetBindResponseSuccess() {
		this.bindResponseSuccess = false;
	}
	// The above actions are used to pass the Quote stuff to the Selected Policy screen
}
