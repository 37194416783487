import React from "react";
import { GridContainerAdmin } from "Shoothill.Components/StylesAppSpecific/AdminStyling";
import Typography from "@material-ui/core/Typography";

export const AdminHome: React.FC = () => {
	return (
		<>
			<GridContainerAdmin>
				<Typography variant="h4" gutterBottom>
					Dashboard
				</Typography>
			</GridContainerAdmin>
		</>
	);
};
