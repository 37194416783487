// Libs
import * as MobX from "mobx";
import Moment from "moment";

// App
import { BaseStore } from "../BaseStore";
import { User } from "Custom/Models/User";
import { UserDTO } from "Custom/Models/User";
import { Stores } from "Custom/Stores/index";

interface Filters {
	date: any;
	orderAsc: boolean;
	searchFilter: string;
}

export class UserStore extends BaseStore {
	private users = MobX.observable<User>([]);
	@MobX.observable private isLoadingData: boolean = false;
	@MobX.observable private filters: Filters = {
		date: Moment(),
		orderAsc: true,
		searchFilter: "",
	};
	@MobX.observable private lastUpdated: any = Moment();

	public constructor() {
		super();
	}

	public Init(stores: Stores) {}

	@MobX.computed get getUserCount(): number {
		return this.users.length;
	}

	@MobX.action
	public setUsers(users: UserDTO[]) {
		users.forEach((user, index) => {
			let domainModel = this.users.find(dm => dm.id === user.id);

			if (!domainModel) {
				domainModel = new User(user.id);

				domainModel.fromDto(user);
				this.users.push(domainModel);
			}
		});
		this.isLoadingData = false;
		this.lastUpdated = Moment();
	}

	@MobX.action
	public clearUsers() {
		this.users.clear();
	}

	@MobX.action
	public setIsLoadingData(loading: boolean) {
		this.isLoadingData = loading;
	}

	@MobX.computed
	public get getUsers() {
		return this.users;
	}

	@MobX.computed
	public get getIsLoadingData() {
		return this.isLoadingData;
	}

	@MobX.computed
	public get getFilters() {
		return this.filters;
	}

	@MobX.computed
	public get getFilteredUsers() {
		let users = this.users.slice();
		if (this.filters.searchFilter) {
			users = this.users.filter(
				or =>
					or.email.toLowerCase().includes(this.filters.searchFilter) ||
					or.firstName.toLowerCase().includes(this.filters.searchFilter) ||
					or.lastName.toLowerCase().includes(this.filters.searchFilter),
			);
		}
		return users;
	}
}
