import React, { useState, useEffect, useRef } from "react";
import {
	TextField,
	Box,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	FormControl,
	MenuItem,
	Radio,
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as moment from "moment";
import { Moment } from "moment";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { generateID } from "../Utils/Utils";
import { getPath } from "ts-object-path";
import DatePickerIcon from "../../Content/Icon-datepicker.svg";
import Icon from "@material-ui/core/Icon";

const Control = styled(FormControl)`
	min-width: 120px;
`;

const StyledRadio = styled(Radio)`
	background: none !important;
`;

type TModel<T> = IModel<T> & any;

type InputProps = {
	multiline?: boolean;
	rows?: number;
	maxLength?: number;
	min?: string;
};

type FieldType<T> = Omit<
	T,
	| "Errors"
	| "Dirty"
	| "Touched"
	| "Valid"
	| "getError"
	| "setError"
	| "getDirty"
	| "setDirty"
	| "getTouched"
	| "setTouched"
	| "getValid"
	| "setValid"
	| "getValue"
	| "setValue"
	| "fromDTO"
	| "toDTO"
>;

type Props<T extends TModel<T>> = {
	onChange?: any;
	viewModel: IViewModel;
	fieldName: keyof FieldType<T> & string;
	selectItems?: KeyValuePair[];
	inputProps?: InputProps;
	required?: boolean;
	editMode?: boolean;
	validateOnStart?: boolean;
	validateOnBlur?: boolean;
	fullwidth?: boolean;
	type?:
		| "text"
		| "number"
		| "password"
		| "email"
		| "checkbox"
		| "radio"
		| "select"
		| "multi-select"
		| "date"
		| "time"
		| "datetime";
	label?: string;
	[x: string]: any;
	format?: string;
	placeholder?: string;
	className?: string;
};

export const EditableInput = <T extends TModel<T>>(props: Props<T>) => {
	const rendered = useRef<boolean>(false);
	const { multiline } = props.inputProps!;
	let fieldName: string = props.fieldName;

	if (!props.fieldName) {
		//debugger;
		return <></>;
	}

	if (typeof props.fieldName !== "string") {
		//debugger;
		let p = getPath(props.fieldName);
		fieldName = p.join(".");
	}

	//console.log("Fieldname " + props.fieldName);

	useEffect(() => {
		rendered.current = true;

		if (props.validateOnStart) {
			validate(props.viewModel.getValue(fieldName));
		}
	}, []);

	const validate = (value: any) => {
		try {
			props.viewModel.isFieldValid(fieldName, value);
		} catch {
			//console.log("Error " + props.fieldName);
			//debugger;
		}
	};

	const onChange = (value: string | number) => {
		// if (props.type === "number" && isNaN(parseInt(value as any))) {
		//     return;
		// }
		if (props.type === "number") {
			value = parseInt(value as any);
		}

		props.viewModel.setValue(fieldName, value);
		props.viewModel.setDirty(fieldName, true);

		if (!props.validateOnBlur) {
			validate(value);
		}
	};

	const onDateChange = (value: Moment) => {
		if (value) {
			let d = value.toDate();
			if (!isNaN(d.getTime())) {
				props.viewModel.setValue(fieldName, d);
				props.viewModel.setDirty(fieldName, true);

				if (!props.validateOnBlur) {
					validate(d.toISOString());
				}
			}
		}
	};

	const onBlur = (value: string | number) => {
		if (props.type === "number" && isNaN(parseInt(value as any))) {
			return;
		}
		if (props.type === "number") {
			value = parseInt(value as any);
		}

		props.viewModel.setValue(fieldName, value);
		props.viewModel.setTouched(fieldName, true);
		validate(value);
	};

	const isInError = (): boolean => {
		let isValid = props.viewModel.getValid(fieldName);
		if (props.validateOnStart) {
			return !isValid;
		}

		if (!rendered.current) {
			return false;
		}
		return !isValid;
	};

	const renderControl = () => {
		if (props.type === "checkbox") {
			return checkBox();
		} else if (props.type === "radio") {
			return radio();
		} else if (props.type === "select") {
			return selectField();
		} else if (props.type === "multi-select") {
			return multiSelectField();
		} else if (props.type === "date") {
			return dateComp();
		} else if (props.type === "time") {
			return timeComp();
		} else {
			return textField();
		}
	};

	const dateComp = () => {
		return (
			<>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardDatePicker
						placeholder="01/01/2020"
						label={props.label}
						{...props.x}
						value={props.viewModel.getValue(fieldName)}
						onChange={(e: any) => {
							onDateChange(e);
						}}
						onBlur={(e: any) => onBlur(e.target.value)}
						mask={"__-__-____"}
						format={props.format !== "" ? props.format : "DD-MMM-YYYY"}
						className={props.className !== null ? props.className : ""}
						keyboardIcon={
							<Icon>
								<img src={DatePickerIcon} alt={"Calendar icon."} />
							</Icon>
						}
					/>
				</MuiPickersUtilsProvider>
			</>
		);
	};

	const timeComp = () => {
		return (
			<>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<KeyboardTimePicker
						placeholder="08:00 AM"
						label={props.label}
						mask={"__:__ _M"}
						{...props.x}
						value={props.viewModel.getValue(fieldName)}
						onChange={(e: any) => {
							onDateChange(e);
						}}
						onBlur={(e: any) => onBlur(e.target.value)}
					/>
				</MuiPickersUtilsProvider>
			</>
		);
	};

	const radio = () => {
		return (
			<Control error={isInError()}>
				<FormLabel component="legend" id="demo-simple-radio-label">
					{props.label}
				</FormLabel>
				<RadioGroup
					row={true}
					{...props.x}
					aria-label={props.label}
					name={props.label}
					value={props.viewModel.getValue(fieldName)}
					onChange={(e: any) => {
						onChange(e.target.value);
					}}
					onBlur={(e: any) => onBlur(e.target.value)}
				>
					{props.selectItems!.map((item: KeyValuePair) => {
						return (
							<FormControlLabel
								key={generateID()}
								value={item.value}
								control={<StyledRadio disableRipple disableFocusRipple disableTouchRipple />}
								label={item.key}
							/>
						);
					})}
				</RadioGroup>
				{isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
			</Control>
		);
	};

	const selectField = () => {
		return (
			<Control error={isInError()}>
				<InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
				<Select
					id="demo-simple-select"
					value={props.viewModel.getValue(fieldName)}
					{...props.x}
					onChange={(e: any) => {
						onChange(e.target.value);
						props.onChange ? props.onChange(e.target.value) : "";
					}}
				>
					{props.selectItems!.map((item: KeyValuePair, index: number) => {
						return (
							<MenuItem key={generateID()} value={item.value}>
								{item.key}
							</MenuItem>
						);
					})}
				</Select>
				{isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
			</Control>
		);
	};

	const multiSelectField = () => {
		let value: string = props.viewModel.getValue(fieldName);
		let valueArr = value.toString().split(",");

		return (
			<Control error={isInError()}>
				<InputLabel id="demo-mutiple-name-label">{props.label}</InputLabel>
				<Select
					id="demo-multiple-name"
					value={valueArr}
					{...props.x}
					onChange={(e: any) => {
						onChange(e.target.value);
						props.onChange ? props.onChange(e.target.value) : "";
					}}
					multiple={true}
				>
					{props.selectItems!.map((item: KeyValuePair, index: number) => {
						return (
							<MenuItem key={generateID()} value={item.value}>
								{item.key}
							</MenuItem>
						);
					})}
				</Select>
				{isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
			</Control>
		);
	};

	const textField = () => {
		if (props.editMode) {
			return (
				<TextField
					fullWidth
					{...props.inputProps}
					{...props.x}
					multiline={multiline ? true : undefined}
					label={props.label}
					type={props.type}
					error={isInError()}
					autoComplete={props.type}
					value={props.viewModel.getValue(fieldName)}
					onChange={(e: any) => onChange(e.target.value)}
					onBlur={(e: any) => onBlur(e.target.value)}
					helperText={props.viewModel.getError(fieldName)}
					placeholder={props.placeholder}
					className={props.className !== null ? props.className : ""}
				/>
			);
		} else {
			return <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>;
		}
	};

	const checkBox = () => {
		return (
			<>
				<FormControlLabel
					style={{ color: isInError() ? "red" : "black" }}
					control={
						<Checkbox
							checked={props.viewModel.getValue(fieldName) as boolean}
							onChange={(e: any) => onChange(e.target.checked)}
							value={props.viewModel.getValue(props.fieldName)}
						/>
					}
					label={props.label}
				/>
				{isInError() && (
					<FormHelperText style={{ color: "red" }}>
						{/*{errorMessage}*/}
						{props.viewModel.getError(fieldName)}
					</FormHelperText>
				)}
			</>
		);
	};

	const formatSelectValue = (value: any) => {
		let retval = props.selectItems!.find(a => a.value == value);
		if (retval) return retval!.key;
		else return "";
	};

	return useObserver(() => <Box>{renderControl()}</Box>);
};

EditableInput.defaultProps = {
	type: "text",
	editMode: true,
	validateOnStart: false,
	validateOnBlur: false,
	fullwidth: true,
	inputProps: {
		multline: undefined,
	},
};
