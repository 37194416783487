import React, { useEffect } from "react";
import MaterialTable from "material-table";
import * as Mui from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../../Core/Utils";

// App
import UserViewModel from "Custom/ViewModels/UserViewModel";
import { User as UserDTO } from "Custom/Models/Dto/User";
import styled from "styled-components";
import { ApiResult } from "../../../Core/Models";

const userViewModel = new UserViewModel();

export const Container = styled(Mui.Box)`
	.MuiTableCell-root {
		font-size: 0.8rem;
	}
	.MuiTableSortLabel-root {
		:hover {
			color: lightgray;
		}
	}
	.MuiTableRow-root {
		cursor: pointer;
		:hover {
			background-color: lightgray;
		}
	}
`;

export const UserListContainer: React.FC = () => {
	const { history } = useRouter();
	useEffect(() => {
		const response = userViewModel.loadUsersAsync().then((apiResult: ApiResult<UserDTO[]>) => {
			if (apiResult.wasSuccessful) {
			}
		});
	}, []);

	const handleRowClick = (e: any, rowData: any) => {
		history.push(`/userslist/user/${rowData.id}`);
	};

	const handleNewClick = () => {
		history.push(`/userslist/user/0`);
	};

	return useObserver(() => (
		<Container>
			<Mui.Box marginTop="45px" width="100%">
				<Mui.Typography variant="h1" color="textPrimary">
					Users
				</Mui.Typography>
				<Mui.Box paddingRight="10px">
					<Mui.Box flexGrow={1} textAlign="right">
						<Mui.Button variant="contained" color="secondary" onClick={() => handleNewClick()}>
							<Mui.Typography variant="button">New User</Mui.Typography>
						</Mui.Button>
					</Mui.Box>
				</Mui.Box>
			</Mui.Box>
			{
				<MaterialTable
					columns={[
						{ title: "First name", field: "firstName" },
						{ title: "Surname", field: "lastName" },
						{ title: "Email", field: "email" },
						{ title: "Access Level", field: "userType" },
					]}
					options={{
						pageSize: 50,
						pageSizeOptions: [10, 20, 50, 100],
						emptyRowsWhenPaging: false,
					}}
					data={userViewModel.getUsers}
					onRowClick={handleRowClick}
					title=""
				/>
			}
		</Container>
	));
};
