import { action, runInAction } from "mobx";
import { BindQuoteModel, BindQuoteResponseModel, QuoteResponseNoQuotesModel } from "../Models/";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { Server } from "../../Custom/Globals/AppUrls";
import { StoresInstance } from "Custom/Stores";

export class BindQuoteRequestViewModel extends ViewModelBase<BindQuoteModel> {
	public ResponseModel: any;
	constructor() {
		super(new BindQuoteModel(), true);
		this.setDecorators(BindQuoteModel);
	}

	public isFieldValid(fieldName: keyof BindQuoteModel & string, value: string | number): boolean {
		let { isValid, errorMessage } = this.validateDecorators(fieldName);

		if (!isValid) {
			switch (fieldName) {
				case "payerNameCard":
				case "cardNumber":
				case "cardExpirationMonth":
				case "cardExpirationYear":
				case "cardCvvCode":
					isValid = this.model.paymentOption != "card";
					break;
				case "checkName":
				case "bankName":
				case "routingNumber":
				case "accountNumber":
					isValid = this.model.paymentOption != "echeck";
					break;
				default:
					break;
			}
		}

		if (isValid) {
			errorMessage = "";
		}

		//You need to this two properties after validation
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}

	@action
	public GetBindQuoteAsync = async (): Promise<boolean> => {
		let apiResult = await this.Post<BindQuoteResponseModel>(Server.Api.Quotes.BindQuote, this.model);
		runInAction(() => {
			if (apiResult.wasSuccessful) {
				this.ResponseModel = new BindQuoteResponseModel();
				this.ResponseModel.fromDto(apiResult.payload);
				StoresInstance.domain.QuoteStore.setBindResponseSuccess(apiResult.payload.success);
			} else {
				// This never gets called as Greg's API never returns errors.
				this.ResponseModel = new QuoteResponseNoQuotesModel();
				this.ResponseModel.fromDto(apiResult.errors);
				StoresInstance.domain.QuoteStore.setBindResponseSuccess(false);
			}
		});

		return apiResult.wasSuccessful;
	};
}
