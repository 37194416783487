import {
	AdjustBtn,
	AltSelect,
	QuoteSlider,
	SterlingForm,
	SterlingLabel,
	SterlingSmallText,
	SterlingText,
	SubmitBtn,
} from "../../../Shoothill.Components/StylesAppSpecific/FormStyling";
import { FormControlLabel, InputAdornment, Radio, RadioGroup } from "@material-ui/core";
import { GetQuoteViewModel, QuoteRequestViewModel, QuoteResponseViewModel } from "Custom/ViewModels";
import { GridSpaced, SterlingMainBox } from "../../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import React, { useState } from "react";

import { EditableInput } from "Core/Components/EditableInput";
import { GetQuoteModel } from "Custom/Models";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { StoresInstance } from "Custom/Stores";
import { iconVerfiyInfo } from "Content/EmbeddedSVGs";
import { useMediaQuery } from "Core/Utils/Media";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";

const domainStores = StoresInstance.domain;

export const QuoteSliderComp = (props: { fieldName: any; min: number; max: number; step: number }) => {
	return useObserver(() => (
		<QuoteSlider
			value={domainStores.QuoteRequestViewModel.getValue(props.fieldName)}
			onChange={(e: any, newValue: number | number[]) =>
				domainStores.QuoteRequestViewModel.setValue(props.fieldName, newValue as number)
			}
			max={props.max}
			min={props.min}
			step={props.step}
		/>
	));
};

export const GetQuoteForm: React.FC = () => {
	const { history } = useRouter();
	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	let numberOfFloors: number = domainStores.QuoteRequestViewModel.getValue("numberFloors") as number;

	let elevatorValsArr: KeyValuePair[] = [{ value: "1", key: "Yes" }, { value: "0", key: "No" }];
	let floodZoneValsArr: KeyValuePair[] = [
		{ value: "AE", key: "AE" },
		{ value: "VE", key: "VE" },
		{ value: "X", key: "X" },
	];

	const [errorMessage, setErrorMessage] = useState<string>("");

	const adjustNumberFloors = (amount: number) => {
		let currentVal = domainStores.QuoteRequestViewModel.getValue("numberFloors") as number;
		currentVal = Math.max(0, currentVal + amount);
		domainStores.QuoteRequestViewModel.setValue("numberFloors", currentVal);
	};

	const doSubmit = (e: any) => {
		e.preventDefault();

		if (!domainStores.QuoteRequestViewModel.isModelValid()) {
			setErrorMessage("Problem");
		} else {
			setErrorMessage("Not a Problem");

			history.push("/yourquote");
		}
	};

	const headingTitle = (
		<>
			<SterlingMainBox additionalcss="display: flex; align-items: center; justify-content: center; margin-bottom: 55px;">
				{iconVerfiyInfo}
				<h1>Please verify information below</h1>
			</SterlingMainBox>
		</>
	);

	const rowOne = (
		<>
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				additionalcss="margin-bottom: 18px"
				spacing={3}
			>
				<GridSpaced item xs={12} md={8} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 500px)", display: "inline-block" }}>
						<SterlingLabel padding={"0"} additionalcss="height: auto !important;">
							Customer name:
						</SterlingLabel>
					</div>
					<div style={{ width: "500px", display: "inline-block" }}>
						<SterlingText<GetQuoteModel>
							className={"customer-name"}
							autoFocus={true}
							fieldName="customerName"
							additionalcss="display: flex;"
							viewModel={domainStores.QuoteRequestViewModel}
						/>
					</div>
				</GridSpaced>
				<GridSpaced item xs={12} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align: right;">
							Policy effective:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block" }}>
						<SterlingText<GetQuoteModel>
							viewModel={domainStores.QuoteRequestViewModel}
							type="date"
							className={"datePicker policy-effective"}
							fieldName="dateEffective"
							format="MM/DD/YYYY"
							additionalcss="display: flex;"
						/>
					</div>
				</GridSpaced>
			</GridSpaced>
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				padding="0"
				margin="0px 0px 30px"
				additionalcss={"border-bottom: 1px solid #1ab7ea;"}
			></GridSpaced>
		</>
	);

	const blockTwo = (
		<>
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				additionalcss="margin-bottom: 18px"
				spacing={3}
			>
				<GridSpaced item xs={12} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0"} additionalcss="height: auto !important;">
							Dwelling coverage:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block" }}>
						<SterlingText<GetQuoteModel>
							className={"sliderText dwelling-coverage"}
							fieldName="coverageBuilding"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
							additionalcss="display: flex;"
							inputProps={{
								InputProps: {
									startAdornment: (
										<InputAdornment position="start">
											<div className="dollarSign">$</div>
										</InputAdornment>
									),
								},
							}}
						/>
					</div>
				</GridSpaced>
				<GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align: right;">
							Contents coverage:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block" }}>
						<SterlingText<GetQuoteModel>
							className={"sliderText contents-coverage"}
							fieldName="coverageContents"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
							additionalcss="display: flex;"
							inputProps={{
								InputProps: {
									startAdornment: (
										<InputAdornment position="start">
											<div className="dollarSign">$</div>
										</InputAdornment>
									),
								},
							}}
						/>
					</div>
				</GridSpaced>
				<GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align:right;">
							Loss of use coverage:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block" }}>
						<SterlingText<GetQuoteModel>
							className={"sliderText loss-coverage"}
							fieldName="valueLossOfUse"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
							additionalcss="display: flex;"
							inputProps={{
								InputProps: {
									startAdornment: (
										<InputAdornment position="start">
											<div className="dollarSign">$</div>
										</InputAdornment>
									),
								},
							}}
						/>
					</div>
				</GridSpaced>
			</GridSpaced>
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				/* padding="0px 0px 6px" */
				margin="0px 0px 30px"
				additionalcss={"border-bottom: 1px solid #1ab7ea;"}
			></GridSpaced>
		</>
	);

	const blockThree = (
		<>
			{/* <GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				additionalcss="margin-bottom: 18px"
				spacing={3}
			>
				<GridSpaced item xs={12} md={4} padding="0px" additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0"} additionalcss="height: auto !important;">
							Floodzone:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<div className="floodzone-select">
							<EditableInput<GetQuoteModel>
								type="select"
								fieldName="floodZone"
								viewModel={domainStores.QuoteRequestViewModel}
								selectItems={floodZoneValsArr}
							/>
						</div>
					</div>
				</GridSpaced>
				<GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align:right;">
							Year built:*
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<SterlingText<GetQuoteModel>
							className={"sliderText 1 year-built"}
							fieldName="yearBuilt"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
							additionalcss="display: flex;"
						/>
					</div>
				</GridSpaced>
				<GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align: right;">
							Replacement cost:*
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<SterlingText<GetQuoteModel>
							viewModel={domainStores.QuoteRequestViewModel}
							className={"sliderText 2 replacement-cost"}
							fieldName="valueBuilding"
							type={"number"}
							additionalcss="display: flex;"
							inputProps={{
								InputProps: {
									startAdornment: (
										<InputAdornment position="start">
											<div className="dollarSign">$</div>
										</InputAdornment>
									),
								},
							}}
						/>
					</div>
				</GridSpaced>
			</GridSpaced> */}
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				additionalcss="margin-bottom: 18px"
				spacing={3}
			>
				<GridSpaced item xs={12} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0"} additionalcss="height: auto !important;">
							Number of floors:
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<div style={{ display: "flex" }}>
							<AdjustBtn
								onClick={() => adjustNumberFloors(-1)}
								border={"1px solid #1ab7ea;"}
								borderleft={"1px solid #1ab7ea;"}
							>
								-
							</AdjustBtn>
							<SterlingSmallText
								type="number"
								className={"sliderText 3 number-of-floors"}
								fieldName="numberFloors"
								viewModel={domainStores.QuoteRequestViewModel}
								inputProps={{ min: "0" }}
								additionalcss="width: auto; display: flex;"
							/>
							<AdjustBtn
								onClick={() => adjustNumberFloors(1)}
								border={"1px solid #1ab7ea;"}
								borderright={"1px solid #1ab7ea;"}
							>
								+
							</AdjustBtn>
						</div>
					</div>
				</GridSpaced>
				<GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align: right;">
							Elevator?
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<div style={{ display: "flex", height: "30px" }} className="elevator">
							<EditableInput<GetQuoteModel>
								type="radio"
								viewModel={domainStores.QuoteRequestViewModel}
								fieldName="elevator"
								selectItems={elevatorValsArr}
								row={true}
							/>
						</div>
					</div>
				</GridSpaced>
				{/* <GridSpaced item xs={6} md={4} additionalcss={"display: flex; align-items: center;"}>
					<div style={{ width: "calc(100% - 144px)", display: "inline-block" }}>
						<SterlingLabel padding={"0 10px 0 0"} additionalcss="height: auto !important; text-align: right;">
							Total SQ FT:*
						</SterlingLabel>
					</div>
					<div style={{ width: "144px", display: "inline-block", height: "35px" }}>
						<SterlingText<GetQuoteModel>
							className={"sliderText 4 total-sq-ft"}
							fieldName="buildingSqft"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
							additionalcss="display: flex;"
						/>
					</div>
				</GridSpaced> */}
			</GridSpaced>
			<GridSpaced
				container
				direction={"row"}
				justify="center"
				alignItems="center"
				padding="0"
				additionalcss={"border-bottom: 1px solid #1ab7ea;"}
			></GridSpaced>
			<GridSpaced container direction={"row"} justify="center" alignItems="center">
				<GridSpaced item xs={12} additionalcss={"text-align: center;"}>
					<SubmitBtn
						padding={"10px 50px"}
						margin={"38px 0px 50px"}
						additionalcss={"width: 259px; height: 55px;"}
						onClick={doSubmit}
					>
						Get my quotes
					</SubmitBtn>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	return useObserver(() => (
		<>
			<GridSpaced item xs={12}>
				<div style={{ position: "absolute", top: 0, right: 0, margin: "15px 50px" }}>
					<SubmitBtn padding={"10px 24px"} onClick={doSubmit}>
						Get my quotes
					</SubmitBtn>
				</div>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12}>
						{headingTitle}
					</GridSpaced>
				</GridSpaced>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={12} margin={"0px 50px"}>
						<SterlingForm onSubmit={doSubmit}>
							{/* {rowOne}
							{blockTwo} */}
							{blockThree}
						</SterlingForm>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	));
};
