import { createMuiTheme, Theme } from "@material-ui/core/styles";

const breakpointTheme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 0,
			md: 0,
			lg: 0,
			xl: 768,
		},
	},
});

export const defaultTheme = createMuiTheme({
	typography: {
		fontFamily: `"Open Sans", sans-serif`,
		h1: {
			fontSize: "2rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: "1.22em",
			},
		},
		h2: {
			fontSize: "1.5rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: "0.969em",
			},
		},
		h3: {
			fontSize: "1.17rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: ".792em",
			},
		},
		h4: {
			fontSize: "0.85rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: ".655em",
			},
		},
		h5: {
			fontSize: "0.83rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: "0.55em",
			},
		},
		h6: {
			fontSize: "0.67rem",
			fontWeight: "bold",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: "0.47em",
			},
		},
		subtitle1: {
			fontSize: "0.85rem",
			fontWeight: 300,
			//lineHeight: `${1.8 * referenceFontSize}vw`,
			letterSpacing: "-0.4px",
			[breakpointTheme.breakpoints.down("lg")]: {
				//fontSize: "1em",
				//lineHeight: `${1.8 * referenceFontSize}vw`,
				//letterSpacing: "-0.4px",
			},
		},
		subtitle2: {
			fontSize: "0.875rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
		body1: {
			fontSize: "0.85rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
		body2: {
			fontSize: "0.875rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
		button: {
			fontSize: "0.875rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
		caption: {
			fontSize: "0.75rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
		overline: {
			fontSize: "0.75rem",
			//fontWeight: 300,
			[breakpointTheme.breakpoints.down("lg")]: {
				// fontSize: "0.8em",
			},
		},
	},
	palette: {
		primary: {
			main: "#1ab7ea",
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: "#F2893E",
			contrastText: "#FFFFFF",
		},
	},
	overrides: {
		MuiLink: {
			root: {
				textDecoration: "none",
				color: "#000",
			},
		},
		MuiTabs: {
			indicator: {
				display: "none",
			},
		},
		MuiTab: {
			root: {
				backgroundColor: "#F1F1F1",
				border: "1px solid rgba(0, 0, 0, 0.08)",
				marginLeft: "1px",
				textTransform: "capitalize",
				fontWeight: "bold",
				borderRadius: "2px 2px 0 0",
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, .1)",
					color: "black",
				},
				"@media (min-width: 960px)": {
					width: "57px",
					minWidth: "57px",
				},
			},
		},
		MuiButton: {
			root: {
				margin: "15px 0 15px 0",
				borderRadius: "0",
				textTransform: "capitalize",
				fontSize: "14px",
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: "36px",
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: "10px 6px 10px 6px",
				height: "30px",
			},
			notchedOutline: {
				borderRadius: "0",
			},
		},
		MuiInputLabel: {
			outlined: {
				fontWeight: 500,
				color: "#ffffff",
			},
		},
		MuiFormHelperText: {
			root: {
				marginTop: "5px !important",
				fontSize: "14px !important",
			},
		},
	},
});
