import {
	AppHeaderStart,
	GridSpaced,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { QuoteBtn, QuoteHeadingBox, QuoteLink } from "Shoothill.Components/StylesAppSpecific/QuoteStyling";
import React, { useContext, useEffect, useState } from "react";
import { Stores, StoresContext, StoresInstance } from "Custom/Stores";

import { ProcessFlowStore } from "Custom/Stores/Domain";
import { QuoteModel } from "../Models/";
import { YourQuoteSlider } from "Custom/Components/Quotes/YourQuoteSlider";
import { YourQuotes } from "Custom/Components/Quotes/YourQuotes";
import { iconGettingQuotesTile } from "Content/EmbeddedSVGs";
import { iconYourQuotes4Title } from "../../Content/EmbeddedSVGs";
import { isNullorEmpty } from "Core/Utils/Validation";
import loadingTickOptomised from "Content/loadingTick-optomised.gif";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { Box } from "@material-ui/core";

export const YourQuote: React.FC = () => {
	const domainStores = StoresInstance.domain;
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;

	let quote1000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes1000;
	let quote2000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes2000;
	let quote5000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes5000;
	let quote10000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes10000;
	let quote25000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes25000;
	let quote50000: any = domainStores.QuoteResponseViewModel.model.quotes.quotes50000;
	let quoteExcess: any = domainStores.QuoteResponseViewModel.model.quotes.excess;

	const [sliderAdjust, setSliderAdjust] = useState<number>(10000);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [showQuotes, setShowQuotes] = useState<boolean>(true);
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	useEffect(() => {
		domainStores.QuoteRequestViewModel.setValue("createdDate", new Date().toISOString());
		domainStores.QuoteRequestViewModel.GetQuotesAsync().then(() => {
			setSliderAdjust(10000);
			getCardOptions(0);
			getCardOptions(1);
			getCardOptions(2);
			setHasLoaded(true);
			setHasQuotes();
		});
	}, []);

	function newQuote(): void {
		ProcessFlowStoresContext.ResetQuote();
		domainStores.QuoteRequestViewModel.resetAll();
		history.push("/");
	}

	function editQuote(): void {
		history.push("/editquote");
	}

	function selectQuote(): void {
		//history.push("/editquote");
		let host = window.location.host;
		window.location.href =
			"https://" + host + "/q/quotes?qrid=" + domainStores.QuoteResponseViewModel.model.quoteRequest.quoteRequestId;
	}

	function shortCut(): void {
		history.push("/policyselected");
	}

	const getCardOptions = (cardNumber: number): QuoteModel | undefined => {
		switch (sliderAdjust) {
			case 1000:
				if (!isNullorEmpty(quote1000)) {
					let sorted1000 = quote1000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted1000[cardNumber];
				}
				break;
			case 2000:
				if (!isNullorEmpty(quote2000)) {
					let sorted2000 = quote2000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted2000[cardNumber];
				}

				break;
			case 5000:
				if (!isNullorEmpty(quote5000)) {
					let sorted5000 = quote5000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted5000[cardNumber];
				}
				break;
			case 10000:
				if (!isNullorEmpty(quote10000)) {
					let sorted10000 = quote10000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted10000[cardNumber];
				}
				break;
			case 25000:
				if (!isNullorEmpty(quote25000)) {
					let sorted25000 = quote25000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted25000[cardNumber];
				}
				break;
			case 50000:
				if (!isNullorEmpty(quote50000)) {
					let sorted50000 = quote50000.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted50000[cardNumber];
				}
				break;
			case 100000:
				if (!isNullorEmpty(quoteExcess)) {
					let sorted100000 = quoteExcess.sort(
						(n1: QuoteModel, n2: QuoteModel) => n1.coverageBuilding - n2.coverageBuilding,
					);
					return sorted100000[cardNumber];
				}
				break;
		}
		return undefined;
	};

	const setHasQuotes = () => {
		if (domainStores.QuoteResponseViewModel.model.quoteCount === 0) {
			setShowQuotes(false);
		}
	};

	const sliderAction = (newValue: number) => {
		setSliderAdjust(newValue);
	};

	const newQuoteMarkup = (
		<>
			<QuoteLink title={"Start new quote"} className="backLink" onClick={newQuote}>
				Start new quote
			</QuoteLink>
		</>
	);

	const titleMarkup = (
		<>
			<QuoteHeadingBox additionalcss="text-align: center;">
				<div>{iconYourQuotes4Title}</div>
				<h1>Your quotes</h1>
			</QuoteHeadingBox>
		</>
	);

	const loaderTitleMarkup = (
		<>
			<GridSpaced item xs={5}>
				<SterlingMainBox additionalcss="text-align: center;">
					<div>{iconGettingQuotesTile}</div>
					<h1>Getting your quotes</h1>
				</SterlingMainBox>
			</GridSpaced>
		</>
	);

	const editQuoteMarkup = (
		<>
			<QuoteBtn
				onClick={editQuote}
				backgroundcolor={"#cbdb2a"}
				fontcolor={"#000000"}
				fontSize={"14px"}
				padding={"7px 24px"}
			>
				Edit quote info
			</QuoteBtn>
		</>
	);
	return useObserver(() => (
		<>
			{!hasLoaded && (
				<>
					<MainRoot>
						<HomeMain role="main">
							<AppHeaderStart>
								<GridSpaced
									container
									className="gridContainer"
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									<GridSpaced item xs={12} className="alignCenter">
										<GridSpaced container direction="row" justify="center" padding="0" margin="50px 0px 0px">
											{loaderTitleMarkup}
										</GridSpaced>
									</GridSpaced>
									<GridSpaced item xs={12} className="alignCenter" margin="0px 0px 50px">
										<img src={loadingTickOptomised} alt={"Your quote is on it's way"} />
									</GridSpaced>
								</GridSpaced>
							</AppHeaderStart>
						</HomeMain>
					</MainRoot>
				</>
			)}
			{hasLoaded && (
				<>
					<MainRoot>
						<HomeMain role="main">
							<AppHeaderStart additionalcss={"height: unset; background-color: #ffffff; color: black;"}>
								<GridSpaced container additionalcss={"height: unset;"} justify="flex-start">
									<GridSpaced item xs={12}>
										<GridSpaced container padding={"30px"}>
											{!isMobile && (
												<>
													<GridSpaced item md={4}>
														{newQuoteMarkup}
													</GridSpaced>
												</>
											)}
											<GridSpaced item xs={12} md={5} padding="0px 0px 0px 60px">
												{titleMarkup}
											</GridSpaced>
											<GridSpaced item xs={12} md={3} additionalcss={"text-align: right;"}>
												<Box display={"flex"} id={"btncont"}>
													{editQuoteMarkup}
												</Box>
											</GridSpaced>
										</GridSpaced>

										<GridSpaced
											container
											justify="center"
											alignItems="center"
											margin={"10px auto"}
											additionalcss={"max-width: 990px;"}
										>
											<GridSpaced item xs={12}>
												<YourQuoteSlider initialValue={4000} sliderValue={sliderAction} />
											</GridSpaced>
										</GridSpaced>
										<GridSpaced container justify="center" alignItems="center" additionalcss={"text-align:center;"}>
											{!showQuotes && (
												<>
													<GridSpaced item md={4} additionalcss={"margin-top: 25px"}>
														No quotes found.
													</GridSpaced>
												</>
											)}
											{showQuotes && (
												<>
													<GridSpaced item xs={12} md={4} margin="20px" additionalcss="max-width: 330px; width; 100%;">
														<YourQuotes styleType={""} titleText={"Basic option"} quoteModel={getCardOptions(0)} />
													</GridSpaced>
													<GridSpaced item xs={12} md={4} margin="20px" additionalcss="max-width: 350px; width; 100%;">
														<YourQuotes
															styleType={"featured"}
															titleText={"Sterling option"}
															quoteModel={getCardOptions(1)}
														/>
													</GridSpaced>
													<GridSpaced item xs={12} md={4} margin="20px" additionalcss="max-width: 330px; width; 100%;">
														<YourQuotes styleType={""} titleText={"Sterling+ option"} quoteModel={getCardOptions(2)} />
													</GridSpaced>
												</>
											)}
										</GridSpaced>
									</GridSpaced>
								</GridSpaced>
							</AppHeaderStart>
						</HomeMain>
					</MainRoot>
				</>
			)}
		</>
	));
};
