import { Box, Grid, Link, makeStyles } from "@material-ui/core";

import styled from "styled-components";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
}

export const GridSpaced: any = styled(Grid)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : undefined)};
	${props => props.additionalcss};
`;

export const BackLink: any = styled(Link)<IComponentProps>`
	color: white;
	font-size: 21px;
	/* text-decoration: underline; */
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "30px 30px 0px")};
	cursor: pointer;
	display: block;
	@media (max-width: 767px) {
		padding: ${props => (props.padding !== undefined ? props.padding : "30px 0px 0px 15px")};
		display: block;
		text-align: center;
		font-size: 14px;
	}
	${props => props.additionalcss};
`;

export const H1TitleAndImg: any = styled.h1<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "inherit")};
	margin: ${props => (props.margin !== undefined ? props.margin : "inherit")};
	font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : "inherit")};
	font-size: calc(20px + (30 - 21) * ((100vw - 350px) / (1366 - 350)));
	svg {
		width: calc(32px + (34 - 24) * ((100vw - 350px) / (1366 - 350)));
		height: auto;
		max-height: 57px;
		@media (max-width: 767px) {
			max-height: 37px;
		}
		vertical-align: middle;
		padding-right: 0px !important;
		margin-right: 10px;
	}
	${props => props.additionalcss};
`;

export const PageRoot: any = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;

	main {
	}

	@media (min-width: 768px) {
		height: unset;
		padding: 0px 20px;
		min-height: 100vh;
	}

	@media (min-width: 1180px) and (min-height: 744px) {
		height: 0px;
	}

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const MainRoot: any = styled(PageRoot)`
	height: 100% !important;
	padding: 0px !important;
	.information,
	.mapPlaceholder {
		margin: 30px 0;
	}

	.information {
		margin-top: 14px;
		text-align: left;
		font-size: 16px;
		@media (max-width: 767px) {
			font-size: 12px;
		}
		h1,
		p {
			margin: 0;
			padding: 0;
		}
		p.red {
			margin-top: 20px;
		}
		h1 {
			text-align: left;
			font: Bold 18px/20px "Open Sans", sans-serif;
			letter-spacing: -0.45px;
			color: #464545;
			opacity: 1;
			height: 34px;
		}
		p {
			text-align: left;
			letter-spacing: 0;
			color: #292b28;
			opacity: 1;
			font-weight: normal;
		}

		@media (max-width: 1024px) {
			margin-top: 0;
		}
	}
`;

export const HomeMain: any = styled.main`
	width: 100%;
`;

export const AppHeader: any = styled(Box)<IComponentProps>`
	min-height: 550px;
	width: 100%;
	@media (min-width: 768px) {
		display: flex;
		height: 100%;
	}
	flex-direction: column;
	align-items: center;
	justify-content: start;
	font-size: calc(10px + 2vmin);
	color: white;
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#3f3f3f")};
	max-width: 1140px;
	min-height: 550px;
	margin: 0 auto;
	h1 {
		font-size: calc(23px + (30 - 23) * ((100vw - 350px) / (1366 - 350)));
	}
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	${props => props.additionalcss};
`;

export const AppHeaderStart: any = styled(AppHeader)<IComponentProps>`
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#3f3f3f")};
	height: calc(100vh);
	@media (min-width: 768px) {
		display: flex;
	}
	.AddressSelector {
		flex-direction: column;
		margin: 30px auto;
		max-width: 350px;
		justify-content: center;
		@media screen and (min-width: 1140px) {
			max-width: 1140px;
		}
		input {
			height: 45px;
			width: 554px;
			font-size: 18px;
			padding-left: 15px;
			color: #464545;
			border: 1px solid #1ab7ea;
			opacity: 0.95;
			@media (max-width: 767px) {
				width: 90%;
				height: 34px;
				font-size: 14px;
			}
		}
	}
	.gridContainer {
		@media (max-width: 767px) {
			margin-top: 30px;
		}
		@media (min-width: 768px) {
			height: 550px;
		}
	}
	.rightPanelHeader {
		@media (min-width: 768px) {
			padding-top: 25px;
		}
	}
	.rightPanelHeaderIcon {
		text-align: right;
	}
	.rightPanelText {
		font-size: calc(14px + (18 - 14) * ((100vw - 350px) / (1366 - 350)));
		color: white;
	}
	.rightPanelAddress {
		color: #1ab7ea;
		font-size: calc(14px + (18 - 14) * ((100vw - 350px) / (1366 - 350)));
		text-align: center;
	}
	.alignCenter {
		text-align: center;
	}
	.panelIcon {
		text-align: center;
		padding-right: 15px;
		font-size: 24px;
		padding-top: 15px;
		:hover {
			text-decoration: none !important;
		}
		a,
		.panelLink,
		.MuiLink-button {
			font-size: 24px;
			color: #fff;
			text-decoration: none !important;
		}
		img {
			padding-bottom: 0px;
		}
	}
	@media (max-width: 767px) {
		.panelIcon {
			padding-right: 0;
			font-size: 12px;
			margin-top: 25px;
			img {
				width: 64px;
				height: auto;
			}
		}
	}

	.panelSelected {
		a,
		.panelLink,
		.MuiLink-button {
			color: #1ab7ea;
		}
	}

	${props => props.additionalcss};
`;

export const SterlingMainBox: any = styled(Box)<IComponentProps>`
	text-align: center;
	h1,
	p {
		display: inline-flex;
		margin: 0;
		padding: 0;
	}
	svg {
		margin-right: 12px;
	}
	h1 {
		font-size: calc(23px + (30 - 23) * ((100vw - 350px) / (1366 - 350)));
		color: #ffffff;
		margin: 0;
	}
	p {
		font-family: "Open Sans", sans-serif;
		display: block;
		margin: 0 auto;
		width: 277px;
		font-size: 11px;
		font-weight: normal;
		line-height: 15px;
		@media (min-width: 728px) {
			font-size: 17px;
			width: 100%;
			line-height: 1.43;
		}
	}
	${props => props.additionalcss};
`;

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	button: {
		margin: theme.spacing(1),
	},
	input: {
		display: "none",
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	datePicker: {
		backgroundColor: "#FFFFFF",
		border: "1px solid #E0DAF0",
		height: "54px",
		marginRight: "5px",
		marginBottom: "0px",
		marginTop: "0px",
		width: "100%",
		placeholder: {
			color: "#000000",
		},
	},
	datePickerBox: {},
	datePickersGridContainer: {
		justifyContent: "space-between",
	},
	datePickerGridContainer: {
		maxWidth: "96%",
	},
	subTitle: {
		marginBottom: "18px",
		paddingBottom: "0px !important",
	},
}));
