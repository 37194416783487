import { DomainStores } from "./DomainStores";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import * as History from "history";
import * as Models from "../Models";
import * as MobX from "mobx";
import { createContext } from "react";

export class Stores {
	public history: History.History | undefined;
	public domain: DomainStores = new DomainStores();

	public constructor() {}

	public init(initialState: Models.InitialState, history: History.History) {
		this.history = history;
		this.domain.init(this, initialState);
		// EN: For debugging
		(window as any).Stores = this;
		(window as any).MobX = MobX;
	}
}
export const StoresInstance = new Stores();
export const StoresContext: React.Context<Stores> = createContext(StoresInstance);
