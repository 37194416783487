import { AccountStore } from "./Domain/AccountStores";
import { BindQuoteRequestViewModel } from "../ViewModels/BindQuoteRequestViewModel";
import { UserStore } from "./Domain/Admin/UserStore";
import { Stores } from "Custom/Stores/index";
import { MapStore } from "./Domain/MapStore";
import { ProcessFlowStore } from "./Domain/ProcessFlowStore";
import { QuoteStore } from "./Domain/QuoteStore";
import { QuoteRequestViewModel } from "../ViewModels/QuoteRequestViewModel";
import { QuoteResponseViewModel } from "../ViewModels/QuoteResponseViewModel";

// App
import * as Models from "../Models";
import { FormLockConcurrencyHubStore } from "./Domain/FormLockConcurrencyHubStore";
import { observable, action, computed } from "mobx";

export class DomainStores {
	@observable public openSnackBar = false;
	@observable public openErrorDialog = false;
	@observable public errorDialogContent: string = "";
	@observable public snackBarContent: string = "";
	public AccountStore: AccountStore = new AccountStore();
	public UserStore = new UserStore();
	public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();
	public MapStore: MapStore = new MapStore();
	public ProcessFlowStore: ProcessFlowStore = new ProcessFlowStore();
	public QuoteStore: QuoteStore = new QuoteStore();
	public BindQuoteRequestViewModel: BindQuoteRequestViewModel = new BindQuoteRequestViewModel();
	public QuoteRequestViewModel: QuoteRequestViewModel = new QuoteRequestViewModel();
	public QuoteResponseViewModel: QuoteResponseViewModel = new QuoteResponseViewModel();

	public init(stores: Stores, initialState: Models.InitialState) {
		this.AccountStore.init(stores, initialState);
		this.FormLockConcurrencyHubStore.init(stores, initialState);
		this.MapStore.init(stores);
	}

	@action
	public ShowSnackBar(content: string) {
		this.snackBarContent = content;
		this.openSnackBar = true;
	}

	@action
	public CloseSnackBar() {
		this.openSnackBar = false;
	}

	@action
	public ShowErrorDialog(content: string) {
		this.errorDialogContent = content;
		this.openErrorDialog = true;
	}
}
