import React, { useState, useEffect } from "react";
import { KeyValuePair } from "../../../Core/Models/KeyValuePair";
import { ReactComponent as Iconagencybill } from "Content/icon-agencybill.svg";
import { ReactComponent as Icononlinecheck } from "Content/icon-onlineCheck.svg";
import { ReactComponent as IconcreditCard } from "Content/icon-creditCard.svg";
import iconcheckNotSelected from "Content/icon-checkNotSelected.svg";
import iconcheckSelected from "Content/icon-checkSelected.svg";
import { StoresInstance } from "../../Stores";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { BindQuoteModel, QuoteModel, QuoteRequestModel, QuoteResponseModel } from "Custom/Models";
import { PaymentCard, PolicyForm, SubmitBtn } from "../../../Shoothill.Components/StylesAppSpecific/PolicyStyling";
import { GridSpaced } from "../../../Shoothill.Components/StylesAppSpecific/HomeStyling";
/* import { QuoteHeadingBox } from "../../../Shoothill.Components/StylesAppSpecific/QuoteStyling"; */
import { Button, InputLabel, Checkbox, FormControlLabel, Modal, Backdrop, Fade, Typography } from "@material-ui/core";
import { PaymentModal, PaymentPaper } from "Shoothill.Components/StylesAppSpecific/ModalStyling";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import moment from "moment";
import { makeNonEnumerable } from "mobx/lib/internal";
import { number } from "prop-types";

interface IProps {
	quoteId: string;
	chosenQuote: QuoteModel;
}

interface IPaymentOptionProps {
	onClick: () => void;
	quote: QuoteModel;
	name: string;
	icon: any;
	selected: boolean;
}

const PaymentOption: React.FC<IPaymentOptionProps> = props => {
	return (
		<GridSpaced item xs={12} md={4} margin="20px" additionalcss="max-width: 286px; width; 100%;">
			<Fade in={true}>
				<PaymentCard
					container
					alignItems="start"
					direction={"column"}
					padding="0"
					margin="0"
					additionalcss={
						props.selected
							? "border-left: 4px solid #1AB7EA; border-right: 4px solid #1AB7EA; border-bottom: 4px solid #1AB7EA; border-radius: 5px 5px 0px 0px; position: relative;"
							: "border: 1px solid #eaeaea; border-radius: 5px 5px 0px 0px; position: relative;"
					}
					onClick={props.onClick}
				>
					<PaymentCard item xs={12}>
						<div className={`header ${props.selected ? "featured" : ""}`}>
							<h2>{props.name}</h2>
						</div>
					</PaymentCard>
					<PaymentCard item xs={12} className={"secondary"}>
						<div className={`grouped ${props.selected ? "featured" : ""}`}>
							<PaymentCard container direction={"row"} justify="center" alignItems="center">
								<PaymentCard item xs={5} className={""}>
									<props.icon />
								</PaymentCard>
								<PaymentCard item xs={2} additionalcss="text-align: right; font-weight: bold;">
									<h4>Quote:</h4>
									<h4>Fee:</h4>
									<h4>Tax:</h4>
									<h4>Total:</h4>
								</PaymentCard>
								<PaymentCard item xs={2} additionalcss="text-align: right;">
									<h3>$</h3>
									<h3>$</h3>
									<h3>$</h3>
									<h3>$</h3>
								</PaymentCard>
								<PaymentCard item xs={3} additionalcss="text-align: right;">
									<h3>{props.quote.premiumTotal}</h3>
									<h3>{props.quote.feeTotal}</h3>
									<h3>{props.quote.taxTotal}</h3>
									<h3>{props.quote.quoteTotal}</h3>
								</PaymentCard>
							</PaymentCard>
						</div>
					</PaymentCard>
					<PaymentCard item xs={12} additionalcss="text-align: center; position: absolute; bottom: -40px; left: 40%;">
						<img src={props.selected ? iconcheckSelected : iconcheckNotSelected} alt={"Select"} />
					</PaymentCard>
				</PaymentCard>
			</Fade>
		</GridSpaced>
	);
};

export const PolicyDetailForm: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;
	const [isSameAddress, setIsSameAddress] = useState<boolean>(false);
	const [modalActive, setModalActive] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { history } = useRouter();
	const [paymentPanel, setPaymentPanel] = React.useState<number>(2);

	useEffect(() => {
		domainStores.BindQuoteRequestViewModel.setValue(
			"dateEffective",
			domainStores.QuoteRequestViewModel.getValue("dateEffective"),
		);
		let expDate: Date = domainStores.QuoteRequestViewModel.getValue("dateEffective") as Date;
		let newExpDate = moment(expDate).add(366, "days");
		domainStores.BindQuoteRequestViewModel.setValue("dateExpiration", newExpDate.toDate());
	}, []);

	function roundToTwo(num: number) {
		return +(Math.round(num * 100 + Number.EPSILON) / 100);
	}

	let typeArr: KeyValuePair[] = [
		{ value: "corporation", key: "Corporation" },
		{ value: "individual,", key: "Individual" },
		{ value: "jointventure", key: "Jointventure" },
		{ value: "llc", key: "llc" },
		{ value: "partnership", key: "Partnership" },
		{ value: "trust", key: "Trust" },
		{ value: "other", key: "other" },
	];
	let paymentMethodsArr: KeyValuePair[] = [
		{ value: "bill", key: "Agency bill" },
		{ value: "echeck", key: "Online check" },
		{ value: "card", key: "Credit card" },
	];
	let statesListArr: KeyValuePair[] = [
		{ value: "AL", key: "Alabama" },
		{ value: "AK", key: "Alaska" },
		{ value: "AS", key: "American Samoa" },
		{ value: "AZ", key: "Arizona" },
		{ value: "AR", key: "Arkansas" },
		{ value: "CA", key: "California" },
		{ value: "CO", key: "Colorado" },
		{ value: "CT", key: "Connecticut" },
		{ value: "DE", key: "Delaware" },
		{ value: "DC", key: "District Of Columbia" },
		{ value: "FM", key: "Federated States Of Micronesia" },
		{ value: "FL", key: "Florida" },
		{ value: "GA", key: "Georgia" },
		{ value: "GU", key: "Guam" },
		{ value: "HI", key: "Hawaii" },
		{ value: "ID", key: "Idaho" },
		{ value: "IL", key: "Illinois" },
		{ value: "IN", key: "Indiana" },
		{ value: "IA", key: "Iowa" },
		{ value: "KS", key: "Kansas" },
		{ value: "KY", key: "Kentucky" },
		{ value: "LA", key: "Louisiana" },
		{ value: "ME", key: "Maine" },
		{ value: "MH", key: "Marshall Islands" },
		{ value: "MD", key: "Maryland" },
		{ value: "MA", key: "Massachusetts" },
		{ value: "MI", key: "Michigan" },
		{ value: "MN", key: "Minnesota" },
		{ value: "MS", key: "Mississippi" },
		{ value: "MO", key: "Missouri" },
		{ value: "MT", key: "Montana" },
		{ value: "NE", key: "Nebraska" },
		{ value: "NV", key: "Nevada" },
		{ value: "NH", key: "New Hampshire" },
		{ value: "NJ", key: "New Jersey" },
		{ value: "NM", key: "New Mexico" },
		{ value: "NY", key: "New York" },
		{ value: "NC", key: "North Carolina" },
		{ value: "ND", key: "North Dakota" },
		{ value: "MP", key: "Northern Mariana Islands" },
		{ value: "OH", key: "Ohio" },
		{ value: "OK", key: "Oklahoma" },
		{ value: "OR", key: "Oregon" },
		{ value: "PW", key: "Palau" },
		{ value: "PA", key: "Pennsylvania" },
		{ value: "PR", key: "Puerto Rico" },
		{ value: "RI", key: "Rhode Island" },
		{ value: "SC", key: "South Carolina" },
		{ value: "SD", key: "South Dakota" },
		{ value: "TN", key: "Tennessee" },
		{ value: "TX", key: "Texas" },
		{ value: "UT", key: "Utah" },
		{ value: "VT", key: "Vermont" },
		{ value: "VI", key: "Virgin Islands" },
		{ value: "VA", key: "Virginia" },
		{ value: "WA", key: "Washington" },
		{ value: "WV", key: "West Virginia" },
		{ value: "WI", key: "Wisconsin" },
		{ value: "WY", key: "Wyoming" },
	];

	domainStores.BindQuoteRequestViewModel.setValue("policyHolderName", domainStores.QuoteRequestViewModel.getValue(
		"customerName",
	) as string);

	const doSubmit = (e: any) => {
		e.preventDefault();
		// PW: Fields required: these are not in the form but are required for the API
		let currDate: Date = new Date();

		let primaryQuoteId: string = props.quoteId;
		let excessQuoteId: string = "";
		let quoteArr: string[] = props.quoteId.split("+");
		if (quoteArr !== null && quoteArr.length > 1) {
			primaryQuoteId = quoteArr[0];
			excessQuoteId = quoteArr[1];
		}
		domainStores.BindQuoteRequestViewModel.setValue("quoteId", primaryQuoteId);
		domainStores.BindQuoteRequestViewModel.setValue("excessQuoteId", excessQuoteId);
		domainStores.BindQuoteRequestViewModel.setValue("excessUnderlyingCarrier", "");
		domainStores.BindQuoteRequestViewModel.setValue("excessUnderlyingPolicy", "");
		domainStores.BindQuoteRequestViewModel.setValue("createdDate", currDate.toISOString());

		if (!domainStores.BindQuoteRequestViewModel.isModelValid()) {
			setErrorMessage("");
		} else {
			/* setModalActive(true); */
			domainStores.BindQuoteRequestViewModel.GetBindQuoteAsync().then(() => {
				if (domainStores.QuoteStore.bindResponseSuccess) {
					setModalActive(true);
				}
			});
		}
	};

	const resetClick = () => {
		domainStores.QuoteRequestViewModel.setModel(new QuoteRequestModel());
		domainStores.QuoteResponseViewModel.setModel(new QuoteResponseModel());
		domainStores.BindQuoteRequestViewModel.setModel(new BindQuoteModel());
		domainStores.MapStore.ResetLocation;

		history.push("/");
	};

	const selectPaymentOptions = (paymentPanelNumber: number) => {
		setPaymentPanel(paymentPanelNumber);
		domainStores.BindQuoteRequestViewModel.setValue("paymentOption", paymentMethodsArr[paymentPanelNumber - 1].value);
	};

	const sameAddress = () => {
		domainStores.BindQuoteRequestViewModel.setValue(
			"address1",
			domainStores.QuoteResponseViewModel.model.quoteRequest.address,
		);
		domainStores.BindQuoteRequestViewModel.setValue("address2", "");
		domainStores.BindQuoteRequestViewModel.setValue(
			"city",
			domainStores.QuoteResponseViewModel.model.quoteRequest.city,
		);
		domainStores.BindQuoteRequestViewModel.setValue(
			"state",
			domainStores.QuoteResponseViewModel.model.quoteRequest.state,
		);
		domainStores.BindQuoteRequestViewModel.setValue(
			"zipcode",
			domainStores.QuoteResponseViewModel.model.quoteRequest.zipcode,
		);
	};

	const clearAddress = () => {
		domainStores.BindQuoteRequestViewModel.setValue("address1", "");
		domainStores.BindQuoteRequestViewModel.setValue("address2", "");
		domainStores.BindQuoteRequestViewModel.setValue("city", "");
		domainStores.BindQuoteRequestViewModel.setValue("state", "");
		domainStores.BindQuoteRequestViewModel.setValue("zipcode", "");
	};

	const doToggle = () => {
		if (isSameAddress) {
			setIsSameAddress(false);
			clearAddress();
		} else {
			setIsSameAddress(true);
			sameAddress();
		}
	};

	const handleOnAddressChange = () => {
		if (isSameAddress) {
			setIsSameAddress(false);
		}
	};

	const policyAddress = useObserver(() => (
		<>
			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={4} className={"checkbox-container"}>
					<FormControlLabel
						control={
							<Checkbox
								checked={isSameAddress}
								className={"checkbox-margin"}
								onClick={doToggle}
								value="checkedB"
								color="primary"
							/>
						}
						label="Same as property address"
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policyholder or Company name</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="policyHolderName"
					/>
				</GridSpaced>
				<GridSpaced
					item
					sm={3}
					md={2}
					additionalcss={"display: flex; align-items: center;max-height: 30px; justify-content: flex-end;"}
				>
					<InputLabel>Type:</InputLabel>
				</GridSpaced>
				<GridSpaced
					item
					sm={9}
					md={3}
					className={"policy-selected-select-container"}
					additionalcss={"justify-content: flex-end;"}
				>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="select"
						fullwidth={true}
						fieldName="ownershipType"
						selectItems={typeArr}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policyholder email</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="email"
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>
						Policyholder
						<br /> address 1
					</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="address1"
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>
						Policyholder
						<br /> address 2
					</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="address2"
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policyholder City</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="city"
					/>
				</GridSpaced>
				<GridSpaced
					item
					sm={3}
					md={2}
					additionalcss={"display: flex; align-items: center;max-height: 30px; justify-content: flex-end;"}
				>
					<InputLabel>State:</InputLabel>
				</GridSpaced>
				<GridSpaced
					item
					sm={9}
					md={3}
					className={"policy-selected-select-container"}
					additionalcss={"justify-content: flex-end;"}
				>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="select"
						fieldName="state"
						selectItems={statesListArr}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policyholder zipcode</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={9} md={2}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fieldName="zipcode"
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container>
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policyholder phone</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={12} md={5}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type={"number"}
						fieldName="phone"
					/>
				</GridSpaced>
			</GridSpaced>
		</>
	));

	const policyDetail = (
		<>
			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policy effective date:</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={9} md={4}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="date"
						fieldName="dateEffective"
						format="MM/DD/YYYY"
						className="datePicker"
						fullwidth={true}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container margin="0px 0px 35px">
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Policy expiry date:</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={9} md={4}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="date"
						fieldName="dateExpiration"
						format="MM/DD/YYYY"
						className="datePicker"
						fullwidth={true}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container>
				<GridSpaced item sm={12} md={2}>
					<InputLabel>Mortgage info:</InputLabel>
				</GridSpaced>
				<GridSpaced item sm={9} md={10}>
					<EditableInput<BindQuoteModel>
						viewModel={domainStores.BindQuoteRequestViewModel}
						type="text"
						fullwidth={true}
						fieldName="mortgageDetails"
					/>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const policyPayment = (
		<>
			<PaymentOption
				icon={Iconagencybill}
				name="Agency Bill"
				onClick={() => selectPaymentOptions(1)}
				quote={props.chosenQuote}
				selected={paymentPanel === 1}
			/>
			<PaymentOption
				icon={Icononlinecheck}
				name="Online Check"
				onClick={() => selectPaymentOptions(2)}
				quote={props.chosenQuote}
				selected={paymentPanel === 2}
			/>
			<PaymentOption
				icon={IconcreditCard}
				name="Credit Card"
				onClick={() => selectPaymentOptions(3)}
				quote={props.chosenQuote}
				selected={paymentPanel === 3}
			/>
			{/* <GridSpaced item xs={12} md={4} margin="20px" additionalcss="max-width: 286px; width; 100%;">
				<Fade in={true}>
					<PaymentCard
						container
						alignItems="start"
						direction={"column"}
						padding="0"
						margin="0"
						additionalcss="border-left: 4px solid #1AB7EA; border-right: 4px solid #1AB7EA; border-bottom: 4px solid #1AB7EA; border-radius: 5px 5px 0px 0px; position: relative;"
						onClick={() => showPaymentPanel(2)}
					>
						<PaymentCard item xs={12}>
							<div className={"header featured"}>
								<h2>Online Check</h2>
							</div>
						</PaymentCard>
						<PaymentCard item xs={12} className={"secondary"}>
							<div className={"grouped"}>
								<PaymentCard container direction={"row"} justify="center" alignItems="center">
									<PaymentCard item xs={5} className={""}>
										<img src={icononlineCheck} alt={"Online Check"} />
									</PaymentCard>
									<PaymentCard item xs={2} additionalcss="text-align: right; font-weight: bold;">
										<h4>Quote:</h4>
										<h4>Fee:</h4>
										<h4>Total:</h4>
									</PaymentCard>
									<PaymentCard item xs={2} additionalcss="text-align: right;">
										<h3>$</h3>
										<h3>$</h3>
										<h3>$</h3>
									</PaymentCard>
									<PaymentCard item xs={3} additionalcss="text-align: right;">
										<h3>3018.47</h3>
										<h3>0.00</h3>
										<h3>3018.47</h3>
									</PaymentCard>
								</PaymentCard>
							</div>
						</PaymentCard>
						<PaymentCard item xs={12} additionalcss="text-align: center; position: absolute; bottom: -40px; left: 40%;">
							<img src={iconcheckSelected} alt={"Select"} />
						</PaymentCard>
					</PaymentCard>
				</Fade>
			</GridSpaced> */}

			{/* paymentPanel === 1 && (
				<>
					<GridSpaced container margin="30px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Payment type:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="radio"
								fullwidth={true}
								fieldName="paymentOption"
								selectItems={paymentMethodsArr}
								row={true}
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Quote:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<InputLabel>$ {roundToTwo(parseInt(props.chosenQuote.premiumTotal))}</InputLabel>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Surplus Lines Tax:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<InputLabel>$ {roundToTwo(parseInt(props.chosenQuote.taxTotal))}</InputLabel>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Fee:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<InputLabel>$ {roundToTwo(parseInt(props.chosenQuote.feeTotal))}</InputLabel>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Total:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<InputLabel>$ {roundToTwo(parseInt(props.chosenQuote.quoteTotal))}</InputLabel>
						</GridSpaced>
					</GridSpaced>
				</>
			) */}

			{paymentPanel === 2 && (
				<>
					<GridSpaced container margin="30px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Name on check:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="text"
								fieldName="checkName"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Bank name:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="text"
								fieldName="bankName"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Routing number:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="text"
								fieldName="routingNumber"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Account number:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="number"
								fieldName="accountNumber"
							/>
						</GridSpaced>
					</GridSpaced>
				</>
			)}

			{paymentPanel === 3 && (
				<>
					<GridSpaced container margin="30px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Name on card:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="text"
								fieldName="payerNameCard"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Card number:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={9} md={8}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="number"
								fieldName="cardNumber"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Card expiration:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={1} md={1}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="number"
								fieldName="cardExpirationMonth"
							/>
						</GridSpaced>
						<GridSpaced item sm={1} md={1}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="number"
								fieldName="cardExpirationYear"
							/>
						</GridSpaced>
					</GridSpaced>

					<GridSpaced container margin="0px 0px 15px">
						<GridSpaced item sm={12} md={2} className={"payment-label-parent"}>
							<InputLabel>Card CVV code:</InputLabel>
						</GridSpaced>
						<GridSpaced item sm={1} md={1}>
							<EditableInput<BindQuoteModel>
								viewModel={domainStores.BindQuoteRequestViewModel}
								type="number"
								fieldName="cardCvvCode"
							/>
						</GridSpaced>
					</GridSpaced>
				</>
			)}
		</>
	);

	const completeModal = (
		<>
			<PaymentModal
				open={modalActive}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalActive}>
					<PaymentPaper>
						<div className="customer-paymentheader">
							<h2>Your $ {roundToTwo(parseInt(props.chosenQuote.quoteTotal))} payment has been recorded</h2>
						</div>
						<div className="customer-mydetails">
							<p>Your application has been submitted to underwriting for approval.</p>
							<p>
								Coverage is not bound until agreed by Underwriting. If accepted, your payment will be processed and
								policy documents will be issued to the email address recorded in the application.
							</p>
							<p>Thank you for your business!</p>
							<div>
								<Button onClick={resetClick}>Go back to start</Button>
							</div>
						</div>
					</PaymentPaper>
				</Fade>
			</PaymentModal>
		</>
	);

	return useObserver(() => (
		<>
			<PolicyForm onSubmit={doSubmit}>
				{errorMessage !== "" && (
					<Typography variant="caption" style={{ color: "red" }}>
						{errorMessage}
					</Typography>
				)}
				<GridSpaced container justify="flex-start">
					<GridSpaced item xs={12}>
						{/* <Button onClick={() => setModalActive(true)}>modal</Button> */}
						<GridSpaced
							container
							justify="center"
							alignItems="center"
							margin={"10px auto"}
							padding={"42px 0px"}
							additionalcss={"max-width: 1040px; border-bottom: 1px solid #1ab7ea;"}
							className={"policy-selected-address-container"}
						>
							<GridSpaced item xs={12} margin="0px 0px 32px">
								<h2>Policyholder address</h2>
							</GridSpaced>
							{policyAddress}
						</GridSpaced>

						<GridSpaced
							container
							justify="center"
							alignItems="center"
							margin={"10px auto"}
							padding={"42px 0px"}
							additionalcss={"max-width: 1040px; border-bottom: 1px solid #1ab7ea;"}
						>
							<GridSpaced item xs={12} margin="0px 0px 32px">
								<h2>Policy details</h2>
							</GridSpaced>
							{policyDetail}
						</GridSpaced>

						<GridSpaced
							container
							justify="center"
							alignItems="center"
							margin={"10px auto"}
							padding={"42px 0px"}
							additionalcss={"max-width: 1040px; border-bottom: 1px solid #1ab7ea;"}
							className={"policy-selected-payment"}
						>
							<GridSpaced item xs={12} margin="0px 0px 32px">
								<h2>Payment</h2>
							</GridSpaced>
							{policyPayment}
						</GridSpaced>

						<GridSpaced container justify="center" alignItems="center" margin={"10px auto"} padding={"42px 0px"}>
							<SubmitBtn padding="7px 59px" onClick={doSubmit}>
								Pay now
							</SubmitBtn>
						</GridSpaced>
					</GridSpaced>
				</GridSpaced>
			</PolicyForm>
			{completeModal}
		</>
	));
};
