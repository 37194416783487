import React, { useState, useEffect } from "react";
import { GridSpaced } from "../../../Shoothill.Components/StylesAppSpecific/HomeStyling";

import {
	AltSelect,
	SterlingForm,
	SterlingLabel,
	SterlingText,
	SterlingSmallText,
	SubmitBtn,
	QuoteSlider,
} from "../../../Shoothill.Components/StylesAppSpecific/FormStyling";

import { AdjustBtn } from "../../../Shoothill.Components/StylesAppSpecific/QuoteStyling";

import { useMediaQuery } from "Core/Utils/Media";
import { useRouter } from "Core/Utils";
import { QuoteRequestModel } from "Custom/Models";
import { StoresInstance } from "Custom/Stores";
import { EditQuotFormGrid } from "Shoothill.Components/StylesAppSpecific/QuoteStyling";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { EditableInput } from "Core/Components/EditableInput";
import { observer, useObserver } from "mobx-react-lite";
import { isObservable } from "mobx";

const domainStores = StoresInstance.domain;

export const QuoteSliderCompEditQuote = (props: { fieldName: any; min: number; max: number; step: number }) => {
	return useObserver(() => (
		<QuoteSlider
			value={domainStores.QuoteRequestViewModel.getValue(props.fieldName)}
			onChange={(e: any, newValue: number | number[]) =>
				domainStores.QuoteRequestViewModel.setValue(props.fieldName, newValue as number)
			}
			max={props.max}
			min={props.min}
			step={props.step}
		/>
	));
};

const PriorLoss = (props: { priorLossArr: KeyValuePair[] }) => {
	return useObserver(() => (
		<>
			<GridSpaced
				container
				direction={"row"}
				alignItems="center"
				margin="0px 0px 20px"
				additionalcss={`${
					domainStores.QuoteRequestViewModel.model.priorLoss
						? "background-color: #F6F6F6; padding: 15px 20px 0px 20px; width: calc(100% + 40px);"
						: "padding: 0px 20px 0px 20px;"
				} margin: 0px -20px 0px -20px;`}
			>
				<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
					<GridSpaced item xs={12} md={3}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Prior loss:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced
						item
						xs={12}
						md={9}
						margin="0px"
						additionalcss={`${
							domainStores.QuoteRequestViewModel.model.priorLoss === "0" ||
							domainStores.QuoteRequestViewModel.model.priorLoss === "1"
								? "margin-left: 10px; margin-right: -10px;"
								: ""
						}`}
					>
						<GridSpaced item xs={6} md={9} className={"editquote-radio-group"}>
							<EditableInput<QuoteRequestModel>
								type="radio"
								viewModel={domainStores.QuoteRequestViewModel}
								fieldName="priorLoss"
								selectItems={props.priorLossArr}
								row={true}
							/>
						</GridSpaced>
					</GridSpaced>
				</GridSpaced>
				{domainStores.QuoteRequestViewModel.model.priorLoss === "1" && (
					<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
						<GridSpaced item xs={12} md={3}>
							<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
								Date of loss:
							</SterlingLabel>
						</GridSpaced>
						<GridSpaced item xs={12} md={9} margin="0px">
							<GridSpaced item xs={6} md={6} additionalcss={"display: flex; align-items: center;"}>
								<EditableInput<QuoteRequestModel>
									viewModel={domainStores.QuoteRequestViewModel}
									type="date"
									className={"datePicker"}
									fieldName="priorLossDate"
									format="MM/DD/YYYY"
								/>
							</GridSpaced>
						</GridSpaced>
					</GridSpaced>
				)}
				{domainStores.QuoteRequestViewModel.model.priorLoss === "1" && (
					<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
						<GridSpaced item xs={12} md={3}>
							<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
								Storm name:
							</SterlingLabel>
						</GridSpaced>
						<GridSpaced item xs={12} md={9} margin="0px">
							<GridSpaced item additionalcss={"display: flex; align-items: center;"}>
								<SterlingText<QuoteRequestModel>
									className={""}
									fieldName="priorLossStorm"
									viewModel={domainStores.QuoteRequestViewModel}
									additionalcss={"max-width: 386px; width: 100% !important;"}
								/>
							</GridSpaced>
						</GridSpaced>
					</GridSpaced>
				)}
				{domainStores.QuoteRequestViewModel.model.priorLoss === "1" && (
					<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
						<GridSpaced item xs={12} md={3}>
							<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
								Amount of loss:
							</SterlingLabel>
						</GridSpaced>
						<GridSpaced item xs={6} md={6} additionalcss={"max-width: 386px; display: flex; align-items: center;"}>
							<QuoteSliderCompEditQuote fieldName="priorLossAmount" max={1000000} min={50000} step={10000} />
						</GridSpaced>
						<GridSpaced
							item
							xs={6}
							md={3}
							additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
						>
							<SterlingText<QuoteRequestModel>
								className={"sliderText"}
								additionalcss={"width: 168px;"}
								fieldName="priorLossAmount"
								viewModel={domainStores.QuoteRequestViewModel}
							/>
						</GridSpaced>
					</GridSpaced>
				)}
			</GridSpaced>
		</>
	));
};

export const EditQuoteForm: React.FC = () => {
	const domainStores = StoresInstance.domain;

	let priorLossArr: KeyValuePair[] = [{ value: "1", key: "Yes" }, { value: "0", key: "No" }];

	let homeTypeArr: KeyValuePair[] = [
		{
			value: "residential",
			key: "House",
		},
		{
			value: "complex",
			key: "Multi-floor Complex",
		},
		{
			value: "commercial",
			key: "Commercial",
		},
	];

	let foundationTypeArr: KeyValuePair[] = [
		{
			value: "1A",
			key: "Slab on grade",
		},
		{
			value: "2A",
			key: "Raised floor with basement",
		},
		{
			value: "8",
			key: "Raised floor with crawlspace",
		},
		{
			value: "5",
			key: "Elevated on piers",
		},
	];

	let buildingUseArr: KeyValuePair[] = [
		{
			value: "primary",
			key: "Primary",
		},
		{
			value: "secondary",
			key: "Secondary",
		},
	];

	let propertyTypeArr: KeyValuePair[] = [
		// {
		//     value: "0",
		//     key: "Commercial"
		// },
		// {
		//     value: "1",
		//     key: "Residential"
		// }
		{
			value: "residential",
			key: "Residential",
		},
		{
			value: "complex",
			key: "Multi-floor Complex",
		},
		{
			value: "commercial",
			key: "Commercial",
		},
	];

	let constructionTypeArr: KeyValuePair[] = [
		{
			value: "BrickVeneer",
			key: "Brick veneer",
		},
		{
			value: "Masonry",
			key: "Masonry",
		},
		{
			value: "Frame",
			key: "Frame",
		},
		{
			value: "Stucco",
			key: "Stucco",
		},
	];

	let enclosureTypeArr: KeyValuePair[] = [
		{
			value: "Elevator",
			key: "Elevator",
		},
		{
			value: "Unfinished",
			key: "Unfinished",
		},
		{
			value: "Storage",
			key: "Storage",
		},
		{
			value: "Garage",
			key: "Garage",
		},
		{
			value: "Entrance",
			key: "Entrance",
		},
		{
			value: "Bath",
			key: "Bath",
		},
		{
			value: "Gameroom",
			key: "Game room",
		},
		{
			value: "Livingspace",
			key: "Living space",
		},
	];

	let elevatorArr: KeyValuePair[] = [{ value: "1", key: "Yes" }, { value: "0", key: "No" }];

	let frontalDuneArr: KeyValuePair[] = [{ value: "1", key: "Yes" }, { value: "0", key: "No" }];

	let numberOfFloors: number = domainStores.QuoteRequestViewModel.getValue("numberFloors") as number;

	let commercialArr: KeyValuePair[] = [
		{ value: "Airport", key: "Airport" },
		{
			value: "AmusementParkorFairground",
			key: "Amusement Park or Fairground",
		},
		{ value: "AuditoriumorArena", key: "Auditorium or Arena" },
		{ value: "AutoDealerServiceRepair", key: "Auto Dealer Service Repair" },
		{
			value: "AutoDealerShowroomOffice",
			key: "Auto Dealer Showroom Office",
		},
		{ value: "Bakery", key: "Bakery" },
		{ value: "BarorTavern", key: "Bar or Tavern" },
		{ value: "BeautySalon", key: "Beauty Salon" },
		{ value: "BoatDealer", key: "Boat Dealer" },
		{ value: "BoatYardorMarina", key: "Boat Yard or Marina" },
		{ value: "BowlingAlley", key: "Bowling Alley" },
		{ value: "BreweryorDistillery", key: "Brewery or Distillery" },
		{ value: "BuildingSupplies", key: "Building Supplies" },
		{ value: "Camp", key: "Camp" },
		{ value: "Casino", key: "Casino" },
		{ value: "CementPlant", key: "Cement Plant" },
		{
			value: "ChemicalorFertilizerRisks",
			key: "Chemical or Fertilizer Risks",
		},
		{ value: "Church", key: "Church" },
		{
			value: "ClothingRetailorWholesale",
			key: "Clothing Retail or Wholesale",
		},
		{ value: "ClubHealthSocialSport", key: "Club Health Social Sport" },
		{
			value: "ContractorEquipmentDealer",
			key: "Contractor Equipment Dealer",
		},
		{ value: "ConvenienceStore", key: "Convenience Store" },
		{ value: "ConventionCenter", key: "Convention Center" },
		{ value: "DayCareCenter", key: "Day Care Center" },
		{ value: "DepartmentStore", key: "Department Store" },
		{ value: "DrugsStore", key: "Drugs Store" },
		{ value: "DryCleaner", key: "Dry Cleaner" },
		{ value: "ExhibitionHall", key: "Exhibition Hall" },
		{ value: "FineArtsDealers", key: "Fine Arts Dealers" },
		{ value: "FoodProcessing", key: "Food Processing" },
		{ value: "FuneralHomeorMortuary", key: "Funeral Home or Mortuary" },
		{
			value: "FurnitureRetailorWholesale",
			key: "Furniture Retail or Wholesale",
		},
		{ value: "GasolineStations", key: "Gasoline Stations" },
		{ value: "GrocersSmallRetail", key: "Grocers Small Retail" },
		{ value: "GrocerySupermarket", key: "Grocery Supermarket" },
		{ value: "GroceryWarehouse", key: "Grocery Warehouse" },
		{ value: "HabitationalApartments", key: "Habitational Apartments" },
		{ value: "HabitationalCondominium", key: "Habitational Condominium" },
		{ value: "HabitationalMultiFamily", key: "Habitational Multi Family" },
		{
			value: "HardwareRetailorWholesale",
			key: "Hardware Retail or Wholesale",
		},
		{ value: "Hospital", key: "Hospital" },
		{ value: "HotelorMotel", key: "Hotel or Motel" },
		{
			value: "JewelryRetailorWholesale",
			key: "Jewelry Retail or Wholesale",
		},
		{ value: "Kennels", key: "Kennels" },
		{ value: "LaboratoryResearch", key: "Laboratory Research" },
		{
			value: "LaboratoryEquipmentorSupplies",
			key: "Laboratory Equipment or Supplies",
		},
		{ value: "Laundry", key: "Laundry" },
		{ value: "Library", key: "Library" },
		{ value: "ManufacturingGeneral", key: "Manufacturing General" },
		{ value: "ManufacturingElectronics", key: "Manufacturing Electronics" },
		{
			value: "ManufacturingAutoorBoats",
			key: "Manufacturing Auto or Boats",
		},
		{ value: "Winery", key: "Winery" },
		{ value: "Woodworkers", key: "Woodworkers" },
	];

	const adjustNumberFloors = (amount: number) => {
		let currentVal = domainStores.QuoteRequestViewModel.getValue("numberFloors") as number;
		currentVal = Math.max(0, currentVal + amount);
		domainStores.QuoteRequestViewModel.setValue("numberFloors", currentVal);
	};

	const { history } = useRouter();
	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const rowOne = useObserver(() => (
		<>
			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12}>
					<h2>Customer</h2>
				</GridSpaced>
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Customer name:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px" className={"formcontrol-item-parent"}>
					<SterlingText<QuoteRequestModel>
						className={""}
						autoFocus={true}
						fieldName="customerName"
						viewModel={domainStores.QuoteRequestViewModel}
						additionalcss={"max-width: 386px; width: 100% !important;"}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} justify="center" alignItems="center" padding="0px 0px 20px">
				<GridSpaced item xs={12}>
					<h2>Coverage</h2>
				</GridSpaced>
				<GridSpaced container padding="0px 0px 20px">
					<GridSpaced item xs={12} md={3}>
						<SterlingLabel padding={"10px 0px"}>Dwelling coverage:</SterlingLabel>
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={6}
						additionalcss={"max-width: 386px; display: flex; align-items: center;"}
						className={"formcontrol-item-parent"}
					>
						<QuoteSliderCompEditQuote fieldName="coverageBuilding" max={1000000} min={50000} step={10000} />
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={3}
						additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
						className={"formcontrol-item-parent slider-input"}
					>
						<SterlingText<QuoteRequestModel>
							className={"sliderText"}
							additionalcss={"width: 168px;"}
							fieldName="coverageBuilding"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
						/>
					</GridSpaced>
				</GridSpaced>
				<GridSpaced container direction={"row"} alignItems="center" padding="0px 0px 20px">
					<GridSpaced item xs={12} md={3}>
						<SterlingLabel padding={"10px 0px"}>Contents coverage:</SterlingLabel>
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={6}
						additionalcss={"max-width: 386px; display: flex; align-items: center;"}
						className={"formcontrol-item-parent"}
					>
						<QuoteSliderCompEditQuote fieldName="coverageContents" max={1000000} min={50000} step={10000} />
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={3}
						additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
						className={"formcontrol-item-parent slider-input"}
					>
						<SterlingText<QuoteRequestModel>
							className={"sliderText"}
							additionalcss={"width: 168px;"}
							fieldName="coverageContents"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
						/>
					</GridSpaced>
				</GridSpaced>
				<GridSpaced
					container
					direction={"row"}
					alignItems="center"
					padding="0px 0px 38px"
					margin="0px 0px 0px"
					additionalcss={"border-bottom: 1px solid #1ab7ea;"}
				>
					<GridSpaced item xs={12} md={3}>
						<SterlingLabel padding={"10px 0px"}>Loss of use coverage:</SterlingLabel>
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={6}
						additionalcss={"max-width: 386px; display: flex; align-items: center;"}
						className={"formcontrol-item-parent"}
					>
						<QuoteSliderCompEditQuote fieldName="valueLossOfUse" max={1000000} min={50000} step={10000} />
					</GridSpaced>
					<GridSpaced
						item
						xs={6}
						md={3}
						additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
						className={"formcontrol-item-parent slider-input"}
					>
						<SterlingText<QuoteRequestModel>
							className={"sliderText"}
							additionalcss={"width: 168px;"}
							fieldName="valueLossOfUse"
							viewModel={domainStores.QuoteRequestViewModel}
							type={"number"}
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	));

	const blockTwo = useObserver(() => (
		<>
			<GridSpaced item xs={12}>
				<h2>Details from your quote request</h2>
			</GridSpaced>
			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Policy effective date:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px">
					<GridSpaced item xs={6} md={6} className={"formcontrol-item-parent"}>
						<EditableInput<QuoteRequestModel>
							viewModel={domainStores.QuoteRequestViewModel}
							type="date"
							className={"datePicker"}
							fieldName="dateEffective"
							format="MM/DD/YYYY"
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Policy address:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px" className={"formcontrol-item-parent"}>
					<SterlingText<QuoteRequestModel>
						className={""}
						fieldName="address"
						viewModel={domainStores.QuoteRequestViewModel}
						additionalcss={"max-width: 386px; width: 100% !important;"}
					/>
				</GridSpaced>
			</GridSpaced>

			{/*    */}

			<PriorLoss priorLossArr={priorLossArr} />

			{/*    */}

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Home type:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px">
					<GridSpaced item xs={12} md={12} additionalcss={"min-width: 100%;"} className={"editquote-radio-group"}>
						<EditableInput<QuoteRequestModel>
							type="radio"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="buildingType"
							selectItems={homeTypeArr}
							row={true}
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Foundation type:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px" className={"formcontrol-item-parent select-input"}>
					<SterlingText<QuoteRequestModel>
						type="select"
						fieldName="buildingDiagram"
						viewModel={domainStores.QuoteRequestViewModel}
						selectItems={foundationTypeArr}
						additionalcss={"max-width: 386px; width: 100% !important;"}
					/>
				</GridSpaced>
			</GridSpaced>
			<GridSpaced
				container
				direction={"row"}
				alignItems="center"
				padding="0px 0px 38px"
				margin="0px 0px 0px"
				additionalcss={"border-bottom: 1px solid #1ab7ea;"}
			>
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Building use:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px" className={"formcontrol-item-parent  select-input"}>
					<EditableInput<QuoteRequestModel>
						type="select"
						fieldName="buildingUse"
						viewModel={domainStores.QuoteRequestViewModel}
						selectItems={
							domainStores.QuoteRequestViewModel.getValue("buildingType") !== "commercial"
								? buildingUseArr
								: commercialArr
						}
					/>
				</GridSpaced>
			</GridSpaced>
		</>
	));

	const blockThree = useObserver(() => (
		<>
			<GridSpaced item xs={12}>
				<h2>Further information</h2>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced
					item
					xs={6}
					additionalcss={`display: flex; 
                        flex-direction: row; 
                        justify-content: 
                        space-between; 
                        min-width: 340px;`}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							CBRA:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={""}>
						<EditableInput<QuoteRequestModel>
							type="text"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="cbra"
							row={true}
							editMode={false}
							label={domainStores.QuoteRequestViewModel.getValue("cbra") as string}
						/>
					</GridSpaced>
				</GridSpaced>

				<GridSpaced
					item
					xs={6}
					additionalcss={"display: flex; flex-direction: row; justify-content: space-between; min-width: 340px;"}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Floodzone:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={""}>
						<EditableInput<QuoteRequestModel>
							type="text"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="floodZone"
							row={true}
							editMode={false}
							label={domainStores.QuoteRequestViewModel.getValue("floodZone") as string}
						/>
					</GridSpaced>
					<GridSpaced item xs={6} md={3}></GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Property type:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px">
					<GridSpaced item xs={12} md={12} additionalcss={"min-width: 100%;"} className={"editquote-radio-group"}>
						<EditableInput<QuoteRequestModel>
							type="radio"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="buildingType"
							selectItems={propertyTypeArr}
							row={true}
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Year built:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={6} md={6} additionalcss={"max-width: 386px; display: flex; align-items: center;"}>
					<QuoteSliderCompEditQuote fieldName="yearBuilt" max={2020} min={1000} step={1} />
				</GridSpaced>
				<GridSpaced
					item
					xs={6}
					md={3}
					additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
				>
					<SterlingText<QuoteRequestModel>
						className={"sliderText 1"}
						additionalcss={"width: 168px;"}
						fieldName="yearBuilt"
						viewModel={domainStores.QuoteRequestViewModel}
						type={"number"}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Replacement cost:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={6} md={6} additionalcss={"max-width: 386px; display: flex; align-items: center;"}>
					<QuoteSliderCompEditQuote fieldName="valueBuilding" max={10000000} min={50000} step={10000} />
				</GridSpaced>
				<GridSpaced
					item
					xs={6}
					md={3}
					additionalcss={"text-align: right; display: flex; align-items: center; justify-content: flex-end;"}
				>
					<SterlingText<QuoteRequestModel>
						className={"sliderText"}
						additionalcss={"width: 168px;"}
						fieldName="valueBuilding"
						viewModel={domainStores.QuoteRequestViewModel}
						type={"number"}
					/>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced
					item
					xs={6}
					additionalcss={"display: flex; flex-direction: row; justify-content: space-between; min-width: 340px;"}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Construction type:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={"formcontrol-item-parent select-input"}>
						<EditableInput<QuoteRequestModel>
							type="select"
							fieldName="constructionType"
							viewModel={domainStores.QuoteRequestViewModel}
							selectItems={constructionTypeArr}
						/>
					</GridSpaced>
				</GridSpaced>

				<GridSpaced
					item
					xs={6}
					additionalcss={"display: flex; flex-direction: row; justify-content: space-between; min-width: 340px;"}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Number of floors:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" additionalcss={"display: flex; align-items: center;"}>
						<GridSpaced
							item
							xs={6}
							md={6}
							additionalcss={"display: inline-flex;"}
							className={"formcontrol-item-parent counter-control-parent"}
						>
							<AdjustBtn
								onClick={() => adjustNumberFloors(-1)}
								border={"1px solid #1ab7ea;"}
								borderLeft={"1px solid #1ab7ea;"}
							>
								-
							</AdjustBtn>
							<SterlingSmallText
								type="number"
								className={"sliderText 3"}
								fieldName="numberFloors"
								viewModel={domainStores.QuoteRequestViewModel}
								inputProps={{ min: "0" }}
							/>
							<AdjustBtn
								onClick={() => adjustNumberFloors(1)}
								border={"1px solid #1ab7ea;"}
								borderRight={"1px solid #1ab7ea;"}
							>
								+
							</AdjustBtn>
						</GridSpaced>
					</GridSpaced>
					<GridSpaced item xs={6} md={3}></GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced
					item
					xs={6}
					additionalcss={`display: flex; 
                        flex-direction: row; 
                        justify-content: 
                        space-between; 
                        min-width: 340px;`}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Enclosure/basement:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={"formcontrol-item-parent select-input"}>
						<EditableInput<QuoteRequestModel>
							type="multi-select"
							fieldName="enclosureType"
							viewModel={domainStores.QuoteRequestViewModel}
							selectItems={enclosureTypeArr}
						/>
					</GridSpaced>
				</GridSpaced>

				<GridSpaced
					item
					xs={6}
					additionalcss={"display: flex; flex-direction: row; justify-content: space-between; min-width: 340px;"}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Encl. SQ FT:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={"formcontrol-item-parent select-input"}>
						<EditableInput<QuoteRequestModel>
							type="number"
							fieldName="enclosureSqft"
							viewModel={domainStores.QuoteRequestViewModel}
						/>
					</GridSpaced>
					<GridSpaced item xs={6} md={3}></GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Elevator:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px">
					<GridSpaced item xs={6} md={6} additionalcss={"min-width: 100%;"} className={"editquote-radio-group"}>
						<EditableInput<QuoteRequestModel>
							type="radio"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="elevator"
							selectItems={elevatorArr}
							row={true}
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced
					item
					xs={6}
					additionalcss={`display: flex; 
                        flex-direction: row; 
                        justify-content: 
                        space-between; 
                        min-width: 340px;`}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Flr Elev vs BFE:
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={""}>
						<EditableInput<QuoteRequestModel>
							type="text"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="floodZone"
							row={true}
							editMode={false}
							label={domainStores.QuoteRequestViewModel.getValue("floodZone") as string}
						/>
					</GridSpaced>
				</GridSpaced>

				<GridSpaced
					item
					xs={6}
					additionalcss={"display: flex; flex-direction: row; justify-content: space-between; min-width: 340px;"}
				>
					<GridSpaced item xs={6}>
						<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
							Water distance (ft):
						</SterlingLabel>
					</GridSpaced>
					<GridSpaced item xs={6} margin="0px" className={""}>
						<EditableInput<QuoteRequestModel>
							type="text"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="floodZone"
							row={true}
							editMode={false}
							label={domainStores.QuoteRequestViewModel.getValue("floodZone") as string}
						/>
					</GridSpaced>
					<GridSpaced item xs={6} md={3}></GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced container direction={"row"} alignItems="center" margin="0px 0px 20px">
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Frontal dune:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px">
					<GridSpaced item xs={6} md={6} additionalcss={"min-width: 100%;"} className={"editquote-radio-group"}>
						<EditableInput<QuoteRequestModel>
							type="radio"
							viewModel={domainStores.QuoteRequestViewModel}
							fieldName="frontalDune"
							selectItems={frontalDuneArr}
							row={true}
						/>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>

			<GridSpaced
				container
				direction={"row"}
				alignItems="center"
				padding="0px 0px 38px"
				margin="0px 0px 0px"
				additionalcss={"border-bottom: 1px solid #1ab7ea;"}
			>
				<GridSpaced item xs={12} md={3}>
					<SterlingLabel padding={"10px 0px"} fontcolor={"#000000"}>
						Total SQ FT:
					</SterlingLabel>
				</GridSpaced>
				<GridSpaced item xs={12} md={9} margin="0px" additionalcss={"display: flex; align-items: center;"}>
					<SterlingText<QuoteRequestModel>
						type="number"
						fieldName="buildingSqft"
						viewModel={domainStores.QuoteRequestViewModel}
						additionalcss={"display: flex; align-items: center; width: 168px;"}
					/>
				</GridSpaced>
			</GridSpaced>
		</>
	));

	return useObserver(() => (
		<>
			<EditQuotFormGrid item xs={12} fontcolor={"#000000"}>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={12} margin={"0px 50px 50px"}>
						<SterlingForm>
							{rowOne}
							{blockTwo}
							{blockThree}
						</SterlingForm>
					</GridSpaced>
				</GridSpaced>
			</EditQuotFormGrid>
		</>
	));
};
