import { action, computed, observable, runInAction } from "mobx";
import { EnclosureSizeModel, EnclosureSizeModelDTO } from "../Models/EnclosureSizeModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class EnclosureSizeViewModel extends ViewModelBase<EnclosureSizeModel> {
	constructor() {
		super(new EnclosureSizeModel());
		this.setDecorators(EnclosureSizeModel);
	}

	public isFieldValid(fieldName: keyof EnclosureSizeModel & string, value: string | number): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		//You need this two properties after validation
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}
}
