import { action, runInAction } from "mobx";
import { QuoteRequestModel } from "../Models/QuoteRequestModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { QuoteResponseModel, QuoteResponseModelDTO } from "../Models/QuoteResponseModel";
import { Server } from "Custom/Globals/AppUrls";
import { StoresInstance } from "Custom/Stores";

export class QuoteRequestViewModel extends ViewModelBase<QuoteRequestModel> {
	constructor() {
		super(new QuoteRequestModel(), true);
		this.setDecorators(QuoteRequestModel);
	}

	public isFieldValid(fieldName: keyof QuoteRequestModel & string, value: string | number): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		if (!isValid) {
			console.log("Field " + fieldName + " is not valid");
		}
		//You need to this two properties after validation
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}

	@action
	public getExistingQuote = async (quoteId: string) => {
		let response = await this.Get(Server.Api.Quotes.GetExistingQuote + "/" + quoteId);
		if (response.wasSuccessful) {
			let domain = new QuoteRequestModel();
			domain.fromDto(response.payload);
			domain.priorLoss = "0";
			domain.priorLossDate = new Date().toISOString();
			this.setModel(domain, true);
			/*setTimeout(() => {
				this.resetModel();
			}, 5000);*/
		}
	};

	@action
	public GetQuotesAsync = async (): Promise<boolean> => {
		this.saveModel();
		let apiResult = await this.Post<QuoteResponseModelDTO>(Server.Api.Quotes.GetQuote, this.model);
		runInAction(() => {
			if (apiResult.wasSuccessful && apiResult.payload.quoteCount > 0) {
				let responseModel = new QuoteResponseModel();
				responseModel.fromDto(apiResult.payload);
				StoresInstance.domain.QuoteResponseViewModel.setModel(responseModel, true);
				this.model.setValue("yearBuilt", apiResult.payload.quoteRequest.yearBuilt);
				this.model.setValue("customerName", apiResult.payload.quoteRequest.customerName);
				if (
					apiResult.payload.quoteRequest.dateEffective !== null &&
					apiResult.payload.quoteRequest.dateEffective !== undefined
				) {
					this.model.setValue("dateEffective", apiResult.payload.quoteRequest.dateEffective);
				}
				this.model.setValue("coverageBuilding", apiResult.payload.quoteRequest.coverageBuilding);
				this.model.setValue("coverageContents", apiResult.payload.quoteRequest.coverageContents);
				this.model.setValue("valueLossOfUse", apiResult.payload.quoteRequest.valueLossOfUse);
				this.model.setValue("floodZone", apiResult.payload.quoteRequest.floodZone);
				this.model.setValue("valueBuilding", apiResult.payload.quoteRequest.valueBuilding);
				this.model.setValue("buildingSqft", apiResult.payload.quoteRequest.buildingSqft);
				this.model.setValue("cbra", apiResult.payload.quoteRequest.cbra);
			}
		});

		return apiResult.wasSuccessful;
	};

	public resetAll = () => {
		this.model.resetAll();
	};
}
