import React from "react";
import { withStyles, createStyles, createMuiTheme, Theme, Grid } from "@material-ui/core";

interface Props {
	children: any;
}

export const Centered = ({ children }: Props) => {
	return (
		<Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
			<Grid item>{children}</Grid>
		</Grid>
	);
};
