import React, { useContext, useState } from "react";
import { useRouter } from "../../Core/Utils";

import { AppHeaderStart, BackLink, HomeMain, MainRoot } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Link, Grid } from "@material-ui/core";

import { useMediaQuery } from "Core/Utils/Media/mediaQuery";

import { ConstructionType } from "Custom/Components/PropertyTypes/ConstructionType";
import { StoresInstance } from "../Stores";

export const PropertyConstruction: React.FC = () => {
	const domainStores = StoresInstance.domain;
	const [resident, setResident] = useState<boolean>(true);
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const nextStep = (callbackVal: number) => {
		history.push("/primaryresidence");
	};

	function prevStep(): void {
		history.push("/propertytype");
	}

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<Grid container className="gridContainer">
							<Grid item xs={12}>
								{!isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
								<ConstructionType constructionType={nextStep} propertyTitlePart={"property"} />
								{isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
							</Grid>
						</Grid>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
