// Libs
import * as Axios from "axios";

// App
import { getApiUrl } from "../Utils";

export const GetDefaultConfig: () => Axios.AxiosRequestConfig = () => {
	return {
		validateStatus: undefined, // We handle the httpstatus validation throwing some custom errors objects.
		baseURL: getApiUrl(),
	};
};
