import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { iconBrickVeneer, iconFrame, iconHouse4Tile, iconMasonry, iconStucco } from "Content/EmbeddedSVGs";

import { Link } from "@material-ui/core";
import React from "react";
import { StoresInstance } from "../../Stores";
import { useMediaQuery } from "../../../Core/Utils/Media";

interface IProps {
	constructionType: (chosenVal: number) => void;
	propertyTitlePart: string;
}

export const ConstructionType: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;
	let choicesStyling: string = isMobile ? "max-width: 320px;" : "max-width: 900px;";
	let activeValue: string = domainStores.QuoteRequestViewModel.getValue("constructionType") as string;

	let headerImg: string;

	const panelActive = (panelValue: any) => {
		let retVal: string = "panelIcon";

		if (activeValue === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: string) => {
		domainStores.QuoteRequestViewModel.setValue("constructionType", clickVal);
		props.constructionType(6);
	};

	const rowOne = (
		<>
			<GridSpaced
				container
				direction="row"
				justify="center"
				alignItems="center"
				additionalcss={"margin: 0 auto; " + choicesStyling}
			>
				<GridSpaced item xs={6} sm={3} className={panelActive("BrickVeneer")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("BrickVeneer")}>
							{iconBrickVeneer}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("BrickVeneer")} className="panelLink">
						Brick veneer
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Masonry")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Masonry")}>
							{iconMasonry}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Masonry")} className="panelLink">
						Masonry
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Frame")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Frame")}>
							{iconFrame}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Frame")} className="panelLink">
						Frame
					</Link>
				</GridSpaced>
				<GridSpaced item xs={6} sm={3} className={panelActive("Stucco")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide("Stucco")}>
							{iconStucco}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide("Stucco")} className="panelLink">
						Stucco
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={isMobile ? "text-align: center;" : "text-align: center; svg {width: 64px;}"}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 30px"}
			>
				{iconHouse4Tile}
				What construction type is the {props.propertyTitlePart}?
			</H1TitleAndImg>
		</>
	);

	return (
		<>
			<GridSpaced item xs={12}>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12}>
						{headingTitle}
					</GridSpaced>
				</GridSpaced>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={12}>
						{rowOne}
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	);
};
