/*global google*/
import React, { useEffect, useContext, useRef } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import { GeocodeResult } from "../Models/GeocodeResult";
import styled from "styled-components";
import { useRouter } from "../../Core/Utils";
import { useObserver } from "mobx-react-lite";
import { StoresInstance } from "../Stores";

const useStyles: any = makeStyles((theme: Theme) => ({
	yourriskForm: {
		display: "flex",
		width: "100%",
		padding: "0",
		margin: "0",
	},
	textField: {
		backgroundColor: "#FFFFFF",
		height: "54px",
		marginRight: "5px",
		maxWidth: "100%",
		textAlign: "left",
		width: "100%",
		fontSize: "20px",
		padding: "10px",
		"@media (min-width: 1466px)": {
			minWidth: "305px",
		},
		placeholder: {
			color: "#000000",
		},
		color: "#000000",
		"@media (max-width: 767px)": {
			height: "34px",
			fontSize: "14px",
			fontFamily: "'Lato', sans-serif",
		},
	},
	textFieldZip: {
		maxWidth: "100%",
		backgroundColor: "#FFFFFF",
		paddingLeft: "5px",
		height: "54px",
		marginRight: "5px",
		color: "#464545",
		border: "1px solid #1ab7ea",
		opacity: "0.95",
		"@media (min-width: 1466px)": {
			maxWidth: "177px",
		},
		"& placeholder": {
			color: "#000000",
		},
	},
}));

export const StyledFormGoogle: any = styled.form`
	input {
		color: #464545 !important;
		font-weight: normal;
	}

	div[class$="-container"] {
		font-weight: normal;
		> div {
			padding: 0 0 0 4px;
			border: none;
			font-size: 21px;
			cursor: text;
			span {
				background-color: transparent;
			}
			div {
				padding: 0 0 0 4px;
				border: none;
			}
		}
	}

	div[class$="-menu"] {
		margin: 0;
		border-radius: 0;
		div {
			div {
				cursor: pointer;
				font-size: 16px;
				border-bottom: solid 1px #efefef;
				padding: 5px;
			}
		}
	}

	svg {
		top: 0 !important;
		width: 54px;
		height: 54px;
		color: transparent;
		cursor: pointer;
	}
`;

export interface IAddressSelectorGoogleProps {
	gotoNext?: boolean | undefined;
	pageSource?: string;
	callback?: () => void;
}

export const AddressSelectorGoogle: React.FC<IAddressSelectorGoogleProps> = props => {
	const theme: any = useTheme();
	const classes: any = useStyles(theme);
	const { history } = useRouter();
	const domainStores = StoresInstance.domain;
	const { gotoNext, callback, pageSource } = props;

	let searchLocationAutoComplete: google.maps.places.Autocomplete;
	let searchLocationInput: HTMLInputElement;
	let searchEventListener: google.maps.MapsEventListener;

	let inputReference: React.MutableRefObject<any> = useRef(null);

	const redirectToRisk: () => void = (): void => {
		history.push("/address");
	};

	const setLocation: any = (result: GeocodeResult) => {
		domainStores.QuoteRequestViewModel.setValue("address", result.placeName);
		domainStores.QuoteRequestViewModel.setValue("city", result.city);
		domainStores.QuoteRequestViewModel.setValue("county", result.county);
		domainStores.QuoteRequestViewModel.setValue("state", result.region);
		domainStores.QuoteRequestViewModel.setValue("zipcode", result.zipcode);
		domainStores.QuoteRequestViewModel.setValue("latitude", result.center[1].toString());
		domainStores.QuoteRequestViewModel.setValue("longitude", result.center[0].toString());

		callback && callback();

		// if (pageSource !== undefined && pageSource.length > 1) {
		// 	mapStoreContext.SetPageSource(pageSource);
		// }

		if (gotoNext !== undefined && gotoNext) {
			redirectToRisk();
		}
	};

	useEffect(() => {
		if (inputReference.current != null) {
			searchLocationInput = inputReference.current!;

			searchLocationAutoComplete = new google.maps.places.Autocomplete(searchLocationInput, {
				types: ["address"],
				componentRestrictions: { country: "us" },
			});

			searchEventListener = google.maps.event.addListener(searchLocationAutoComplete, "place_changed", () => {
				const location: google.maps.places.PlaceResult = searchLocationAutoComplete!.getPlace();
				if (location && location.geometry && location.formatted_address && location.types) {
					let result: GeocodeResult = {
						bBox: [],
						center: [location.geometry!.location.lng(), location.geometry!.location.lat()],
						placeName: location.formatted_address!,
						placeType: location.types!,
						text: location.name,
						type: "",
						region: "",
						county: "",
						zipcode: "",
						city: "",
					};

					if (location.address_components) {
						location.address_components.forEach((comp: google.maps.GeocoderAddressComponent) => {
							if (comp.types.indexOf("administrative_area_level_1") >= 0) {
								result.region = comp.short_name;
							} else if (comp.types.indexOf("administrative_area_level_2") >= 0) {
								result.county = comp.long_name;
							} else if (comp.types.indexOf("postal_code") >= 0) {
								result.zipcode = comp.long_name;
							} else if (comp.types.indexOf("administrative_area_level_2") >= 0) {
								result.city = comp.short_name;
							}
						});
					}
					setLocation(result);
				}
			});
		}

		return () => {
			google.maps.event.removeListener(searchEventListener!);
		};
	}, []);

	let isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

	return useObserver(() => {
		return (
			<input
				ref={inputReference}
				onKeyPress={(e: any) => {
					if (e.keyCode === 13 || e.charCode === 13 || e.which === 13) {
						e.preventDefault();
						return false;
					}
				}}
				onChange={(e: any) => {
					domainStores.QuoteRequestViewModel.setValue("address", e.target.value);
				}}
				onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
					isMobile &&
						window.scrollTo({
							top: e.currentTarget.offsetTop - 90,
							left: 0,
							behavior: "smooth",
						});
				}}
				autoFocus={true}
				placeholder={"Please enter your address here..."}
				className={classes.textField}
				value={domainStores.QuoteRequestViewModel.getValue("address") as string}
			/>
		);
	});
};
