import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";
import { QuoteModel } from "./QuoteModel";
/* import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator"; */

export class QuoteDeductableModel extends ModelBase<QuoteDeductableModel, QuoteDeductableModelDTO> {
	@MobX.observable
	public quotes: QuoteModel[] = [];

	public fromDto = (model: QuoteDeductableModelDTO) => {};
	public toDto = (model: QuoteDeductableModel) => {};
}

export interface QuoteDeductableModelDTO {
	quotes: QuoteModel[];
}
