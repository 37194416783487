import React, { useContext, useState } from "react";
import { useRouter } from "../../Core/Utils";

import {
	AppHeaderStart,
	BackLink,
	HomeMain,
	MainRoot,
	GridSpaced,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Link, Grid } from "@material-ui/core";

import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { StoresContext, Stores } from "../Stores";
import { ProcessFlowStore } from "../Stores/Domain/ProcessFlowStore";

import { GetQuoteForm } from "Custom/Components/Quotes/GetQuoteForm";

export const GetQuote: React.FC = () => {
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	function prevStep(): void {
		history.goBack();
	}

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<GridSpaced
							container
							additionalcss={"height: unset; background-color: #3f3f3f; position: relative"}
							justify="flex-start"
						>
							<GridSpaced item xs={12}>
								{!isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
								<GetQuoteForm />
								{isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
							</GridSpaced>
						</GridSpaced>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
