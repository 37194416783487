import { TextField, InputLabel, Button, Slider, Select, Grid, Box, Link } from "@material-ui/core";
import styled from "styled-components";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
	border?: string;
	borderLeft?: string;
	borderRight?: string;
}

export const DeductableSlider: any = styled(Slider)<IComponentProps>`
	.MuiSlider-root {
		padding: 6px 0;
	}

	.MuiSlider-track,
	.MuiSlider-rail,
	.MuiSlider-trackFalse,
	.MuiSlider-thumb {
		background-color: #1ab7ea;
	}

	.MuiSlider-thumb,
	.MuiSlider-thumb.Mui-focusVisible,
	.MuiSlider-thumb:hover {
		box-shadow: unset;
		height: 43px;
		width: 43px;
		background-color: #ffffff;
		border: solid 2px #1ab7ea;
		margin-top: -21px;
		margin-left: -19px;
	}

	.MuiSlider-markLabel {
		margin-top: 15px !important;
	}
`;

export const QuoteHeadingBox: any = styled(Box)<IComponentProps>`
	text-align: center;
	display: flex;
	align-items: center;
	h1,
	div {
		display: inline-flex;
		margin: 0;
		padding: 0;
	}
	div {
		margin-right: 20px;
		vertical-align: middle;
	}
	div img {
		width: calc(284px + (498 - 350) * ((100vw - 350px) / (1366 - 350)));
		max-width: 44px;
	}
	h1 {
		font-size: calc(23px + (30 - 23) * ((100vw - 350px) / (1366 - 350)));
		margin-bottom: 11px;
		@media (min-width: 728px) {
			margin-bottom: 24px;
		}
	}
	svg {
		fill: #ffffff;
	}

	.edit-quote-icon-container {
		max-width: 30px;
		max-height: 37px;

		img {
			max-width: 30px;
			max-height: 37px;
		}
	}

	h1 {
		margin-bottom: 0px;
	}

	${props => props.additionalcss};
`;

export const QuoteCard: any = styled(Grid)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};

	.header,
	.secondary,
	.grouped,
	.premiumResult {
		cursor: pointer;
		h2,
		h3,
		h4 {
			margin: 0;
			color: #000000;
		}

		h3,
		h4 {
			line-height: 1.2;
		}

		background-color: #4b6770;
		padding: 0;

		h2 {
			font-size: 24px;
			font-weight: 400;
		}
		h4 {
			font-size: 12px;
			font-weight: 400;
		}
		h3 {
			font-size: 28px;
			font-weight: normal;
		}
	}

	.header {
		border-radius: 5px 5px 0px 0px;
		height: 78px;
		opacity: 1;
		h2 {
			padding-top: 20px;
			color: #ffffff;
		}
	}

	.secondary {
		height: 89px;
		background-color: #b7c2c6;
		padding-top: 14px;
	}

	.grouped {
		height: 88px;
		background-color: #eceff0;
		padding-top: 20px;
	}

	.header.featured {
		background-color: #1ab7ea;
		height: 88px;
		h2 {
			padding-top: 24px;
		}
	}

	.secondary.featured {
		background-color: #b9e2ee;
	}

	.grouped.featured {
		background-color: #ddf3f9;
	}

	.premiumResult {
		background-color: #ffffff;
		padding-top: 14px;
		h3 {
			font-size: 18px;
			font-weight: 400;
		}
		h2 {
			font-size: 34px;
			font-weight: bold;
		}
	}

	.interaction {
		display: inline-flex;
		font-size: 12px;
		height: 32px;
		padding: 7px;
		cursor: pointer;
		img {
			margin-right: 5px;
		}
	}

	.interaction.right {
		text-align: right;
	}

	${props => props.additionalcss};
`;

export const QuoteLink: any = styled(Link)<IComponentProps>`
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#1ab7ea")};
	font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "21px")};
	/* text-decoration: underline; */
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	cursor: pointer;
	display: block;
	@media (max-width: 767px) {
		padding: ${props => (props.padding !== undefined ? props.padding : "30px 0px 0px 15px")};
		display: block;
		text-align: center;
		font-size: 14px;
	}
	${props => props.additionalcss};
`;

export const QuoteBtn: any = styled(Button)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
	font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "14px")};
	border-radius: 5px;
	text-transform: unset;
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#1ab7ea")};
	:hover {
		opacity: 0.7;
		background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#1ab7ea")};
	}
	${props => props.additionalcss}
`;

//EditQuotFormGrid

export const EditQuotFormGrid: any = styled(GridSpaced)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};

	label {
		font-weight: bold;
		color: #000000;
		display: flex;
		align-items: center;
	}

	h2 {
		font-size: 32px;
	}

	.MuiSelect-select {
		max-height: 15px;
	}

	input {
		max-height: 30px;
	}

	.MuiSlider-root {
		:before {
			background-color: black !important;
		}

		:after {
			background-color: black !important;
			z-index: 1 !important;
		}

		.MuiSlider-rail,
		.MuiSlider-track {
			background: #000000;
			opacity: 1 !important;
		}

		.MuiSlider-thumb {
			z-index: 2 !important;
		}
	}

	span.MuiRadio-root {
		color: #1ab7ea;
		.MuiIconButton-label {
			> div {
				width: 20px;
				height: 20px;
			}
		}
	}

	span.MuiRadio-root.Mui-checked {
		.MuiIconButton-label {
			> div {
				svg:last-child {
					width: 28px;
					height: 28px;
					margin-left: -5px;
					margin-top: -5px;
				}
			}
		}
	}

	.MuiInput-formControl {
		margin-top: 0px !important;
	}

	.formcontrol-item-parent {
		> .MuiBox-root {
			display: flex;
			align-items: center;
		}
	}

	.formcontrol-item-parent.slider-input {
		> .MuiBox-root {
			justify-content: flex-end;
		}
	}

	.formcontrol-item-parent.select-input {
		display: flex;
		align-items: center;
	}

	.counter-control-parent {
		> .MuiBox-root {
			max-width: 32px;
			input {
				width: 30px;
				min-width: 30px;
			}
		}
	}

	.editquote-radio-group {
		.MuiFormControl-root {
			min-width: 100%;

			label {
				width: 100%;
				max-width: 245px;
			}

			label:last-child {
				margin-right: 0px;
			}
		}
	}

	.MuiSelect-selectMenu {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 132px;
		min-width: 156px !important;
		display: block !important;
		padding: 0px;
		max-height: 100%;
		line-height: 32px;
	}

	${props => props.additionalcss};
`;

export const AdjustBtn: any = styled(Button)<IComponentProps>`
	height: 32px;
	width: 32px !important;
	min-width: 32px;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	background-color: #ffffff;
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	:hover {
		background-color: #ffffff;
	}
	border: ${props => (props.border !== undefined ? props.border : "none")};
	border-left: ${props => (props.borderLeft !== undefined ? props.borderLeft : "none")};
	border-right: ${props => (props.borderRight !== undefined ? props.borderRight : "none")};
`;
