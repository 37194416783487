import { Button, Box, Grid } from "@material-ui/core";
import { EditableInput } from "Core/Components/EditableInput";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import styled from "styled-components";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
	textAlign?: string;
}

export const PolicyHeader: any = styled(Box)<IComponentProps>`
	display: flex;
	width: 1140px;
	height: 99px;
	background-color: #ffffff;
	color: black;
	box-shadow: 0px 0px 6px #00000042;
	border-radius: 5px 5px 0px 0px;
	@media (min-width: 768px) {
		display: flex;
		height: 100%;
	}
	flex-direction: column;
	align-items: left;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	h1 {
		display: block;
		font-size: 23px;
	}
	h2 {
		display: block;
		font-size: 14px;
	}
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0 auto")};
	${props => props.additionalcss};
`;

export const PolicyTitle: any = styled(Box)<IComponentProps>`
	/* text-align: center;*/
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 30px 50px;

	.policy-selected-icon-container {
		padding-right: 15px;
		display: flex;
		align-items: center;
	}

	.policy-selected-title-container {
		> h1,
		h2 {
			margin: 0px;
		}

		h2,
		span {
			font-weight: 100;
		}
	}

	${props => props.additionalcss};
`;

export const PolicyForm: any = styled.form<IComponentProps>`
	width: 100%;

	h2 {
		text-align: left;
		font: 400 32px "Segoe UI";
		letter-spacing: 0;
		margin: 0px;
	}

	label {
		height: 30px;
		align-items: center;
	}

	input,
	.MuiSelect-select {
		height: 15px;
		align-items: center;
	}

	label {
		text-align: left;
		font: 400 18px "Segoe UI";
		letter-spacing: 0;
		color: #000000;
	}

	label + .MuiInput-formControl {
		margin-top: 0px;
	}

	input,
	.MuiSelect-select {
		border: 1px solid #1ab7ea;
		font-size: 14px;
		width: 100%;
		background: #ffffff;
		padding-left: 10px;
	}

	.MuiSelect-select {
		display: flex;
		min-width: 132px !important;
	}

	.MuiSelect-icon {
		top: calc(50% - 15px);
	}

	.datePicker {
		button {
			width: 45px;
			border-radius: 0px;
			padding: 5px;
			margin-right: 0px;
		}

		input,
		.MuiInputBase-root {
			width: 100%;
			max-width: 170px;
		}

		span.MuiIcon-root {
			img {
				width: 20px;
				height: 20px;
				padding: 0px;
				margin-bottom: 2px;
			}
		}
	}

	span.MuiRadio-root {
		color: #1ab7ea;
		.MuiIconButton-label {
			> div {
				background-color: #ffffff;
				border-radius: 50%;
				border: 1px solid #1ab7ea;
				width: 20px;
				height: 20px;
				svg:first-child {
					fill: #ffffff;
					display: none;
				}
				svg:last-child {
					fill: #000000;
					display: none;
				}
			}
		}
		.MuiIconButton-root {
			padding: 3px 10px;
		}
	}

	.MuiTypography-root,
	.MuiTypography-body1 {
		font-size: 16px !important;
	}

	span.MuiRadio-root.Mui-checked {
		.MuiIconButton-label {
			> div {
				svg:first-child {
					fill: #ffffff;
					display: none;
				}
				svg:last-child {
					fill: #000000;
					width: 22px;
					height: 22px;
					margin-left: -2px;
					margin-top: -2px;
					display: block;
				}
			}
		}
	}

	.MuiCheckbox-colorPrimary.Mui-checked {
		color: #1ab7ea;
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after {
		border-bottom: 0px !important;
		transition: unset;
	}

	.policy-selected-address-container {
		> .MuiGrid-root {
			display: flex;
			align-items: center;
		}
		.MuiBox-root {
			display: flex;
		}
	}

	.policy-selected-select-container {
		display: flex;
		align-items: center;

		.MuiBox-root {
			display: flex;
		}
	}

	.MuiInput-root {
		position: relative;

		div.MuiInputAdornment-root {
			position: absolute;
			right: 0px;
		}

		svg.MuiSvgIcon-root {
			width: 26px;
			height: 26px;
		}
	}

	.policy-selected-payment {
		.payment-label-parent {
			label {
				max-width: 140px;
			}
		}
	}

	/* .checkbox-container {
        svg {
            stroke-width: 1px;
            box-shadow: none;
            width: 30px;
            height: 30px;
            stroke: transparent;
            fill: transparent;
            border: 1px solid #1ab7ea;
        }
    } */

	.checkbox-margin {
		input {
			margin-top: 18px;
		}
	}
`;

export const SubmitBtn: any = styled(Button)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
	border-radius: 5px;
	background-color: #1ab7ea;
	${props => props.additionalcss}

	:hover {
		background-color: #0f799b;
	}
`;

export const PaymentCard: any = styled(Grid)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};

	.header,
	.secondary,
	.grouped,
	.premiumResult {
		cursor: pointer;
		h2,
		h3,
		h4 {
			margin: 0;
			color: #000000;
		}

		h3,
		h4 {
			line-height: 1.2;
		}

		background-color: #4b6770;
		padding: 0;

		h2 {
			font-size: 24px;
			font-weight: 400;
			text-align: center;
		}
		h4 {
			font-size: 12px;
			font-weight: bold;
			padding-bottom: 10px;
		}
		h3 {
			font-size: 12px;
			font-weight: 400;
			padding-bottom: 10px;
		}
	}

	.header {
		/* border-radius: 5px 5px 0px 0px; */
		height: 58px;
		opacity: 1;
		h2 {
			padding-top: 14px;
			color: #ffffff;
		}
	}

	.secondary {
		height: 89px;
		background-color: #ffffff;
		// padding-top: 14px;
	}

	.grouped {
		height: 144px;
		background-color: #ffffff;
		padding: 20px;
	}

	.grouped:not(.featured) {
		margin: 0 3px 4px;

		.cioc,
		.dioc {
			fill: #4b6770;
		}

		.bioc,
		.cioc {
			stroke: #4b6770;
		}
	}

	.header.featured {
		background-color: #1ab7ea;
		height: 58px;
		h2 {
			padding-top: 14px;
		}
	}

	.secondary.featured {
		background-color: #b9e2ee;
	}

	.grouped.featured {
		/* background-color: #ddf3f9; */
		.aiab,
		.biab,
		.aicc {
			stroke: #1ab7ea;
		}

		.bicc,
		.ciab {
			fill: #1ab7ea;
		}
	}

	.premiumResult {
		background-color: #ffffff;
		padding-top: 14px;
		h3 {
			font-size: 18px;
			font-weight: 400;
		}
		h2 {
			font-size: 34px;
			font-weight: bold;
		}
	}

	.interaction {
		display: inline-flex;
		font-size: 12px;
		height: 32px;
		padding: 7px;
		cursor: pointer;
		img {
			margin-right: 5px;
		}
	}

	.interaction.right {
		text-align: right;
	}

	img {
		cursor: pointer;
	}

	${props => props.additionalcss};
`;
