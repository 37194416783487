import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";

export class QuoteResponseNoQuotesModel extends ModelBase<QuoteResponseNoQuotesModel, QuoteResponseNoQuotesModelDTO> {
	@MobX.observable
	public success: boolean = false;

	@MobX.observable
	public messages: string = "";

	@MobX.observable
	public quoteCount: number = 0;

	@MobX.observable
	public createdDate: string = "";

	public fromDto = (model: QuoteResponseNoQuotesModelDTO) => {
		for (let key in model) {
			if (model.hasOwnProperty(key)) {
				this[key] = model[key];
			}
		}
	};
	public toDto = (model: QuoteResponseNoQuotesModel) => {};
}

export interface QuoteResponseNoQuotesModelDTO {
	success: boolean;
	messages: string;
	quoteCount: number;
	createdDate: string;
}
