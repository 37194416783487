import { BaseStore } from "./BaseStore";
import { observable, autorun, action, computed, observe, runInAction, when } from "mobx";
import { Stores } from "../Stores";
import { LockForm } from "../../../Core/Models";
import { Disposer } from "../../../Core/Models";
import { DomainStores } from "../DomainStores";
import { InitialState } from "../../Models";
import axios, { AxiosResponse } from "axios";
import { ApiResult } from "Core/Models/ApiResult";
import { HubConnection, HubConnectionBuilder, HubConnectionState, RetryContext } from "@microsoft/signalr";

export class FormLockConcurrencyHubStore extends BaseStore {
	private signalRConcurrencyHubConnection: HubConnection;
	private domainStores?: DomainStores;

	@observable private formLocks = observable<{ [ref: string]: { locked: boolean; lockedBy: string; id: string } }>({}); // Keep track of who by and if all form types are locked
	@observable private formRef = ""; // The form ref the client is currently tracking
	@observable private disposers: Disposer[] = []; // Disposers to unregister from notifications
	@observable private connectionQueue: (() => void)[] = []; // Queue of callbacks to invoke when the connection is started
	@observable private registeredForms: number[] = []; // Keep track of which forms the client has registered to recieve notification for
	@observable private cid: string = ""; // The current connection id
	@observable private myLockedForms: number[] = []; // The forms the client intents to be locked (to keep track of which forms should be re-claimed if the server goes down)
	@observable private signalRAccessToken: string = "";
	@observable private connectedServer: string = "";
	@observable private enabled: boolean = false;

	constructor() {
		super();

		this.signalRConcurrencyHubConnection = new HubConnectionBuilder()
			.withUrl(`/hubs/concurrency/formlock`, { accessTokenFactory: () => this.signalRAccessToken }) // Access token to allow authentication, also allows server to get the username of the client
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: (context: RetryContext) => {
					return 1000;
				},
			}) // Don't stop attempting to reconnect
			.build();
	}

	@action
	public init(stores: Stores, initialState: InitialState) {
		this.domainStores = stores.domain;
		this.signalRAccessToken = initialState.accountStatus.signalRAccessToken;

		runInAction(() => {
			this.enabled = initialState.appSettings.useFormLockConcurrency === "true";
		});

		autorun(() => {
			if (stores.domain.AccountStore.IsLoggedIn && this.signalRAccessToken) {
				this.connect();
			} else {
				if (this.enabled) {
					axios.post(`/api/account/GetSignalRToken`).then((apiResult: AxiosResponse<ApiResult<string>>) => {
						if (apiResult.status === 200 && apiResult.data && apiResult.data.wasSuccessful) {
							this.setSignalRAccessToken(apiResult.data.payload);
						}
					});
				} else {
					this.disconnect();
				}
			}
		});
	}

	@action
	public enqueueToConnectionQueue(cb: () => void) {
		this.connectionQueue.push(cb);
	}

	@action
	private setInitialFormLocks() {
		this.signalRConcurrencyHubConnection
			.invoke(`GetAreFormsLockedAsync`, this.registeredForms, this.formRef)
			.then((initalFormLocks: string[]) => {
				initalFormLocks.forEach((lockedBy: string, index: number) => {
					runInAction(() => {
						this.formLocks[this.registeredForms[index]] = { locked: lockedBy !== "", lockedBy: lockedBy, id: "" };
					});
				});
			});
	}

	@action
	public setSignalRAccessToken(token: string) {
		this.signalRAccessToken = token;
	}

	// Track a new set of forms
	@action
	public setFormsInfo(ref: string, forms: number[]) {
		// Create callback
		const setForms = () => {
			// Dispose to unregister from any previous notifications
			this.disposers.forEach(disposer => {
				disposer.dispose();
			});
			// Reset disposers
			this.disposers = [];
			// Reset which forms are locked
			this.formLocks = this.getResetFormLocks;
			// Set the reference for the new forms
			this.formRef = ref;
			// Set new forms as the registered forms
			this.registeredForms = forms;
			// Register for notifications for new forms
			this.registerForms();
			// Get which forms are already locked
			this.setInitialFormLocks();
		};

		// If the connection to the server is ready, run the above callback
		if (this.getIsHubConnected) {
			setForms();
		} else {
			// Otherwise, add it to a queue to invoke when it becomes ready
			this.connectionQueue.push(setForms);
		}
	}

	@action
	private registerForms() {
		// Register for notifications for all form types in list
		this.registeredForms.forEach(form => {
			this.disposers.push(this.registerFormLocks(form));
		});
	}

	@computed
	public get getIsHubConnected() {
		return this.signalRConcurrencyHubConnection.state === HubConnectionState.Connected;
	}

	@computed
	private get getResetFormLocks() {
		// The observable dictionary for tracking locked form types
		return observable<{ [ref: string]: { locked: boolean; lockedBy: string; id: string } }>({});
	}

	@action
	private onLock = (lockForm: LockForm) => {
		// Handle message from server to lock a form type
		this.formLocks[lockForm.formType] = { locked: true, lockedBy: lockForm.lockedByName, id: lockForm.id };
	};

	@action
	private onUnlock = (lockForm: LockForm) => {
		// Handle message from server to unlock a form type
		this.formLocks[lockForm.formType] = { ...this.formLocks[lockForm.formType], locked: false };
	};

	@computed
	public get getFormRef() {
		return this.formRef;
	}

	// Try to lock the form if not already, returning a promise which will resolve to a boolean indicating if this client now has the form type locked
	@action
	public async lockForm(formType: number) {
		let success = await this.signalRConcurrencyHubConnection.invoke<boolean>(
			`TryLockFormForEditing`,
			formType,
			this.formRef,
		);
		if (success) {
			// Wait for notification from the server to be added to the formLocks dictionary for verification
			await when(
				() =>
					this.formLocks[formType] &&
					this.formLocks[formType].locked &&
					this.formLocks[formType].lockedBy === this.domainStores!.AccountStore.UserName,
			);
			// Add it to the list of forms locked by this client
			this.myLockedForms.push(formType);
			return true;
		}
		return false;
	}

	@action
	public async forceUnlockForm(formType: number, formRef: string) {
		await this.signalRConcurrencyHubConnection.invoke(`ForceUnlockForm`, formType, formRef);
	}

	@action
	public unlockAllForms() {
		this.myLockedForms.forEach(form => {
			this.unlockForm(form);
		});
	}

	// Unlock a form type
	@action
	public unlockForm(formType: number) {
		this.signalRConcurrencyHubConnection.send(`UnlockForm`, this.formLocks[formType].id);
		// Remove from list of forms locked by this client
		this.myLockedForms.splice(this.myLockedForms.indexOf(formType), 1);
	}

	public getLocker(formType: number) {
		let form = this.formLocks[formType];
		return form ? form.lockedBy : "";
	}

	public getIsFormLocked(formType: number) {
		let form = this.formLocks[formType];
		// Don't include if the form is locked by this client
		return form ? form.locked && form.lockedBy !== this.domainStores!.AccountStore.DisplayName : false;
	}

	// Register for notifications for a form type
	private registerFormLocks = (formType: number): Disposer => {
		this.signalRConcurrencyHubConnection.send(`JoinFormLockGroup`, formType, this.formRef).catch(error => {
			console.error(`Failed to "JoinFormLockGroup": ${error}`);
		});

		// Return a disposer to unregister from the notifications
		return {
			dispose: () => {
				if (this.signalRConcurrencyHubConnection !== null && this.signalRConcurrencyHubConnection !== undefined) {
					this.signalRConcurrencyHubConnection.send(`LeaveFormLockGroup`, formType, this.formRef).catch(error => {
						console.error(`Failed to "LeaveFormLockGroup": ${error}`);
					});
				}
			},
		};
	};

	// Connect to the server
	@action
	private connect = () => {
		// Make sure client isn't already connected
		if (this.signalRConcurrencyHubConnection.state === HubConnectionState.Disconnected) {
			this.signalRConcurrencyHubConnection.start().then(() => {
				// Connection established
				// Track the connection id of this connection
				this.cid = this.signalRConcurrencyHubConnection.connectionId!;

				this.signalRConcurrencyHubConnection.invoke<string>(`GetServerSessionId`).then((serverSessionId: string) => {
					runInAction(() => {
						this.connectedServer = serverSessionId;
					});
				});
				// Add handler for if the server goes down and needs to reconnect
				this.signalRConcurrencyHubConnection.onreconnected(() => {
					// Register for notifications on the forms on new connection
					this.registerForms();
					// Send a reconnection message to handle which forms are locked by this client
					this.signalRConcurrencyHubConnection.send(`Reconnected`, this.cid, this.myLockedForms, this.connectedServer);
					// Track the connection id of this new connection
					this.cid = this.signalRConcurrencyHubConnection.connectionId!;
					// Get which forms are already locked
					this.setInitialFormLocks();
				});
				this.signalRConcurrencyHubConnection.onreconnecting(() => {
					let intervalId = window.setInterval(() => {
						axios.post(`/api/account/GetSignalRToken`).then((apiResult: AxiosResponse<ApiResult<string>>) => {
							if (apiResult.status === 200 && apiResult.data && apiResult.data.wasSuccessful) {
								window.clearInterval(intervalId);
								this.setSignalRAccessToken(apiResult.data.payload);
							}
						});
					}, 1000);
				});
				// Register handlers for lock & unlock notifications
				this.signalRConcurrencyHubConnection.on(`LockForm`, this.onLock);
				this.signalRConcurrencyHubConnection.on(`UnlockForm`, this.onUnlock);
				// Invoke any callbacks in the connection queue
				this.connectionQueue.forEach(cb => {
					runInAction(() => {
						cb();
					});
				});
			});
		}
	};

	// Disconnect from the server
	@action
	private disconnect = () => {
		// Make sure client isn't already disconnected
		if (this.signalRConcurrencyHubConnection.state === HubConnectionState.Connected) {
			// Unregister handlers for lock & unlock notifications
			this.signalRConcurrencyHubConnection.off(`LockForm`, this.onLock);
			this.signalRConcurrencyHubConnection.off(`UnlockForm`, this.onUnlock);
			// Stop the connection
			this.signalRConcurrencyHubConnection.stop();
		}
	};
}
