export class RequestError extends Error {
	public readonly errorMessages: string[];

	constructor(errorMessages: string[]) {
		//AJ: https://stackoverflow.com/questions/47941324/inherited-exceptions-and-instanceof
		const targetPrototype = new.target.prototype;
		super("The request had errors.");
		Object.setPrototypeOf(this, targetPrototype);

		this.errorMessages = errorMessages;
	}
}
