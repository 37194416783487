import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class EnclosureSizeModel extends ModelBase<EnclosureSizeModel, EnclosureSizeModelDTO> {
	@MobX.observable
	@IsNotEmpty({ message: "Enclosure SqFt is required" })
	@IsInt({ message: "Enclosure SqFt must be a valid number" })
	public enclosureSqft: number | undefined = undefined;

	public fromDto = (model: EnclosureSizeModelDTO) => {};
	public toDto = (model: EnclosureSizeModel) => {};
}

export interface EnclosureSizeModelDTO {
	enclosureSqft: number | undefined;
}
