import { action, computed } from "mobx";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class LoginViewModel extends ViewModelBase<LoginModel> {
	constructor() {
		super(new LoginModel());
		//EN - Havent figured out how to make this call work from the base model yet
		//This is only needed if you make use of the validation decorators
		this.setDecorators(LoginModel);
	}

	public login = async (): Promise<ApiResult<AccountStatus>> => {
		let apiResult = await this.Post<AccountStatus>("/api/account/login", this.model);
		return apiResult;
	};

	public isFieldValid(fieldName: "emailAddress" | "password"): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}

	@computed
	public get isEmailAddressValid(): string {
		let retval = "";
		if (isEmptyOrWhitespace(this.model.emailAddress)) return "Email address is required";
		if (this.model.emailAddress.length < 4) return "Email address must be greater than 4 characters";

		return retval;
	}

	@computed
	public get isPasswordValid(): string {
		let retval = "";
		if (isEmptyOrWhitespace(this.model.password)) return "Password is required";
		if (this.model.password.length < 4) return "Password must be greater than 4 characters";

		return retval;
	}
}
