import { Box, Tab } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import { useAdminStyles, TabsCustom } from "../../../Shoothill.Components/StylesAppSpecific/AdminStyling";
import { AdminHome } from "./AdminHome";
import { AppHeader } from "Core/Components/Styles/Styles";
import { UsersList } from "./UsersList";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</Typography>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export const Admin: React.FC = () => {
	const classes = useAdminStyles();
	const [value, setValue] = React.useState(0);

	function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
		setValue(newValue);
	}

	return useObserver(() => (
		<>
			<div>
				<AppHeader className={classes.adminHeader}>
					<div className={classes.tabsContainer}>
						<TabsCustom
							value={value}
							onChange={handleChange}
							aria-label="simple tabs example"
							className={classes.tabsWidth}
						>
							<Tab label="Dashboard" {...a11yProps(0)} />
							<Tab label="Manage users" {...a11yProps(1)} />
						</TabsCustom>
					</div>
				</AppHeader>
			</div>

			<TabPanel value={value} index={0}>
				<AdminHome />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<UsersList />
			</TabPanel>
		</>
	));
};
