import { QuoteRequest } from "./../../Models/QuoteRequest";
import { Stores } from "./../Stores";
import { BaseStore } from "./BaseStore";
import { observable, action } from "mobx";

export class ProcessFlowStore extends BaseStore {
	@observable public pageFlowSource: string = "";
	@observable public ExperiencedFloodDamage: boolean | undefined;
	@observable public DateOfLoss: string = "";
	@observable public StormName: string = "";
	@observable public AmmountOfLoss: string = "";
	@observable public PropertyType: string = "";
	@observable public ConstructionType: string = "";
	@observable public PrimaryResidence: boolean | undefined;
	@observable public ResidenceUsage: string = "";
	@observable public FoundationType: string = "";
	@observable public EnclosedPiers: string = "";
	@observable public EncBaseSize: number = 0;
	@observable public Features: string[] = [];
	@observable public BusinessType: string = "";

	@observable public propertyTypeChoices: string[] = [];

	@observable public sufferedFloodDamage: boolean | undefined;

	public constructor() {
		super();
	}

	// tslint:disable-next-line: no-empty
	public init(stores: Stores): void {}

	@action
	public SetFloodDamage(aVal: boolean) {
		this.ExperiencedFloodDamage = aVal;
	}

	@action
	public SetDateLoss(aVal: string) {
		this.DateOfLoss = aVal;
	}

	@action
	public SetStormName(aVal: string) {
		this.StormName = aVal;
	}

	@action
	public SetAmountOfLoss(aVal: string) {
		this.AmmountOfLoss = aVal;
	}

	@action
	public SetPropertyType(aVal: string) {
		this.PropertyType = aVal;
	}

	@action
	public SetConstructionType(aVal: string) {
		this.ConstructionType = aVal;
	}

	@action
	public SetPrimaryResidence(aVal: boolean) {
		this.PrimaryResidence = aVal;
	}

	@action
	public SetResidenceUsage(aVal: string) {
		this.ResidenceUsage = aVal;
	}

	@action
	public SetFoundationType(aVal: string) {
		this.FoundationType = aVal;
	}

	@action
	public SetEnclosedPiers(aVal: string) {
		this.EnclosedPiers = aVal;
	}

	@action
	public SetFeatures(aVal: string[]) {
		this.Features = aVal;
	}

	@action
	public SetEncBaseSize(aVal: number) {
		this.EncBaseSize = aVal;
	}

	@action
	public SetBusinessType(aVal: string) {
		this.BusinessType = aVal;
	}

	@action
	public ResetQuote(): void {
		this.ExperiencedFloodDamage = undefined;
		this.DateOfLoss = "";
		this.StormName = "";
		this.AmmountOfLoss = "";
		this.PropertyType = "";
		this.ConstructionType = "";
		this.PrimaryResidence = undefined;
		this.ResidenceUsage = "";
		this.FoundationType = "";
		this.EnclosedPiers = "";
		this.Features = [];
		this.BusinessType = "";
	}

	@action
	public SetPageSource(page: string): void {
		this.pageFlowSource = page;
	}

	@action
	public ResetPageSource(): void {
		this.pageFlowSource = "";
	}

	/*     @action SetFloodDamage(flooded: boolean): void {
        this.sufferedFloodDamage = flooded;
    } */

	@action ResetFloodDamage(): void {
		this.sufferedFloodDamage = undefined;
	}
}
