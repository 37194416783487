import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "Core/Utils";

import { Link } from "@material-ui/core";
import {
	AppHeaderStart,
	BackLink,
	HomeMain,
	MainRoot,
	GridSpaced,
	SterlingMainBox,
} from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { QuoteHeadingBox, QuoteLink, QuoteBtn } from "Shoothill.Components/StylesAppSpecific/QuoteStyling";
import iconYourQuotes4TitleWhite from "Content/icon-yourQuotes4Title-White.svg";

import { useMediaQuery } from "Core/Utils/Media/mediaQuery";

import { StoresInstance /* , StoresContext, Stores  */ } from "Custom/Stores";
/* import { MapStore } from "Custom/Stores/Domain/MapStore";

import { QuoteResponseViewModel } from "Custom/ViewModels"; */
import { EditQuoteForm } from "Custom/Components/Quotes/EditQuoteForm";
import { useObserver } from "mobx-react-lite";

export const EditQuote: React.FC = () => {
	//const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
	const quoteIdRef = useRef("");
	const domainStores = StoresInstance.domain;

	const [sliderAdjust, setSliderAdjust] = useState<number>(1000);
	const { history, location, match } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	useEffect(() => {
		(async () => {
			let { quoteId } = match.params as any;
			quoteIdRef.current = quoteId;
			if (quoteId) {
				await domainStores.QuoteRequestViewModel.getExistingQuote(quoteId);
				setSliderAdjust(0);
			}
		})();
	}, []);

	const sliderAction = (newValue: number) => {
		setSliderAdjust(newValue);
	};

	function resetQuote(): void {
		domainStores.QuoteRequestViewModel.resetModel();
		history.push("/editquote");
	}

	function recalculateQuote(): void {
		let enclosureType: any;
		domainStores.QuoteRequestViewModel.setValue("createdDate", new Date().toISOString());
		enclosureType = domainStores.QuoteRequestViewModel.getValue("enclosureType");
		domainStores.QuoteRequestViewModel.setValue("enclosureType", enclosureType.toString());

		//domainStores.QuoteRequestViewModel.GetQuotesAsync().then(() => {
		history.push("/yourquote");
		//});
	}

	const titleMarkup = (
		<>
			<QuoteHeadingBox>
				<div className="edit-quote-icon-container">
					<img src={iconYourQuotes4TitleWhite} alt={"Sterling"} />
				</div>
				<h1>Quote info</h1>
			</QuoteHeadingBox>
		</>
	);

	const recalculateQuoteMarkup = (
		<>
			<QuoteBtn
				backgroundcolor={"#1AB7EA"}
				fontcolor={"#FFFFFF"}
				fontsize={"14px"}
				padding={"7px 50px"}
				onClick={recalculateQuote}
			>
				Recalculate
			</QuoteBtn>
		</>
	);

	const resetQuoteMarkup = (
		<>
			<QuoteBtn
				backgroundcolor={"#000000"}
				fontcolor={"#FFFFFF"}
				fontsize={"14px"}
				padding={"7px 50px"}
				onClick={resetQuote}
			>
				Reset changes
			</QuoteBtn>
		</>
	);

	return useObserver(() => (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart additionalcss={"height: unset; background-color: #ffffff; color: black;"}>
						<GridSpaced container additionalcss={"height: unset;"} justify="flex-start">
							<GridSpaced item xs={12}>
								<GridSpaced
									container
									padding={"25px"}
									additionalcss={"background-color: #CBDB2A; height: 99px; padding-left: 50px;"}
								>
									<GridSpaced item xs={12} md={6} additionalcss={"color: white; text-align: left;"}>
										{titleMarkup}
									</GridSpaced>
									<GridSpaced item xs={12} md={3} additionalcss={"text-align: right;"}>
										{recalculateQuoteMarkup}
									</GridSpaced>
									<GridSpaced item xs={12} md={3} additionalcss={"text-align: center;"}>
										{resetQuoteMarkup}
									</GridSpaced>
								</GridSpaced>

								<GridSpaced
									container
									justify="center"
									alignItems="center"
									margin={"10px auto"}
									additionalcss={"max-width: 1090px; color: #ffffff;"}
								>
									<EditQuoteForm />
								</GridSpaced>
								<GridSpaced
									container
									justify="center"
									direction={"row"}
									alignItems="center"
									margin="0px 0px 20px"
									additionalcss={"text-align:center;"}
								>
									<GridSpaced item xs={12} md={3} additionalcss={"text-align: right;"}>
										{recalculateQuoteMarkup}
									</GridSpaced>
									<GridSpaced item xs={12} md={3} additionalcss={"text-align: center;"}>
										{resetQuoteMarkup}
									</GridSpaced>
								</GridSpaced>
								<GridSpaced container justify="center" alignItems="center" additionalcss={"text-align:center;"}>
									<GridSpaced
										item
										xs={12}
										md={4}
										margin="20px"
										additionalcss="max-width: 330px; width; 100%;"
									></GridSpaced>
									<GridSpaced
										item
										xs={12}
										md={4}
										margin="20px"
										additionalcss="max-width: 350px; width; 100%;"
									></GridSpaced>
									<GridSpaced
										item
										xs={12}
										md={4}
										margin="20px"
										additionalcss="max-width: 330px; width; 100%;"
									></GridSpaced>
								</GridSpaced>
							</GridSpaced>
						</GridSpaced>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	));
};
