import { TextField, InputLabel, Button, Slider, Select } from "@material-ui/core";
import { EditableInput } from "Core/Components/EditableInput";
import { GridSpaced } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import styled from "styled-components";
import { Component } from "react";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
	textAlign?: string;
	border?: string;
	borderLeft?: string;
	borderRight?: string;
	width?: string;
}

export const SterlingForm: any = styled.form<IComponentProps>`
	.policy-effective,
	.loss-coverage,
	.contents-coverage,
	.dwelling-coverage,
	.year-built,
	.replacement-cost,
	.total-sq-ft,
	.number-of-floors,
	.customer-name {
		input {
			min-width: auto;
			height: 30px;
			font-size: 12px;
		}

		width: 100%;
	}

	.floodzone-select {
		> div {
			display: flex;
		}

		.MuiSelect-root {
			height: 30px !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}

	.elevator {
		.MuiIconButton-label {
			> div {
				width: 30px !important;
				height: 30px !important;
				svg:last-child {
					width: 54px !important;
					height: 54px !important;
				}
			}
		}

		label {
			min-width: auto;
			margin: 0;
		}

		.MuiFormControlLabel-label {
			font-size: 12px;
		}

		.MuiFormControlLabel-root {
			height: 30px;
		}
	}

	input,
	label,
	.MuiSelect-select {
		min-width: 156px;
		height: 45px;
		vertical-align: middle;
	}

	input,
	.adminClasses.datePickerBox {
		width: 148px;
		padding: 0 0px 0px 10px;
		margin: 0;
		background-color: #ffffff;
	}

	input,
	.MuiSelect-select {
		border: 1px solid #1ab7ea;
		font-size: 18px;
		width: 100%;
		background: #ffffff;
	}

	.MuiSelect-select {
		height: 32px !important;
		padding-left: 10px !important;
		display: flex !important;
		align-items: center !important;
		/* min-width: 132px !important; */
	}

	.floodzone-select {
		.MuiFormControl-root {
			min-width: auto;
			width: 100%;
		}
		.MuiInput-formControl {
			margin-top: 0px;
		}
		div {
			min-width: auto;
		}
	}

	.submit.panelIcon {
		img {
			max-width: 107px;
			height: auto;
		}
	}

	.datePicker {
		button {
			width: 45px;
			border-radius: 0px;
			padding: 5px;
			margin-right: 0px;
		}

		input,
		.MuiInputBase-root {
			width: 100%;
			/* max-width: 170px; */
			/*border: 1px solid #1ab7ea;*/
		}

		span.MuiIcon-root {
			img {
				width: 20px;
				height: 20px;
				padding: 0px;
				margin-bottom: 2px;
			}
		}
	}

	.error {
		color: red;
		font-size: 14px !important;
	}

	span.MuiRadio-root {
		color: #1ab7ea;
		.MuiIconButton-label {
			> div {
				background-color: #ffffff;
				border-radius: 50%;
				border: 1px solid #1ab7ea;
				width: 45px;
				height: 45px;
				svg:first-child {
					fill: #ffffff;
					display: none;
				}
				svg:last-child {
					fill: #000000;
					display: none;
				}
			}
		}
	}

	span.MuiRadio-root.Mui-checked {
		.MuiIconButton-label {
			> div {
				svg:first-child {
					fill: #ffffff;
					display: none;
				}
				svg:last-child {
					fill: #000000;
					width: 69px;
					height: 69px;
					margin-left: -13px;
					margin-top: -13px;
					display: block;
				}
			}
		}
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after {
		border-bottom: 0px;
		transition: unset;
	}

	.MuiInput-root {
		position: relative;

		div.MuiInputAdornment-root {
			position: absolute;
			right: 0px;
		}

		div.MuiInputAdornment-positionStart {
			left: 0;
		}

		div.dollarSign {
			font-weight: bold;
			color: #464545;
			margin: 0 7px;
			pointer-events: none;
			font-size: 18px;
		}

		input.MuiInputBase-inputAdornedStart {
			padding-left: 25px;
		}

		svg.MuiSvgIcon-root {
			width: 26px;
			height: 26px;
		}
	}

	.MuiInput-root:hover {
		border-bottom: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: none;
	}

	.MuiSlider-root {
		:before {
			height: 6px;
			width: 6px;
			background-color: #ffffff;
			content: "";
			position: absolute;
			left: -4px;
			top: 9px;
			border-radius: 50%;
		}

		:after {
			height: 6px;
			width: 6px;
			background-color: #ffffff;
			content: "";
			position: absolute;
			top: 9px;
			border-radius: 50%;
			right: -4px;
			z-index: 1;
		}

		.MuiSlider-rail,
		.MuiSlider-track {
			background: #ffffff;
			opacity: 1;
		}

		.MuiSlider-thumb {
			z-index: 2;
		}
	}

	.commercial-select-parent {
		.MuiFormControl-root {
			width: 100%;
			max-width: 554px;
		}
	}
`;

export const AdjustBtn: any = styled(Button)<IComponentProps>`
	height: 32px;
	width: 32px !important;
	min-width: 32px;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	background-color: #ffffff;
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	:hover {
		background-color: #ffffff;
	}
	border: ${props => (props.border !== undefined ? props.border : "none")};
	border-left: ${props => (props.borderLeft !== undefined ? props.borderLeft : "none")};
	border-right: ${props => (props.borderRight !== undefined ? props.borderRight : "none")};
`;

export const AltSelect: any = styled(Select)<IComponentProps>`
	background-color: #fff;
	border: solid 1px rgb(206, 212, 218);
	padding-left: 10px;
	:hover {
		border: solid 1px rgb(206, 212, 218);
	}
	font-size: ${props => props.fontsize};
	${props => props.additionalcss}
`;

export const SterlingText: any = styled(EditableInput)<IComponentProps>`
	/*background-color: #ffffff;*/
	width: 336px;
	${props => props.additionalcss}
`;

export const SterlingSmallText: any = styled(EditableInput)<IComponentProps>`
	width: 74px;
	overflow: hidden;
	padding: 0px;
	input {
		width: 100%;
		min-width: 45px;
		text-align: center;
		padding: 0px;
	}
	${props => props.additionalcss};
`;

export const SterlingLabel: any = styled(InputLabel)<IComponentProps>`
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
	text-align: ${props => (props.textAlign !== undefined ? props.textAlign : "left")};
	font-size: ${props => (props.fontsize !== undefined ? props.fontsize : "18px")};
	padding: ${props => (props.padding !== undefined ? props.padding : "11px 10px")};
	${props => props.additionalcss};
	letter-spacing: -0.4px;
`;

export const SubmitBtn: any = styled(Button)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
	border-radius: 5px;
	background-color: #1ab7ea;
	${props => props.additionalcss}
`;

export const NextBtn: any = styled(Button)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	margin: ${props => (props.margin !== undefined ? props.margin : "0")};
	color: ${props => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
	${props => props.additionalcss};
	max-width: 107px;
	width: 100%;
	div {
		text-align: center;
		padding-right: 15px;
		font-size: 24px;
		padding-top: 15px;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (max-width: 767px) {
		div {
			padding-right: 0;
			font-size: 12px;
			margin-top: 25px;
			img {
				width: 64px;
				height: auto;
			}
		}
	}
`;

export const QuoteSlider: any = styled(Slider)<IComponentProps>`
	.MuiSlider-root {
		padding: 6px 0;
	}

	.MuiSlider-track,
	.MuiSlider-rail,
	.MuiSlider-trackFalse {
		background-color: #ffffff;
	}

	.MuiSlider-thumb {
		background-color: #1ab7ea;
	}

	.MuiSlider-thumb.Mui-focusVisible,
	.MuiSlider-thumb:hover {
		box-shadow: unset;
	}
`;

export const InputContainer: any = styled(GridSpaced)<IComponentProps>`
	text-align: left;
`;

export const InputParent: any = styled(GridSpaced)<IComponentProps>``;
