import {
	AppHeaderStart,
	BackLink,
	GridSpaced,
	H1TitleAndImg,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";

import { Choices } from "Custom/Components/PropertyTypes/Choices";
import React from "react";
import { StoresInstance } from "../Stores";
import { iconStartGraphic } from "Content/EmbeddedSVGs";
import { useMediaQuery } from "../../Core/Utils/Media";
import { useRouter } from "../../Core/Utils";

export const PropertyType: React.FC = () => {
	const domainStores = StoresInstance.domain;
	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;
	const { history } = useRouter();

	function onClickBack(): void {
		let priorLoss: string = domainStores.QuoteRequestViewModel.getValue("priorLoss") as string;
		let historyVal: string = "/flooddamage";
		if (priorLoss === "1") {
			historyVal = "/flooddamagedetail";
		}
		history.push(historyVal);
	}

	const handleChoice = (choiceVal: string) => {
		switch (choiceVal) {
			case "residential":
				history.push("/contructiontype");
				break;

			case "complex":
				history.push("/contructiontype");
				break;

			case "commercial":
				history.push("/commercial");
				break;

			default:
				history.push("/");
		}
	};

	const headingTitle = (
		<>
			<SterlingMainBox>
				<div>{iconStartGraphic}</div>
				<h1>What type of property is this?</h1>
			</SterlingMainBox>
		</>
	);

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<GridSpaced container className="gridContainer">
							{!isMobile && (
								<>
									<BackLink
										title={"Go Back"}
										onClick={onClickBack}
										/* padding={ "14px 0px 0px 30px" } */
										additionalcss={"height: 0;"}
									>
										Back
									</BackLink>
								</>
							)}
							<GridSpaced item xs={12}>
								<GridSpaced container direction="row" justify="center" alignItems="center">
									<GridSpaced item xs={12} md={6}>
										{headingTitle}
									</GridSpaced>
								</GridSpaced>
								<GridSpaced container direction="row" justify="center" alignItems="flex-start">
									<GridSpaced item xs={12} md={12}>
										<Choices propertyChoice={handleChoice} />
									</GridSpaced>
								</GridSpaced>
							</GridSpaced>
							{isMobile && (
								<>
									<BackLink title={"Go Back"} onClick={onClickBack} margin={"0px auto"} padding={"0px"}>
										Back
									</BackLink>
								</>
							)}
						</GridSpaced>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
