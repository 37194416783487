import { sortByString } from "../../Core/Utils/Utils";
import { ApiResult } from "Core/Models/ApiResult";
import { action, computed, runInAction } from "mobx";
import { GenericIncludeDeleted, LoginModel } from "../Models";
import * as MobX from "mobx";
import { User, UserDTO } from "Custom/Models/User";
import { StoresInstance } from "Custom/Stores/Stores";
import { UserStore } from "Custom/Stores/Domain";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export default class UserViewModel extends ViewModelBase<User> {
	@MobX.observable public userCount: number = 0;
	@MobX.observable private ascOrder = true;
	private userStore: UserStore = StoresInstance.domain.UserStore;
	@MobX.observable public Valid: boolean = false;

	constructor() {
		super(new User(""));
		this.setDecorators(User);
	}

	public setUser(user: User, newUser: boolean) {
		this.model.setValue("id", newUser ? "" : user.id);
		this.model.setValue("firstName", newUser ? "" : user.firstName);
		this.model.setValue("lastName", newUser ? "" : user.lastName);
		this.model.setValue("email", newUser ? "" : user.email);
		this.model.setValue("password", newUser ? "" : user.password);
	}

	public get(fieldName: string) {
		return this.model.getValue(fieldName);
	}

	@action
	public set(fieldName: string, value: string | number | boolean | Date) {
		this.model.setValue(fieldName, value);
	}

	public isFieldValid(fieldName: keyof User & string, value: any): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}

	@action
	public async loadUsersAsync(): Promise<ApiResult<UserDTO[]>> {
		const includeGenericDeleted: GenericIncludeDeleted = {
			includeDeleted: true,
		};
		var apiResult = await this.Post<UserDTO[]>("/api/user/getall", includeGenericDeleted);

		if (apiResult.wasSuccessful) {
			this.userStore.setUsers(apiResult.payload);
			MobX.runInAction(() => (this.userCount = this.userStore.getUserCount));
		}
		return apiResult;
	}

	@computed get getUsers(): User[] {
		let users = this.userStore.getUsers.slice();
		users.sort((a: UserDTO, b: UserDTO) => {
			if (this.ascOrder) return sortByString(a.firstName, b.firstName);
			else return sortByString(b.firstName, a.firstName);
		});
		return users;
	}

	public getUser = (id: string) => {
		if (id) {
			return this.userStore.getUsers.find(u => u.id === id);
		} else {
			return new User("");
		}
	};

	@computed get getUserCount(): number {
		return this.userCount;
	}

	@computed get getIsLoadingData(): boolean {
		return this.userStore.getIsLoadingData;
	}

	@action
	public setOrderAsc() {
		this.ascOrder = !this.ascOrder;
	}

	@computed get getOrderAsc(): boolean {
		return this.ascOrder;
	}

	public async postUserDetailAsync() {
		var apiResult = await this.Post<any>(`/api/user/Upsert`, this.model);
		if (apiResult.wasSuccessful) {
			let existingUser = this.userStore.getUsers.find(u => u.id === this.model.id);
			if (existingUser) {
				const index = this.userStore.getUsers.indexOf(existingUser, 0);
				if (index > -1) {
					this.userStore.getUsers.splice(index, 1);
				}
			}
			this.userStore.getUsers.push(apiResult.payload);
		}
		return apiResult.payload;
	}
}
