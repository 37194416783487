import React, { useContext, useState } from "react";
import { useRouter } from "../../Core/Utils";

import { AppHeaderStart, BackLink, HomeMain, MainRoot } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Link, Grid } from "@material-ui/core";

import { useMediaQuery } from "Core/Utils/Media/mediaQuery";

import { BasementEnclosureSize } from "Custom/Components/PropertyTypes/BasementEnclosureSize";
import { StoresContext, Stores } from "../Stores";
import { ProcessFlowStore } from "../Stores/Domain/ProcessFlowStore";

export const PropertyBaseEncSize: React.FC = () => {
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const nextStep = (callbackVal: boolean) => {
		history.push("/getquote");
	};

	function prevStep(): void {
		history.goBack();
	}

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<Grid container className="gridContainer">
							<Grid item xs={12}>
								{!isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
								<BasementEnclosureSize
									basencSize={nextStep}
									propertyTitlePart={ProcessFlowStoresContext.PropertyType}
								/>
								{isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
							</Grid>
						</Grid>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
