import { Address } from "./Custom/Views/Address";
import { CommercialProperty } from "./Custom/Views/CommercialProperty";
import { EditQuote } from "./Custom/Views/EditQuote";
import { FloodDamage } from "./Custom/Views/FloodDamage";
import { FloodDamageDetail } from "./Custom/Views/FloodDamageDetail";
import { GetQuote } from "./Custom/Views/GetQuote";
import { Home } from "./Custom/Views/Home";
import { LoginPage } from "./Custom/Views/LoginPage";
import { PrivateRoute } from "Core/Utils";
import { PolicySelected } from "./Custom/Views/PolicySelected";
import { PropertyConstruction } from "./Custom/Views/PropertyConstruction";
import { PropertyBasementType } from "./Custom/Views/PropertyBasementType";
import { PropertyBaseEncSize } from "./Custom/Views/PropertyBaseEncSize";
import { PropertyEnclosedPiers } from "./Custom/Views/PropertyEnclosedPiers";
import { PropertyFoundationType } from "./Custom/Views/PropertyFoundationType";
import { PropertyPrimaryResidence } from "./Custom/Views/PropertyPrimaryResidence";
import { PropertyResidenceUsage } from "./Custom/Views/PropertyResidenceUsage";
import { PropertyType } from "./Custom/Views/PropertyType";
import React, { useContext } from "react";
import { RegisterPage } from "./Custom/Views/RegisterPage";
import { Route } from "react-router";
import { StoresContext, Stores } from "./Custom/Stores";
import { Admin } from "./Custom/Views/Admin/Admin";
import { User } from "./Custom/Views/User";
import { UserDetails } from "./Custom/Views/Admin/UserDetails";
import { YourQuote } from "./Custom/Views/YourQuote";

export const AppRoutes: React.FC = () => {
	const store = useContext<Stores>(StoresContext);
	return (
		<>
			<Route exact path="/" component={Home} />
			<Route exact path="/address" component={Address} />
			<Route exact path="/basementenclosure" component={PropertyBasementType} />
			<Route exact path="/basementenclosuresize" component={PropertyBaseEncSize} />
			<Route exact path="/commercial" component={CommercialProperty} />
			<Route exact path="/contructiontype" component={PropertyConstruction} />
			<Route exact path="/editquote/:quoteId?" component={EditQuote} />
			<Route exact path="/enclosedpiers" component={PropertyEnclosedPiers} />
			<Route exact path="/flooddamage" component={FloodDamage} />
			<Route exact path="/flooddamagedetail" component={FloodDamageDetail} />
			<Route exact path="/foundationtype" component={PropertyFoundationType} />
			<Route exact path="/getquote" component={GetQuote} />
			<Route exact path="/policyselected" component={PolicySelected} />
			<Route exact path="/primaryresidence" component={PropertyPrimaryResidence} />
			<Route exact path="/propertytype" component={PropertyType} />
			<Route exact path="/residenceusage" component={PropertyResidenceUsage} />
			<Route exact path="/yourquote" component={YourQuote} />
			<Route exact path="/login" component={LoginPage} />
			<Route exact path="/register" component={RegisterPage} />
			<PrivateRoute
				exact
				path="/user"
				component={User}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
				}}
			/>
			<PrivateRoute
				exact
				path="/admin"
				component={Admin}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
				}}
			/>
			<PrivateRoute
				exact
				path="/userslist/user/:id"
				component={UserDetails}
				isAllowed={() => {
					return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
				}}
			/>
		</>
	);
};
