import "./Home.css";

import {
	AppHeaderStart,
	GridSpaced,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Box, Grid } from "@material-ui/core";
import { Dot, DotWrapper } from "Core/Components/Styles/Styles";

import { AddressSelectorGoogle } from "../Components/AddressSelectorGoogle";
import React from "react";
import { iconStartGraphic } from "Content/EmbeddedSVGs";
import { useObserver } from "mobx-react-lite";

export const Home: React.FC = () => {
	return useObserver(() => (
		<MainRoot>
			<HomeMain role="main">
				<AppHeaderStart>
					<GridSpaced container className="gridContainer">
						<GridSpaced item xs={12} additionalcss={"padding-top: 57px;"}>
							<SterlingMainBox>
								<div>{iconStartGraphic}</div>
								<h1>An amazing flood quote in one painless minute!</h1>
								<div className="AddressSelector">
									<AddressSelectorGoogle gotoNext={true} />
								</div>
							</SterlingMainBox>
						</GridSpaced>
					</GridSpaced>
				</AppHeaderStart>
			</HomeMain>
		</MainRoot>
	));
};

export const Loaders: React.FC = () => {
	return (
		<>
			<Box paddingTop={2}>
				Animated Dots
				<DotWrapper>
					<Dot delay="0s" />
					<Dot delay=".1s" />
					<Dot delay=".2s" />
				</DotWrapper>
			</Box>
		</>
	);
};
