import { QuoteModel } from "Custom/Models/QuoteModel";
import React from "react";
import Fade from "@material-ui/core/Fade";

import { QuoteCard } from "Shoothill.Components/StylesAppSpecific/QuoteStyling";

import iconTinyEmail from "Content/icon-tinyEmail.svg";
import iconTinyPrint from "Content/icon-tinyPrint.svg";
import { useRouter } from "Core/Utils";

import { StoresInstance } from "Custom/Stores";

interface IProps {
	styleType: string;
	titleText: string;
	quoteModel: QuoteModel | undefined;
}
export const YourQuotes: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;

	let styleOption: string = " " + props.styleType;
	const { history } = useRouter();

	const heading = <>{props.titleText}</>;

	if (props.quoteModel !== undefined) {
		console.log(props.quoteModel);
	}

	function roundToTwo(num: number) {
		return +(Math.round(num * 100 + getEpsilon()) / 100);
	}

	function getEpsilon() {
		let e = 1.0;
		while (1.0 + 0.5 * e !== 1.0) e *= 0.5;
		return e;
	}

	function handleClickPrint(): void {
		//alert("Print");
	}

	function handleEmailPrint(): void {
		//alert("Email");
	}

	const handleQuote = (chosenQuote: QuoteModel) => {
		domainStores.QuoteStore.setChosenQuote(chosenQuote);
		history.push("/policyselected");
	};

	return (
		<>
			{props.quoteModel !== undefined && (
				<>
					<Fade in={true}>
						<QuoteCard
							id={"quoteCard"}
							container
							alignItems="start"
							direction={"column"}
							padding="0"
							margin="0"
							additionalcss="display:block!important; border: 1px solid #eaeaea; border-radius: 5px;"
						>
							<QuoteCard item xs={12} onClick={() => handleQuote(props.quoteModel!)}>
								<div className={"header" + styleOption}>
									<h2>{heading}</h2>
								</div>
							</QuoteCard>
							<QuoteCard item xs={12} onClick={() => handleQuote(props.quoteModel!)}>
								<div className={"secondary" + styleOption}>
									<h4>Building coverage:</h4>
									<h3>${props.quoteModel!.coverageBuilding}</h3>
								</div>
							</QuoteCard>
							<QuoteCard item xs={12} onClick={() => handleQuote(props.quoteModel!)}>
								<div className={"grouped" + styleOption}>
									<QuoteCard container direction={"row"} justify="center" alignItems="center">
										<QuoteCard item xs={6} className={"" + styleOption}>
											<h4>Contents coverage:</h4>
											<h3>${props.quoteModel!.coverageContents}</h3>
										</QuoteCard>
										<QuoteCard item xs={6} className={"" + styleOption}>
											<h4>Loss of use coverage:</h4>
											<h3>${props.quoteModel!.coverageLossOfUse}</h3>
										</QuoteCard>
									</QuoteCard>
								</div>
							</QuoteCard>
							<QuoteCard item xs={12} onClick={() => handleQuote(props.quoteModel!)}>
								<div className={"premiumResult"}>
									<h3>Annual premium:</h3>
									<h2>${roundToTwo(parseFloat(props.quoteModel!.premiumTotal))}</h2>
								</div>
							</QuoteCard>
							{/*
							<QuoteCard item xs={12} additionalcss="border-top:1px solid #eaeaea;">
								<QuoteCard container direction={"row"} justify="center" alignItems="center">
									<QuoteCard item xs={6} className={"interaction"} onClick={handleClickPrint}>
										<img src={iconTinyPrint} />
										<div>print</div>
									</QuoteCard>
									<QuoteCard item xs={6} className={"interaction right"} onClick={handleEmailPrint}>
										<img src={iconTinyEmail} />
										<div>email</div>
									</QuoteCard>
								</QuoteCard>
							</QuoteCard> 
							*/}
						</QuoteCard>
					</Fade>
				</>
			)}
			{props.quoteModel === undefined && (
				<>
					<QuoteCard
						container
						alignItems="start"
						direction={"column"}
						padding="0"
						margin="0"
						additionalcss="border: 1px solid #ffffff; border-radius: 5px; height: 383px;"
					></QuoteCard>
				</>
			)}
		</>
	);
};
