import React, { useState, useContext, useEffect } from "react";
import { Theme, Fade, Box, Avatar, Typography, Button, Paper } from "@material-ui/core";
import { Centered } from "Core/Components/General";
import { useRouter } from "Core/Utils";
import { Redirect } from "react-router";
import { StoresContext, Stores, StoresInstance } from "Custom/Stores";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { EditableInput } from "Core/Components/EditableInput";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { nameofFactory } from "Core/Utils/Utils";
import { LoginModel } from "../Models";

let loginViewModel = new LoginViewModel();

const domainStores = StoresInstance.domain;
const nameof = nameofFactory<LoginModel>();

export const LoginPage: React.FunctionComponent = () => {
	const { history, location, match } = useRouter();
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState("");

	if (domainStores.AccountStore.IsLoggedIn) {
		return <Redirect to={"/"} />;
	}

	const doSubmit = async (e: any) => {
		e.preventDefault();

		if (loginViewModel.isModelValid()) {
			let apiResult = await loginViewModel.login();
			if (apiResult !== null) {
				if (apiResult.wasSuccessful) {
					domainStores.AccountStore.getLoginState(apiResult.payload);
					const { from } = location.state || { from: { pathname: "/" } };
					if (from) {
						history.push(from.pathname);
					}
				} else {
					if (apiResult.errors.length > 0) {
						setErrorMessage("* " + apiResult.errors[0].message);
					} else {
						setErrorMessage("* Unknown error has occurred.");
					}
				}
			}
		}
	};

	return (
		<Centered>
			<Fade in timeout={1000}>
				<Paper>
					<Box padding="5px">
						<Box justifyContent="center" textAlign="center" display="grid" minWidth="300px">
							<Avatar>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h5">
								Sign in
							</Typography>
						</Box>
						<form onSubmit={doSubmit}>
							{errorMessage !== "" && (
								<Typography variant="caption" style={{ color: "red" }}>
									{errorMessage}
								</Typography>
							)}
							<EditableInput
								type="email"
								label="Email Address"
								validateOnBlur={true}
								viewModel={loginViewModel}
								fieldName="emailAddress"
							/>
							<EditableInput type="password" label="Password" viewModel={loginViewModel} fieldName="password" />
							<Button type="submit" fullWidth variant="contained" color="primary">
								{isLoading ? "Please Wait..." : "Sign In"}
							</Button>
						</form>
					</Box>
				</Paper>
			</Fade>
		</Centered>
	);
};
