import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class GetQuoteModel extends ModelBase<GetQuoteModel, GetQuoteModelDTO> {
	@MobX.observable
	@IsNotEmpty({ message: "Customer name is required" })
	public customerName: string = "";

	@MobX.observable
	public dateEffective: Date = new Date();

	@MobX.observable
	public coverageBuilding: number = 0;

	@MobX.observable
	public coverageContents: number = 0;

	@MobX.observable
	public valueLossOfUse: number = 0;

	@MobX.observable
	public floodZone: string = "";

	@MobX.observable
	public yearBuilt: number = 0;

	@MobX.observable
	public valueBuilding: number = 0;

	@MobX.observable
	public numberFloors: number = 0;

	@MobX.observable
	public elevator: number = 0;

	@MobX.observable
	@IsNotEmpty({ message: "Building SqFt is required" })
	@IsInt({ message: "Building SqFt must be a valid number" })
	public buildingSqft: number = 0;

	public fromDto = (model: GetQuoteModelDTO) => {};
	public toDto = (model: GetQuoteModel) => {};
}

export interface GetQuoteModelDTO {
	customerName: string;
	dateEffective: string;
	coverageBuilding: number;
	coverageContents: number;
	valueLossOfUse: number;
	floodZone: string;
	yearBuilt: number;
	valueBuilding: number;
	numberFloors: number;
	elevator: number;
	buildingSqft: number;
}
