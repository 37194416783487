import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";

export class BindQuoteResponseModel extends ModelBase<BindQuoteResponseModel, BindQuoteResponseModelDTO> {
	@MobX.observable
	public id: string = "";

	@MobX.observable
	public quoteId: string = "";

	@MobX.observable
	public success: boolean = false;

	@MobX.observable
	public messages: string = "";

	@MobX.observable
	public createdDate: string = "";

	public fromDto = (model: BindQuoteResponseModelDTO) => {};
	public toDto = (model: BindQuoteResponseModel) => {};
}

export interface BindQuoteResponseModelDTO {
	id: string;
	quoteId: string;
	success: boolean;
	messages: string;
	createdDate: string;
}
