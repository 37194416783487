import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class FloodDamageModel extends ModelBase<FloodDamageModel, FloodDamageModelDTO> {
	@MobX.observable
	@IsInt({ message: "Loss amount must be a valid number" })
	public priorLossAmount: number = 0;

	@MobX.observable
	public priorLossStorm: string = "";

	@MobX.observable
	public priorLossDate: string | undefined = undefined;

	public fromDto = (model: FloodDamageModelDTO) => {};
	public toDto = (model: FloodDamageModel) => {};
}

export interface FloodDamageModelDTO {
	priorLossDate: string;
	priorLossAmount: number;
	priorLossStorm: string;
}
