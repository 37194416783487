import {
	AppHeaderStart,
	BackLink,
	GridSpaced,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Grid, Link } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { CommercialList } from "Custom/Components/Commercial/CommercialList";
import { QuoteRequestViewModel } from "Custom/ViewModels";
import { useMediaQuery } from "Core/Utils/Media/mediaQuery";
import { useRouter } from "../../Core/Utils";

let quoteRequestViewModel = new QuoteRequestViewModel();

export const CommercialProperty: React.FC = () => {
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const prevStep = (callbackVal: string) => {
		history.push("/propertytype");
	};

	const nextStep = (callbackVal: string) => {
		//quoteRequestViewModel.setBuildingUse(callbackVal.toLocaleLowerCase());
		history.push("/getquote");
	};

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<GridSpaced container className="gridContainer">
							<GridSpaced item xs={12}>
								{!isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
								<SterlingMainBox
									additionalcss={
										"display: flex; flex-direction: column; justify-content: center; height: calc(100% - 60px); padding-bottom: 60px;"
									}
								>
									<CommercialList commericalChoice={nextStep} />
								</SterlingMainBox>
								{isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
							</GridSpaced>
						</GridSpaced>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
