import { QuoteResponseModel } from "../Models/QuoteResponseModel";
import { QuoteRequestModel } from "../Models/QuoteRequestModel";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";
import { Server } from "Custom/Globals/AppUrls";

export class QuoteResponseViewModel extends ViewModelBase<QuoteResponseModel> {
	constructor() {
		super(new QuoteResponseModel(), true);
		this.setDecorators(QuoteResponseModel);
	}

	public isFieldValid(fieldName: keyof QuoteResponseModel & string, value: any): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);
		return isValid;
	}

	public async getQuote(quoteRequest: QuoteRequestModel) {
		let apiResult = await this.Post<QuoteResponseModel>(Server.Api.Quotes.GetQuote, quoteRequest);
		if (apiResult.wasSuccessful) {
			//this.quoteStore.setQuoteResponse(apiResult.payload);
		} else {
			console.log("Payload Errors: " + apiResult.errors);
		}
		return apiResult;
	}

	public showQuote() {
		//return this.quoteStore.getQuoteResponse;
	}
}
