import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
/* import { QuoteModel } from "./QuoteModel"; */
import { QuoteDeductableModel } from "./QuoteDeductableModel";
import { QuoteDeductibleListModel } from "./QuoteDeductibleListModel";
import { QuoteRequestModel } from "./QuoteRequestModel";
import { ApiResult } from "Core/Models";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class QuoteResponseModel extends ModelBase<QuoteResponseModel, QuoteResponseModelDTO> {
	@MobX.observable
	public id: string = "";

	@MobX.observable
	public success: boolean = false;

	@MobX.observable
	public messages: string[] = [];

	@MobX.observable
	public quoteCount: number = 0;

	@MobX.observable
	public quoteRequest: QuoteRequestModel = new QuoteRequestModel();

	@MobX.observable
	public quotes: QuoteDeductibleListModel = new QuoteDeductibleListModel();

	@MobX.observable
	public createdDate: string = "";

	public fromDto = (model: QuoteResponseModelDTO) => {
		for (let key in model) {
			if (model.hasOwnProperty(key)) {
				this[key] = model[key];
			}
		}
	};
	public toDto = (model: QuoteResponseModel) => {};
}

export interface QuoteResponseModelDTO {
	id: string;
	success: boolean;
	messages: string[];
	quoteCount: number;
	quoteRequest: QuoteRequestModel;
	quotes: QuoteDeductibleListModel;
	createdDate: string;
}
