import { observable, action, runInAction, computed, IComputedValue } from "mobx";
import { createViewModel, computedFn } from "mobx-utils";
import { ApiResult, ModelBase } from "../Models";
import axios, * as Axios from "axios";
import { CheckHttpStatus, MergeDefaultConfig } from "../Utils/Axios";
import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { LocationState, History } from "history";
import { getFromContainer, MetadataStorage, validate, validateOrReject, Validator } from "class-validator";
import { ValidationMetadata } from "class-validator/metadata/ValidationMetadata";
import _ from "lodash";
import dot from "dot-object";
import { createProxy } from "ts-object-path";
import { getParentObjectPath } from "../Utils/Utils";

//Give typing and intellisense to the field names
export type Field<T> = keyof T & string;
export type ValidationResponse = {
	isValid: boolean;
	errorMessage: string;
};
export type Create<T> = new (...args: any[]) => T;
export abstract class ViewModelBase<T extends IModel<T>> implements IViewModel {
	@observable public IsLoading: boolean = false;
	@observable public IsErrored = false;
	@observable public Errors: string = "";
	@observable public Valid: boolean = false;

	@action protected setIsLoading = (state: boolean) => (this.IsLoading = state);
	@action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
	@action protected setErrors = (state: string) => (this.Errors = state);

	public model: T = {} as T;
	public history = {} as any;
	public location = {} as any;

	public validatorStorage: MetadataStorage = getFromContainer(MetadataStorage);
	//public meta2 = this.validatorStorage.getTargetValidationMetadatas(ModelBase, "");
	private meta = {} as ValidationMetadata[];
	private validator = new Validator();
	private static self = {} as any;

	constructor(model: T, undoable: boolean = false) {
		ViewModelBase.self = this;
		this.setModel(model, undoable);
		(window as any).model = model;
	}

	private getType = <T>(TCtor: new (...args: any[]) => T) => {
		const type = typeof TCtor;
		return type;
	};

	//This must be overriden in any class that extends this base class
	abstract isFieldValid(fieldName: string | boolean | Date | number, value: any): boolean;

	public get getModel() {
		return this.model;
	}

	public setModel(model: T, undoable: boolean = false) {
		if (undoable) {
			//This is a helper method to make the model undoable. You must call submit on the model to save changes
			this.model = createViewModel(model);
			return;
		}
		this.model = model;
	}

	public getProxy = () => {
		return createProxy<T>();
	};

	public setRouter(history: History<LocationState>, location: LocationState) {
		this.history = history;
		this.location = location;
	}

	public saveModel(): void {
		(this.model as any).submit();
	}

	public resetModel(): void {
		(this.model as any).reset();
	}

	@action
	public setValue(fieldName: Field<T>, value: string | number | boolean | Date) {
		this.model.setValue(fieldName, value);
	}

	private static instance<T extends IModel<T>>() {
		return this;
	}

	public getValue(fieldName: Field<T>): string | number | boolean | Date {
		let value = this.model.getValue(fieldName);
		if (value === null) {
			if (_.isString(value)) {
				value = "";
			} else if (_.isBoolean(value)) {
				value = false;
			}
			this.model.setValue(fieldName, value);
		}
		return value;
	}

	//@computed
	// public getValue = computedFn(function(fieldName: Field<T>): string | number | boolean | Date {
	// 	debugger;
	// 	let value = (ViewModelBase.self as any).model.getValue(fieldName);
	// 	if (value === null) {
	// 		if (_.isString(value)) {
	// 			value = "";
	// 		} else if (_.isBoolean(value)) {
	// 			value = false;
	// 		}
	// 		(ViewModelBase.self as any).model.setValue(fieldName, value);
	// 	}
	// 	return value;
	// });

	@action
	public setError(fieldName: Field<T>, value: string) {
		this.model.setError(fieldName, value);
	}

	public getError(fieldName: Field<T>) {
		return this.model.getError(fieldName);
	}

	@action
	public setValid(fieldName: Field<T>, value: boolean): void {
		this.model.setValid(fieldName, value);
	}

	public getValid(fieldName: Field<T>): boolean {
		return this.model.getValid(fieldName);
	}

	@action
	public setDirty(fieldName: Field<T>, value: boolean): void {
		this.model.setDirty(fieldName, value);
	}

	public getDirty(fieldName: Field<T>): boolean {
		return this.model.getDirty(fieldName);
	}

	@action
	public setTouched(fieldName: Field<T>, value: boolean): void {
		this.model.setTouched(fieldName, value);
	}

	public getTouched(fieldName: Field<T>): boolean {
		return this.model.getTouched(fieldName);
	}

	/*public isModelValid = () => {
		let valid = true;

		//Run through first time triggering the valid check
		for (let prop in this.model.Valid) {
			if (Object.prototype.hasOwnProperty.call(this.model.Valid, prop)) {
				this["isFieldValid"](prop, this.model[prop]);
			}
		}

		//Run through again checking properties of model
		for (let prop in this.model.Valid) {
			if (Object.prototype.hasOwnProperty.call(this.model.Valid, prop)) {
				if (valid) {
					valid = this.model.getValid(prop);
				}
			}
		}
		runInAction(() => {
			this.Valid = valid;
		});
		return valid;
	};*/

	public isModelValid = () => {
		let valid = true;
		let target = this.model;
		for (let prop in target) {
			if (
				prop.indexOf("Errors.") < 0 &&
				prop.indexOf("Dirty.") < 0 &&
				prop.indexOf("Touched.") < 0 &&
				prop.indexOf("Valid.") < 0
			) {
				if (prop != "getParentObjectPath") {
					this["isFieldValid"](prop, this.model[prop]);
				}
			}
		}

		// //Run through again checking properties of model
		for (let prop in target) {
			if (
				prop.indexOf("Errors.") < 0 &&
				prop.indexOf("Dirty.") < 0 &&
				prop.indexOf("Touched.") < 0 &&
				prop.indexOf("Valid.") < 0
			) {
				if (valid) {
					valid = this.model.getValid(prop);
				}
			}
		}

		runInAction(() => {
			this.Valid = valid;
		});
		return valid;
	};

	private parseObjectProperties = (obj: any, parse: any) => {
		for (let k in obj) {
			if (typeof obj[k] === "object" && obj[k] !== null) {
				this.parseObjectProperties(obj[k], parse);
			} else if (obj.hasOwnProperty(k)) {
				parse(obj, k);
			}
		}
	};

	public setDecorators = (model: any) => {
		this.meta = this.validatorStorage.getTargetValidationMetadatas(model, "");
	};

	public validateDecorators = (fieldName: keyof T & string): ValidationResponse => {
		let target = this.meta.filter(a => a.propertyName === fieldName).reverse();
		let message = "";
		if (target && target.length > 0) {
			let validated = false;
			target.some((t: ValidationMetadata) => {
				validated = this.validator.validateValueByMetadata(this.getValue(fieldName), t!);
				message = t.message.toString();
				if (!validated) return true;
			});
			//let vp = this.validator.length("", 1, 10);
			//let ve = new ValidationExecutor(this.validator);
			//let promise = await validate(target!);
			return {
				isValid: validated,
				errorMessage: validated ? "" : message.toString(),
			};
		} else {
			//No decorators found so presume no validation required
			return { isValid: true, errorMessage: "" };
		}
	};

	public getModelAsPayload(): T {
		let payload = this.getAnyModelAsPayload(this.model);
		return payload;
	}

	private getAnyModelAsPayload(model: any): T {
		let exclude = ["Dirty", "Errors", "Valid", "Touched", "localComputedValues", "localValues", "isPropertyDirty"];
		let payload = {} as T;
		for (let key in this.model) {
			if (this.model.hasOwnProperty(key)) {
				if (!exclude.includes(key)) {
					//EN: Check for recursed models
					if (key == "model" && typeof this.model[key] === "object") {
						continue;
					}
					payload[key] = this.model[key];
					if (typeof payload[key] === "string") {
						//EN: Exclude null characters in a string
						((payload[key] as any) as string).replace(/\0/g, "");
					}
				}
			}
		}
		return payload;
	}

	Get = <TPayload = ApiResult<undefined>>(
		url: string,
		//model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<ApiResult<TPayload>> => {
		const requestConfig = this.getConfig(config);
		this.setIsLoading(true);
		const postPromise = axios
			.get<ApiResult<TPayload>>(url, requestConfig)
			.then(response => {
				this.setIsLoading(false);
				CheckHttpStatus(response);
				return response.data;
			})
			.catch(error => {
				console.log(error);
				return { wasSuccessful: false };
			});

		return postPromise as Promise<ApiResult<TPayload>>;
	};

	Post = <TPayload = ApiResult<undefined>>(
		url: string,
		model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<ApiResult<TPayload>> => {
		const requestConfig = this.getConfig(config);
		this.setIsLoading(true);

		const postPromise = axios
			.post<ApiResult<TPayload>>(url, this.getAnyModelAsPayload(model), requestConfig)
			.then(response => {
				this.setIsLoading(false);
				CheckHttpStatus(response);
				return response.data;
			})
			.catch(error => {
				this.setIsErrored(true);
				this.setIsLoading(false);
				this.setErrors(error);
				console.log(error);
				return { wasSuccessful: false };
			});

		return postPromise as Promise<ApiResult<TPayload>>;
	};

	getConfig = (config?: Axios.AxiosRequestConfig) => {
		const requestConfig = MergeDefaultConfig(config);
		//Sets the bearer on every header if available
		//Note: You might need to remove this bearer if calling 3rd party api's
		let jwt = (window as any).jwt;
		if (jwt && jwt.length > 0) {
			requestConfig.headers = {
				Authorization: "Bearer " + (window as any).jwt,
			};
		}
		return requestConfig;
	};
}
