import React from "react";
import { StoresInstance, DomainStores } from "Custom/Stores";
import { HomeMain, MainRoot, GridSpaced } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { PolicyHeader, PolicyTitle } from "Shoothill.Components/StylesAppSpecific/PolicyStyling";
import { PolicyDetailForm } from "Custom/Components/Policies/PolicyDetailForm";
import { QuoteModel } from "Custom/Models";
import iconYourQuotes4TitleWhite from "Content/icon-yourQuotes4Title-White.svg";

export const PolicySelected: React.FC = () => {
	const domainStores = StoresInstance.domain;

	function roundToTwo(num: number) {
		return +(Math.round(num * 100 + Number.EPSILON) / 100);
	}

	let chosenQuote: QuoteModel = domainStores.QuoteStore.chosenQuote;
	let policyAddress: string = domainStores.QuoteResponseViewModel.model.quoteRequest.address;
	policyAddress += ", " + domainStores.QuoteResponseViewModel.model.quoteRequest.city;
	policyAddress += ", " + domainStores.QuoteResponseViewModel.model.quoteRequest.state;

	const titleMarkup = (
		<>
			<GridSpaced container additionalcss={"background-color: #1ab7ea; color: #fff;"}>
				<GridSpaced item xs={12}>
					<PolicyTitle>
						<div className="policy-selected-icon-container">
							<img src={iconYourQuotes4TitleWhite} alt={"Sterling"} />
						</div>
						<div className="policy-selected-title-container">
							<h1>
								Your quote (#{chosenQuote.quoteId}) for: <span>{policyAddress}</span>
							</h1>
							<h2>Annual premium ${roundToTwo(parseInt(chosenQuote.premiumTotal))}</h2>
						</div>
					</PolicyTitle>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<PolicyHeader additionalcss={"height: unset; background-color: #ffffff; color: black; margin: 20px auto;"}>
						{titleMarkup}
						<PolicyDetailForm quoteId={chosenQuote.quoteId} chosenQuote={chosenQuote} />
					</PolicyHeader>
				</HomeMain>
			</MainRoot>
		</>
	);
};
