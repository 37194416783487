import "./Home.css";

import {
	AppHeaderStart,
	BackLink,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Box, Fade, Grid, Link } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { iconFloodDamageGraphic, iconNoBig, iconYesBig } from "../../Content/EmbeddedSVGs";

import { StoresInstance } from "../Stores";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../Core/Utils";

/* import { QuoteRequestViewModel } from "Custom/ViewModels"; */

export const FloodDamage: React.FC = () => {
	const domainStores = StoresInstance.domain;

	const { history } = useRouter();

	let activeValue: string = domainStores.QuoteRequestViewModel.getValue("priorLoss") as string;

	function onClickBack(): void {
		history.push("/address");
	}

	const panelActive = (panelValue: any) => {
		let retVal: string = "panelIcon";

		if (activeValue === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: string) => {
		domainStores.QuoteRequestViewModel.setValue("priorLoss", clickVal);
		let historyVal: string = "/propertytype";
		if (clickVal === "0") {
			domainStores.QuoteRequestViewModel.setValue("priorLossStorm", "");
			domainStores.QuoteRequestViewModel.setValue("priorLossDate", new Date());
			domainStores.QuoteRequestViewModel.setValue("priorLossAmount", 0);
		} else {
			historyVal = "/flooddamagedetail";
		}
		history.push(historyVal);
	};

	return useObserver(() => (
		<MainRoot>
			<HomeMain role="main">
				<AppHeaderStart>
					<Grid container className="gridContainer">
						<Grid item xs={12}>
							<BackLink title={"Go Back"} className="backLink" onClick={onClickBack}>
								Back
							</BackLink>
							<SterlingMainBox>
								<div>{iconFloodDamageGraphic}</div>
								<h1>Has the property experienced flood damage in the past?</h1>
							</SterlingMainBox>
							<Grid container direction="row" justify="center" alignItems="center">
								<Grid item xs={3} md={2} className={panelActive("1")}>
									<div>
										<Link component="button" variant="body2" onClick={() => onClickDecide("1")}>
											{iconYesBig}
										</Link>
									</div>
									<Link component="button" variant="body2" onClick={() => onClickDecide("1")}>
										YES
									</Link>
								</Grid>
								<Grid item xs={3} md={2} className={panelActive("0")}>
									<div>
										<Link component="button" variant="body2" onClick={() => onClickDecide("0")}>
											{iconNoBig}
										</Link>
									</div>
									<Link component="button" variant="body2" onClick={() => onClickDecide("0")}>
										NO
									</Link>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AppHeaderStart>
			</HomeMain>
		</MainRoot>
	));
};
