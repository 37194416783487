import { AppRoutes } from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { hot, setConfig } from "react-hot-loader";
import { Layout } from "./Custom/Views/Layout";
import React from "react";
//SW uncomment to use google analytics and implement below
//import ReactGA from "react-ga";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "Shoothill.Components/StylesAppSpecific/AppStyling";

setConfig({
	showReactDomPatchNotification: false,
});

const App: React.FC = () => {
	//SW google analytics
	//ReactGA.initialize("your code here");

	return (
		<StylesProvider injectFirst>
			<MuiThemeProvider theme={defaultTheme}>
				<ThemeProvider theme={defaultTheme}>
					<CssBaseline>
						<Router>
							<Layout>
								<AppRoutes />
							</Layout>
						</Router>
					</CssBaseline>
				</ThemeProvider>
			</MuiThemeProvider>
		</StylesProvider>
	);
};

export default hot(module)(App);
