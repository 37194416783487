import { AccountStatus, ApiResult } from "Core/Models";
import { RegisterUserModel } from "Custom/Models/RegisterUserModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class RegisterUserViewModel extends ViewModelBase<RegisterUserModel> {
	constructor() {
		super(new RegisterUserModel());
		this.setDecorators(RegisterUserModel);
	}

	public register = async (): Promise<ApiResult<AccountStatus>> => {
		let apiResult = await this.Post<AccountStatus>("/api/account/register", this.model);
		return apiResult;
	};

	public isFieldValid(fieldName: keyof RegisterUserModel & string, value: any): boolean {
		let { isValid, errorMessage } = this.validateDecorators(fieldName);

		//Overriding the decorators to check password fields
		if (fieldName === "confirmPassword") {
			errorMessage = this.isConfirmPasswordValid;
			isValid = errorMessage === "";
		}

		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}

	public get isConfirmPasswordValid(): string {
		if (this.model.password !== this.model.confirmPassword && this.model.password !== "") return "Passwords must match";
		return "";
	}
}
