import styled, { isStyledComponent } from "styled-components";
import { Grid, Button, Modal, Paper } from "@material-ui/core";

export interface IComponentProps {
	fontWeight?: string;
	fontcolor?: string;
	backgroundcolor?: string;
	hovercolor?: string;
	padding?: string;
	margin?: string;
	additionalcss?: string;
	fontsize?: string;
	textAlign?: string;
}

export const PaymentModal: any = styled(Modal)<IComponentProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #ccc;
`;

export const PaymentPaper: any = styled(Paper)<IComponentProps>`
	padding: ${props => (props.padding !== undefined ? props.padding : "0")};
	background-color: ${props => (props.backgroundcolor !== undefined ? props.backgroundcolor : "#ffffff")};
	max-width: 500px;
	width: 100%;
	min-height: 319px;
	height: auto;
	border-radius: 5px;

	.customer-paymentheader,
	.customer-mydetails {
		padding: 15px 30px;
	}

	.customer-paymentheader {
		background-color: #1ab7ea;
		color: #ffffff;
		min-height: 73px;
		width: 100%;
		vertical-align: middle;
		border-radius: 5px 5px 0px 0px;
		h2,
		img {
			display: inline-flex;
			font-size: 18px;
		}
	}

	.customer-mydetails {
		p {
			font-size: 12px;
		}

		button {
			padding: 7px 36px;
			font-size: 14px;
			background-color: #1ab7ea;
			color: #ffffff;
			border-radius: 5px;
			text-transform: unset !important;
		}

		.error {
			font-size: 10px;
			color: red;
			font-weight: 100;
		}

		> div {
			text-align: center;
		}
	}
`;
