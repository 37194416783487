import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import React, { useState } from "react";
import { SterlingForm, SterlingText } from "Shoothill.Components/StylesAppSpecific/FormStyling";
import { iconHouse4Tile, iconNextArrow } from "../../../Content/EmbeddedSVGs";

import { EnclosureSizeViewModel } from "Custom/ViewModels";
import { Link } from "@material-ui/core";
import { StoresInstance } from "../../Stores";
import { useMediaQuery } from "../../../Core/Utils/Media";

let enclosureSizeViewModel = new EnclosureSizeViewModel();

interface IProps {
	basencSize: (chosenVal: boolean) => void;
	propertyTitlePart: string;
}
export const BasementEnclosureSize: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;
	const [errorMessage, setErrorMessage] = useState("");

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const doSubmit = (e: any) => {
		e.preventDefault();
		if (!enclosureSizeViewModel.isModelValid()) {
			setErrorMessage("Problem");
		} else {
			setErrorMessage("No problem");
			let size: any = enclosureSizeViewModel.getValue("enclosureSqft");
			domainStores.QuoteRequestViewModel.setValue("enclosureSqft", size);
			props.basencSize(true);
		}
	};

	const rowOne = (
		<>
			<GridSpaced container>
				<GridSpaced item xs={12} md={12}>
					<GridSpaced container margin={"0px 0px 15px"}>
						<GridSpaced item xs={12} additionalcss={"margin-bottom: 25px;"}>
							Please add the enclosure/basement size in SQ FT
						</GridSpaced>
						<GridSpaced item xs={12}>
							<SterlingText
								viewModel={enclosureSizeViewModel}
								placeholder={"0 SQ FT"}
								additionalcss={isMobile ? "width: 336px;" : "width: 554px; color: #7D7D7D;"}
								//value={enclosureSizeViewModel.getValue("enclosureSqft")}
								fieldName="enclosureSqft"
								type={"number"}
							/>
						</GridSpaced>
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={isMobile ? "text-align: center;" : "text-align: center; svg {width: 64px;}"}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 0px"}
			>
				{iconHouse4Tile}
				What is the size of the enclosure/basement?
			</H1TitleAndImg>
		</>
	);

	return (
		<>
			<SterlingForm onSubmit={doSubmit} id="basementenclosuresizeForm" style={{ marginTop: "90px" }}>
				<GridSpaced item xs={12}>
					<GridSpaced container direction="row" justify="center" alignItems="center">
						<GridSpaced item xs={12}>
							{headingTitle}
						</GridSpaced>
					</GridSpaced>
					<GridSpaced
						container
						direction="row"
						justify="center"
						alignItems="center"
						margin={isMobile ? "" : "25px 0px 0px 40px"}
					>
						<GridSpaced item xs={12} md={2}></GridSpaced>
						<GridSpaced
							item
							xs={12}
							md={8}
							additionalcss={isMobile ? "max-width: 90%;" : " text-align: center; font-size: 18px;"}
						>
							{rowOne}
						</GridSpaced>
						<GridSpaced item xs={12} md={2} additionalcss={isMobile ? "text-align: center;" : "margin-top: 60px;"}>
							<div>
								<Link component="button" variant="body2" onClick={() => doSubmit}>
									{iconNextArrow}
								</Link>
							</div>
							Next
						</GridSpaced>
					</GridSpaced>
				</GridSpaced>
			</SterlingForm>
		</>
	);
};
