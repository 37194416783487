import React, { useState } from "react";

import { DeductableSlider } from "Shoothill.Components/StylesAppSpecific/QuoteStyling";

interface IProps {
	sliderValue: (chosenVal: number) => void;
	initialValue: number;
}

export const YourQuoteSlider: React.FC<IProps> = props => {
	const [sliderVal, setSliderVal] = useState<number>(props.initialValue);

	const handleSliderChange = (changeValue: number) => {
		setSliderVal(convertNums(changeValue));
		props.sliderValue(convertNums(changeValue));
	};

	const convertNums = (currentVal: number) => {
		let retVal: number = currentVal;
		// PW: - conversion is needed to ensure the points on the slider are evenly
		// spaced using the value directly will not allow this with the given values.
		switch (currentVal) {
			case 1000: {
				retVal = 1000;
				break;
			}
			case 2000: {
				retVal = 2000;
				break;
			}
			case 3000: {
				retVal = 5000;
				break;
			}
			case 4000: {
				retVal = 10000;
				break;
			}
			case 5000: {
				retVal = 25000;
				break;
			}
			case 6000: {
				retVal = 50000;
				break;
			}
			case 7000: {
				retVal = 100000;
				break;
			}
		}
		return retVal;
	};

	const sliderPoints = [
		{
			value: 1000,
			label: "$1,000",
		},
		{
			value: 2000,
			label: "$2,000",
		},
		{
			value: 3000,
			label: "$5,000",
		},
		{
			value: 4000,
			label: "$10,000",
		},
		{
			value: 5000,
			label: "$25,000",
		},
		{
			value: 6000,
			label: "$50,000",
		},
		{
			value: 7000,
			label: "Excess",
		},
	];

	return (
		<>
			<DeductableSlider
				defaultValue={props.initialValue}
				getAriaValueText={handleSliderChange}
				valueLabelDisplay={"off"}
				marks={sliderPoints}
				max={7000}
				min={1000}
				step={1000}
			/>
		</>
	);
};
