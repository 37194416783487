import { createContext } from "react";
import * as Utils from "Core/Utils/Utils";
import * as Models from "Custom/Models";
// Libs
// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Custom/Models";

export class AccountStore extends BaseStore {
	@observable public IsLoggedIn: boolean = false;
	@observable public UserName: string = "";
	@observable public DisplayName: string = "";
	@observable public UserRoles: string[] = [];
	@observable public UseAdminGrids: boolean = false;
	@observable public UseAdminControlsLight: boolean = false;

	private jwt: string | null = null;

	public constructor() {
		super();
	}

	public init(stores: Stores, initialState: InitialState) {
		this.UseAdminGrids = initialState.appSettings.useAdminGrids === "true" ? true : false;
		this.UseAdminControlsLight = initialState.appSettings.useAdminControlsLight === "true" ? true : false;
		this.getLoginState(initialState.accountStatus.jwt);
	}

	@action
	public setIsLoggedIn(state: boolean) {
		this.IsLoggedIn = state;
	}

	public isInRole = (role: string): boolean => {
		if (this.UserRoles && this.UserRoles.length > 0) {
			return this.UserRoles.includes(role);
		}

		return false;
	};

	@action
	public Logout = (redirect: boolean = true): void => {
		localStorage.removeItem(".auth");
		this.IsLoggedIn = false;
		this.UserName = "";
		this.DisplayName = "";
		this.UserRoles = [];
		(window as any).jwt = null;
		if (redirect) {
			window.location.href = "/";
		}
	};

	@action
	public getLoginState = (jwt: any | string | undefined | null = null) => {
		if (!jwt || jwt.length === 0) {
			this.jwt = localStorage.getItem(".auth") as string;
			//this.jwt = this.getCookie("token") as string;
		} else {
			this.jwt = jwt;
		}

		if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
			const data = Utils.parseJwt(this.jwt);
			localStorage.setItem(".auth", this.jwt);
			this.IsLoggedIn = true;
			this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
			this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
			this.UserRoles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
			// EN: For Debugging
			(window as any).jwt = this.jwt;
		} else {
			let url = encodeURI("https://quotes.sterlingsu.com/");
			if (window.location.host.indexOf("localhost") < 0) {
				window.location.href = "https://backoffice.sterlingsu.com/login?returnurl=https://quotes.sterlingsu.com";
			}
		}
	};

	public getCookie = (cname: string) => {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	};

	@computed get GetUseAdminGrids(): boolean {
		return this.UseAdminGrids;
	}

	@computed get GetUseAdminControlsLight(): boolean {
		return this.UseAdminControlsLight;
	}
}
