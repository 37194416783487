import { ModelBase } from "../../Core/Models/ModelBase";
import * as MobX from "mobx";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class BindQuoteModel extends ModelBase<BindQuoteModel, BindQuoteModelDTO> {
	@MobX.observable
	public id: string = "";

	@MobX.observable
	public quoteId: string = "";

	@MobX.observable
	public excessQuoteId: number = 0;

	@MobX.observable
	@IsNotEmpty({ message: "Payment option is required" })
	public paymentOption: string = "echeck";

	@MobX.observable
	@IsNotEmpty({ message: "Policy holder name is required" })
	@Length(0, 255, { message: "Policy holder is too long" })
	public policyHolderName: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Ownership type is required" })
	public ownershipType: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Address 1 is required" })
	@Length(0, 255, { message: "Address is too long" })
	public address1: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "City is required" })
	@Length(0, 255, { message: "City is too long" })
	public city: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "State is required" })
	public state: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Zipcode is required" })
	@Length(0, 5, { message: "Zipcode is too long" })
	public zipcode: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Policy holder email is required" })
	@Length(0, 255, { message: "Policy holder email is too long" })
	@IsEmail(undefined, { message: "Invalid email" })
	public email: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Phone number is required" })
	@IsInt({ message: "Phone number must be a valid number" })
	public phone: number = 0;

	@MobX.observable
	@IsNotEmpty({ message: "Policy effective date is required" })
	public dateEffective: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Policy expiry date is required" })
	public dateExpiration: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Mortgage details are required" })
	@Length(0, 255, { message: "Mortgage details are too long" })
	public mortgageDetails: string = "";

	@MobX.observable
	public address2: string = "";

	@MobX.observable
	public excessUnderlyingPolicy: string = "";

	@MobX.observable
	public excessUnderlyingCarrier: string = "";

	@MobX.observable
	public fullRequestJson: string = "";

	@MobX.observable
	public createdDate: string = "";

	@MobX.observable
	@IsNotEmpty({ message: "Check name is required" })
	@Length(0, 255, { message: "Check name is too long" })
	public checkName?: string;

	@MobX.observable
	@IsNotEmpty({ message: "Bank name is required" })
	@Length(0, 255, { message: "Bank name is too long" })
	public bankName?: string;

	@MobX.observable
	@IsNotEmpty({ message: "Routing number is required" })
	@Length(0, 255, { message: "Routing number is too long" })
	public routingNumber?: string;

	@MobX.observable
	@IsNotEmpty({ message: "Account number is required" })
	public accountNumber?: number;

	@MobX.observable
	@IsNotEmpty({ message: "Card name is required" })
	@Length(0, 255, { message: "Card name is too long" })
	public payerNameCard?: string;

	@MobX.observable
	@IsNotEmpty({ message: "Card number is required" })
	public cardNumber?: number;

	@MobX.observable
	@IsNotEmpty({ message: "Exp month is required" })
	@Max(12, { message: "Invalid month" })
	public cardExpirationMonth?: number;

	@MobX.observable
	@IsNotEmpty({ message: "Exp year is required" })
	@Min(1000, { message: "Invalid year" })
	@Max(9999, { message: "Invalid year" })
	public cardExpirationYear?: number;

	@MobX.observable
	@IsNotEmpty({ message: "CVV is required" })
	@Max(9999, { message: "Invalid CVV" })
	public cardCvvCode?: string;

	public fromDto = (model: BindQuoteModelDTO) => {};
	public toDto = (model: BindQuoteModel) => {};
}

export interface BindQuoteModelDTO {
	id: string;
	quoteId: string;
	excessQuoteId: string;
	paymentOption: string;
	policyHolderName: string;
	ownershipType: string;
	address1: string;
	city: string;
	state: string;
	zipcode: string;
	email: string;
	phone: number;
	dateEffective: string;
	dateExpiration: string;
	mortgageDetails: string;
	address2: string;
	excessUnderlyingPolicy: string;
	excessUnderlyingCarrier: string;
	fullRequestJson: string;
	createdDate: string;
	checkName?: string;
	bankName?: string;
	routingNumber?: string;
	accountNumber?: number;
	payerNameCard?: string;
	cardNumber?: number;
	cardExpirationMonth?: number;
	cardExpirationYear?: number;
	cardCvvCode?: string;
}
