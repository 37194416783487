import "./Home.css";

import {
	AppHeaderStart,
	BackLink,
	GridSpaced,
	HomeMain,
	MainRoot,
	SterlingMainBox,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Box, Grid, InputAdornment, Link } from "@material-ui/core";
import {
	InputContainer,
	InputParent,
	NextBtn,
	SterlingForm,
	SterlingLabel,
	SterlingText,
} from "../../Shoothill.Components/StylesAppSpecific/FormStyling";
import React, { useContext, useState } from "react";
import { iconFloodDamageGraphic, iconNextSubmit } from "Content/EmbeddedSVGs";

import { EditableInput } from "Core/Components/EditableInput";
import { FloodDamageModel } from "Custom/Models";
import { FloodDamageViewModel } from "Custom/ViewModels";
import { StoresInstance } from "../Stores";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../Core/Utils";

let floodDamageViewModel = new FloodDamageViewModel();

export const FloodDamageDetail: React.FC = () => {
	const domainStores = StoresInstance.domain;
	const [lossDate, setLossDate] = useState<string | undefined>(domainStores.QuoteRequestViewModel.getValue(
		"priorLossDate",
	) as string);
	const [errorMessage, setErrorMessage] = useState("");
	const { history } = useRouter();

	function onClickBack(): void {
		history.push("/flooddamage");
	}

	const doSubmit = (e: any) => {
		e.preventDefault();
		if (!floodDamageViewModel.isModelValid()) {
			setErrorMessage("Problem");
		} else {
			setErrorMessage("No problem");
			history.push("/propertytype");
		}
	};

	function handleLossDateChange(date: Date): void {
		setLossDate(date.toString());
	}

	const formView = (
		<>
			<SterlingForm onSubmit={doSubmit} id="flooddamageForm">
				<GridSpaced container>
					<InputContainer item xs={12} md={10}>
						<GridSpaced container margin={"25px 0px 15px"}>
							<GridSpaced item xs={12} md={4}>
								<SterlingLabel fontSize={"24px"}>Date of loss:</SterlingLabel>
							</GridSpaced>
							<InputParent item xs={12} md={4}>
								<EditableInput<FloodDamageModel>
									placeholder={lossDate}
									viewModel={domainStores.QuoteRequestViewModel}
									type="date"
									className={"datePicker"}
									fieldName="priorLossDate"
									format="MM/DD/YYYY"
								/>
							</InputParent>
						</GridSpaced>
						<GridSpaced container margin={"0px 0px 15px"}>
							<GridSpaced item xs={12} md={4}>
								<SterlingLabel fontSize={"24px"}>Storm name:</SterlingLabel>
							</GridSpaced>
							<InputParent item xs={12} md={4}>
								<SterlingText<FloodDamageModel>
									viewModel={domainStores.QuoteRequestViewModel}
									placeholder={"e.g Katrina"}
									fieldName="priorLossStorm"
								/>
							</InputParent>
						</GridSpaced>
						<GridSpaced container margin={"0px 0px 15px"}>
							<GridSpaced item xs={12} md={4}>
								<SterlingLabel fontSize={"24px"}>Amount of loss:</SterlingLabel>
							</GridSpaced>
							<InputParent item xs={12} md={4}>
								<SterlingText<FloodDamageModel>
									viewModel={domainStores.QuoteRequestViewModel}
									placeholder={"$0.00"}
									fieldName="priorLossAmount"
									type={"number"}
									inputProps={{
										InputProps: {
											startAdornment: (
												<InputAdornment position="start">
													<div className="dollarSign">$</div>
												</InputAdornment>
											),
										},
									}}
								/>
							</InputParent>
						</GridSpaced>
					</InputContainer>
					<GridSpaced
						item
						xs={12}
						md={2}
						className={"submit panelIcon"}
						additionalcss={
							"display: flex; align-items: flex-end; margin-bottom: -30px; justify-content: flex-end; padding-right: 0px;"
						}
					>
						<Link component="button" variant="body2" onClick={doSubmit}>
							<div>{iconNextSubmit}</div>
							Next
						</Link>
					</GridSpaced>
				</GridSpaced>
			</SterlingForm>
		</>
	);

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<Grid container className="gridContainer">
							<Grid item xs={12}>
								<BackLink title={"Go Back"} className="backLink" onClick={onClickBack}>
									Back
								</BackLink>
								<SterlingMainBox additionalcss="margin:0px 50px 50px;">
									{iconFloodDamageGraphic}
									<h1>
										As the property has experienced flood damage in the past <br />
										we need the following information:
									</h1>
									{formView}
								</SterlingMainBox>
							</Grid>
						</Grid>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
