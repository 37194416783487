import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import {
	validate,
	validateOrReject,
	Contains,
	IsInt,
	Length,
	IsEmail,
	IsFQDN,
	IsDate,
	Min,
	Max,
	IsNotEmpty,
	ValidateIf,
	Equals,
} from "class-validator";

export class QuoteRequestModel extends ModelBase<QuoteRequestModel, QuoteRequestModelDTO> {
	@MobX.observable
	public id: string | undefined = undefined;

	@MobX.observable
	public quoteRequestId: number = 0;

	@MobX.observable // Not actually in response
	public customerName: string = "";

	@MobX.observable // Not actually in response
	public dateEffective: Date = new Date();

	@MobX.observable
	public address: string = "";

	@MobX.observable
	public city: string = "";

	@MobX.observable
	public county: string = "";

	@MobX.observable
	public state: string = "";

	@MobX.observable
	public zipcode: string = "";

	@MobX.observable
	public coverageDeductible: number = 0;

	@MobX.observable
	// @IsNotEmpty({ message: "Required" })
	// @IsInt({ message: "Replacement cost must be a valid number" })
	public valueBuilding: number = 0;

	@MobX.observable
	public coverageBuilding: number = 0;

	@MobX.observable
	public valueContents: number = 0;

	@MobX.observable
	public coverageContents: number = 0;

	@MobX.observable
	public buildingType: string = "";

	@MobX.observable
	public buildingUse: string = "";

	@MobX.observable
	public enclosureType: string = "";

	@MobX.observable
	@IsInt({ message: "Enclosure SqFt must be a valid number" })
	public enclosureSqft: number = 0;

	@MobX.observable
	public elevator: string = "0";

	@MobX.observable
	public frontalDune: string = "0";

	@MobX.observable
	public valueLossOfUse: number = 0;

	@MobX.observable
	public coverageLossOfUse: number = 0;

	@MobX.observable
	public priorLoss: string = "";

	@MobX.observable
	public priorLossDate: string | undefined = undefined;

	@MobX.observable
	@IsInt({ message: "Amount of loss must be a valid number" })
	public priorLossAmount: number = 0;

	@MobX.observable
	public priorLossStorm: string = "";

	@MobX.observable
	// @IsNotEmpty({ message: "Required" })
	// @IsInt({ message: "Year built must be a valid number" })
	public yearBuilt: number = 0;

	@MobX.observable
	// @IsNotEmpty({ message: "Required" })
	// @IsInt({ message: "Building SqFt must be a valid number" })
	public buildingSqft: number = 0;

	@MobX.observable
	public numberFloors: number = 1;

	@MobX.observable
	public floodZone: string = "";

	@MobX.observable
	public buildingDiagram: string = "";

	@MobX.observable
	public constructionType: string = "";

	@MobX.observable
	public createdDate: string = "";

	@MobX.observable
	public latitude: string = "";

	@MobX.observable
	public longitude: string = "";

	@MobX.observable
	public cbra: number = 0;

	public fromDto = (model: any) => {
		for (let key in model) {
			if (model.hasOwnProperty(key)) {
				if (this[key] instanceof Date) {
					this[key] = new Date(model[key]);
				} else {
					this[key] = model[key];
				}
			}
		}
	};
	public toDto = (model: QuoteRequestModel) => {};

	public resetAll = () => {
		this.id = undefined;
		this.quoteRequestId = 0;
		this.customerName = "";
		this.dateEffective = new Date();
		this.address = "";
		this.city = "";
		this.county = "";
		this.state = "";
		this.zipcode = "";
		this.coverageDeductible = 0;
		this.valueBuilding = 0;
		this.coverageBuilding = 0;
		this.valueContents = 0;
		this.coverageContents = 0;
		this.buildingType = "";
		this.buildingUse = "";
		this.enclosureType = "";
		this.enclosureSqft = 0;
		this.elevator = "0";
		this.frontalDune = "0";
		this.valueLossOfUse = 0;
		this.coverageLossOfUse = 0;
		this.priorLoss = "";
		this.priorLossDate = "";
		this.priorLossAmount = 0;
		this.priorLossStorm = "";
		this.yearBuilt = 0;
		this.buildingSqft = 0;
		this.numberFloors = 1;
		this.floodZone = "";
		this.buildingDiagram = "";
		this.constructionType = "";
		this.createdDate = "";
		this.latitude = "";
		this.longitude = "";
		this.cbra = 0;
	};
}

export interface QuoteRequestModelDTO {
	id: string;
	quoteRequestId: number;
	customerName: string;
	dateEffective: string;
	address: string;
	city: string;
	county: string;
	state: string;
	zipcode: string;
	coverageDeductible: number;
	valueBuilding: number;
	coverageBuilding: number;
	valueContents: number;
	coverageContents: number;
	buildingType: string;
	buildingUse: string;
	enclosureType: string;
	enclosureSqft: number;
	elevator: string;
	frontalDune: number;
	valueLossOfUse: number;
	coverageLossOfUse: number;
	priorLoss: boolean;
	priorLossDate: string;
	priorLossAmount: number;
	priorLossStorm: string;
	yearBuilt: number;
	buildingSqft: number;
	numberFloors: number;
	floodZone: string;
	buildingDiagram: string;
	constructionType: string;
	createdDate: string;
	latitude: string;
	longitude: string;
	cbra: number;
}
