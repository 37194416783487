import React from "react";
import { Button } from "@material-ui/core";
import { useDataApi } from "Core/Utils";
import { ApiResult } from "../../Core/Models";

export const User: React.FC = () => {
	const { doRequest } = useDataApi<ApiResult>();

	return (
		<div className="App">
			<header className="App-header">
				<h1>User</h1>
				<Button
					onClick={() => {
						doRequest("/api/account/getdata");
					}}
				>
					Test
				</Button>
			</header>
		</div>
	);
};
