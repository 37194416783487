import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { Centered, TextFieldMemo } from "Core/Components/General";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { nameofFactory } from "../../Core/Utils/Utils";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import Radio from "@material-ui/core/Radio";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";

const Main = styled.main`
	width: 600px;
	display: block;
	margin-left: auto;
	margin-right: auto;
`;

let viewModel = new RegisterUserViewModel();
const nameof = nameofFactory<RegisterUserModel>();

export const RegisterPage: React.FC = () => {
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {});

	const doSubmit = async (e: any) => {
		e.preventDefault();
		//isValid will check all fields to make sure they are in a valid state.
		if (!viewModel.isModelValid()) {
			setErrorMessage("Problem");
		} else {
			setErrorMessage("No problem");
			await viewModel.register();
		}
	};

	return (
		<Centered>
			<Main>
				<Paper>
					<Box padding="5px">
						<Box paddingTop={1} paddingBottom={1}>
							<Typography component="h1" variant="h1">
								Sign up
							</Typography>
						</Box>
						<form onSubmit={doSubmit}>
							{errorMessage !== "" && (
								<Typography variant="caption" style={{ color: "red" }}>
									{errorMessage}
								</Typography>
							)}
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									{/*Here we are using the optional generic type of the model to give us intellisense on the fieldname*/}
									<EditableInput<RegisterUserModel> viewModel={viewModel} label="First Name" fieldName="firstName" />
								</Grid>
								<Grid item xs={12} sm={6}>
									{/*Here we are using the "nameof" factory method instead to get intellisense on field name. Its up to you.*/}
									<EditableInput viewModel={viewModel} label="Last Name" fieldName="lastName" />
								</Grid>
								<Grid item xs={12} sm={12}>
									<EditableInput viewModel={viewModel} label="Email Address" fieldName="emailAddress" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput viewModel={viewModel} type="password" label="Password" fieldName="password" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput
										viewModel={viewModel}
										type="password"
										label="Confirm Password"
										fieldName="confirmPassword"
									/>
								</Grid>
								<Grid item xs={6}>
									<EditableInput viewModel={viewModel} label="Address Line 1" fieldName="address1" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput
										viewModel={viewModel}
										label="Address Line 2"
										inputProps={{ multiline: true }}
										fieldName="address2"
									/>
								</Grid>
								<Grid item xs={6}>
									<EditableInput viewModel={viewModel} label="City" fieldName="city" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput viewModel={viewModel} label="County" fieldName="county" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput viewModel={viewModel} label="PostCode" fieldName="postCode" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput
										type="select"
										label="Age"
										viewModel={viewModel}
										fieldName="age"
										selectItems={
											[
												{ value: 10, key: "Ten" },
												{ value: 20, key: "Twenty" },
												{ value: 30, key: "Thirty" },
											] as KeyValuePair[]
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<EditableInput
										type="radio"
										label="Gender"
										viewModel={viewModel}
										fieldName="gender"
										selectItems={
											[
												{ value: "female", key: "Female" },
												{ value: "male", key: "Male" },
												{ value: "other", key: "Other" },
											] as KeyValuePair[]
										}
									/>
									{/*	<FormControlLabel value="female" control={<Radio />} label="Female" />*/}
									{/*	<FormControlLabel value="male" control={<Radio />} label="Male" />*/}
									{/*	<FormControlLabel value="other" control={<Radio />} label="Other" />*/}
									{/*</EditableInput>*/}
								</Grid>
								<Grid item xs={6}>
									<EditableInput type="date" viewModel={viewModel} label="Date of Birth" fieldName="dob" />
								</Grid>
								<Grid item xs={6}>
									<EditableInput type="time" viewModel={viewModel} label="Time of Day" fieldName="tod" />
								</Grid>
								<Grid item xs={12}>
									<EditableInput viewModel={viewModel} label="Terms and Conditions" fieldName="terms" type="checkbox" />
								</Grid>
							</Grid>
							<Button type="submit" fullWidth variant="contained" color="primary">
								Sign Up
							</Button>
						</form>
					</Box>
				</Paper>
			</Main>
		</Centered>
	);
};
