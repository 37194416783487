import { action, computed, observable, runInAction } from "mobx";
import { GetQuoteModel, GetQuoteModelDTO } from "../Models/GetQuoteModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { QuoteResponseModel } from "Custom/Models";
import { Server } from "Custom/Globals/AppUrls";

export class GetQuoteViewModel extends ViewModelBase<GetQuoteModel> {
	public ResponseModel = new QuoteResponseModel();
	constructor() {
		super(new GetQuoteModel());
		this.setDecorators(GetQuoteModel);
	}

	@action
	public GetQuotesAsync = async (): Promise<boolean> => {
		let apiResult = await this.Post<QuoteResponseModel>(Server.Api.Quotes.GetQuote, this.model);
		runInAction(() => {
			if (apiResult.wasSuccessful) {
				//this.Rentals.clear();
				// apiResult.payload.forEach((item: [], index: number) => {
				// 	let domainModel = new RentalsModel();
				// 	domainModel.fromDto(item);
				// 	this.Rentals.push(domainModel);
				// });
				this.ResponseModel = new QuoteResponseModel();
				this.ResponseModel.fromDto(apiResult.payload);
			}
		});

		return apiResult.wasSuccessful;
	};

	public isFieldValid(fieldName: keyof GetQuoteModel & string, value: any): boolean {
		const { isValid, errorMessage } = this.validateDecorators(fieldName);

		//You need to this two properties after validation
		this.model.setError(fieldName, errorMessage);
		this.model.setValid(fieldName, isValid);

		return isValid;
	}
}
