import React, { useContext, useState } from "react";
import { useRouter } from "../../Core/Utils";

import { AppHeaderStart, BackLink, HomeMain, MainRoot } from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Link, Grid } from "@material-ui/core";

import { useMediaQuery } from "Core/Utils/Media/mediaQuery";

import { ResidenceUsage } from "Custom/Components/PropertyTypes/ResidenceUsage";
import { StoresContext, Stores } from "../Stores";
import { ProcessFlowStore } from "../Stores/Domain/ProcessFlowStore";

export const PropertyResidenceUsage: React.FC = () => {
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;
	const [resident, setResident] = useState<boolean>(true);
	const { history } = useRouter();

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const nextStep = (callbackVal: number) => {
		history.push("/foundationtype");
	};

	function prevStep(): void {
		history.push("/primaryresidence");
	}

	return (
		<>
			<MainRoot>
				<HomeMain role="main">
					<AppHeaderStart>
						<Grid container className="gridContainer">
							<Grid item xs={12}>
								{!isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
								<ResidenceUsage residenceUsage={nextStep} propertyTitlePart={ProcessFlowStoresContext.PropertyType} />
								{isMobile && (
									<>
										<BackLink title={"Go Back"} className="backLink" onClick={prevStep}>
											Back
										</BackLink>
									</>
								)}
							</Grid>
						</Grid>
					</AppHeaderStart>
				</HomeMain>
			</MainRoot>
		</>
	);
};
