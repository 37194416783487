import "./Home.css";

import {
	AppHeaderStart,
	BackLink,
	GridSpaced,
	H1TitleAndImg,
	HomeMain,
	MainRoot,
} from "../../Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Box, Fade, Grid, Link } from "@material-ui/core";
import React, { useContext } from "react";
import { Stores, StoresContext, StoresInstance } from "../Stores";
import { iconMapPin4Title, iconNoBig, iconYesBig } from "Content/EmbeddedSVGs";

import { GoogleMap } from "../Components/Map";
import { MapStore } from "../Stores/Domain/MapStore";
import { ProcessFlowStore } from "Custom/Stores/Domain";
import { Link as RouterLink } from "react-router-dom";
import { isNullorWhiteSpace } from "../../Core/Utils/Validation/isNullorWhiteSpace";
import { useMediaQuery } from "../../Core/Utils/Media";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../Core/Utils";

export const Address: React.FC = () => {
	const mapStoresContext: MapStore = useContext<Stores>(StoresContext).domain.MapStore;
	const ProcessFlowStoresContext: ProcessFlowStore = useContext<Stores>(StoresContext).domain.ProcessFlowStore;
	const domainStores = StoresInstance.domain;

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;
	const { history } = useRouter();

	let pageRoute: string = "";
	pageRoute = !isNullorWhiteSpace(mapStoresContext.pageSource) ? "/address" : mapStoresContext.pageSource;

	function onClickBack(): void {
		domainStores.QuoteRequestViewModel.setValue("address", "");
		domainStores.QuoteRequestViewModel.setValue("city", "");
		domainStores.QuoteRequestViewModel.setValue("county", "");
		domainStores.QuoteRequestViewModel.setValue("state", "");
		domainStores.QuoteRequestViewModel.setValue("zipcode", "");
		domainStores.QuoteRequestViewModel.setValue("latitude", "");
		domainStores.QuoteRequestViewModel.setValue("longitude", "");
		mapStoresContext.ResetLocation();
		ProcessFlowStoresContext.ResetQuote();
		history.push("/");
	}

	function onClickYes(): void {
		history.push("/flooddamage");
	}

	const googleMapPlacement = (
		<>
			<GoogleMap />
		</>
	);

	const desktopView = (
		<>
			<Grid item xs={12} md={6}>
				{googleMapPlacement}
			</Grid>
			<GridSpaced item xs={12} md={6}>
				<BackLink title={"Go Back"} onClick={onClickBack}>
					Back
				</BackLink>
				<Box className="rightPanelHeader">
					<Grid container>
						<Grid item xs={12} md={12} className="alignCenter">
							<H1TitleAndImg additionalcss="display: flex; justify-content: center;">
								<div className="rightPanelHeaderIcon">{iconMapPin4Title}</div>
								{/* <img src={iconMapPin4Title} className="rightPanelHeaderIcon" alt={"Property location"} /> */}
								Property location
							</H1TitleAndImg>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} md={12}>
							{mapStoresContext.hasAddress && (
								<>
									<h2 className="rightPanelAddress">{mapStoresContext.address}</h2>
								</>
							)}
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={2} md={2}></Grid>
						<Grid item xs={8} md={8} className="alignCenter">
							<p className="rightPanelText">
								Is this the property location? <br /> If not please click and drag the location pin to the correct
								place.
							</p>
						</Grid>
						<Grid item xs={2} md={2}></Grid>
					</Grid>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={4} md={4} className="panelIcon">
							<div>
								<Link component="button" variant="body2" onClick={onClickYes}>
									{iconYesBig}
								</Link>
							</div>
							<Link component="button" variant="body2" onClick={onClickYes}>
								YES
							</Link>
						</Grid>
						<Grid item xs={4} md={4} className="panelIcon">
							<div>
								<Link component="button" variant="body2" onClick={onClickBack}>
									{iconNoBig}
								</Link>
							</div>
							<Link component="button" variant="body2" onClick={onClickBack}>
								NO
							</Link>
						</Grid>
					</Grid>
				</Box>
			</GridSpaced>
		</>
	);

	const mobileView = (
		<>
			<GridSpaced item xs={12} md={6} margin={"15px 0px"} padding={"0"}>
				<Box className="rightPanelHeader">
					<Grid container>
						<Grid item xs={12} md={12} className="alignCenter">
							<H1TitleAndImg additionalcss="display: flex; justify-content: center;">
								<div className="rightPanelHeaderIcon">{iconMapPin4Title}</div>
								{/* <img src={iconMapPin4Title} className="rightPanelHeaderIcon" alt={"Property location"} /> */}
								Property location
							</H1TitleAndImg>
						</Grid>
					</Grid>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={8} md={12}>
							{mapStoresContext.hasAddress && (
								<>
									<h2 className="rightPanelAddress">{mapStoresContext.address}</h2>
								</>
							)}
						</Grid>
					</Grid>
					<GridSpaced container direction="row" justify="center" alignItems="center" margin={"0"} padding={"0"}>
						<Grid item xs={10} md={8} className="alignCenter">
							<p className="rightPanelText">
								<strong>Is this the property location?</strong>
								<br />
								If not please click and drag the location pin to the correct place.
							</p>
						</Grid>
					</GridSpaced>
				</Box>
			</GridSpaced>
			<GridSpaced item xs={12} md={6} margin={"0"} padding={"0"}>
				{googleMapPlacement}
			</GridSpaced>
			<Grid item xs={12} md={6}>
				<Box>
					<GridSpaced
						container
						direction="row"
						justify="center"
						alignItems="center"
						margin={"0"}
						padding={"0px 0px 0px 15px"}
					>
						<Grid item xs={3} md={6} className="panelIcon">
							<div>
								<Link component="button" variant="body2" onClick={onClickYes}>
									{iconYesBig}
								</Link>
							</div>
							YES
						</Grid>
						<Grid item xs={3} md={6} className="panelIcon">
							<div>
								<Link component="button" variant="body2" onClick={onClickBack}>
									{iconNoBig}
								</Link>
							</div>
							NO
						</Grid>
					</GridSpaced>
				</Box>
				<Box>
					<BackLink title={"Go Back"} onClick={onClickBack}>
						Back
					</BackLink>
				</Box>
			</Grid>
		</>
	);

	return useObserver(() => (
		<MainRoot>
			<HomeMain role="main">
				<AppHeaderStart>
					<GridSpaced container className="gridContainer" margin={"0"} justify="center">
						{!isMobile && desktopView}
						{isMobile && mobileView}
					</GridSpaced>
				</AppHeaderStart>
			</HomeMain>
		</MainRoot>
	));
};
