import {
	AppHeaderStart,
	BackLink,
	GridSpaced,
	H1TitleAndImg,
	HomeMain,
	MainRoot,
} from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import { Grid, Link } from "@material-ui/core";
import React, { useContext, useState } from "react";
import {
	iconBath,
	iconElevator,
	iconEntrance,
	iconGameRoom,
	iconGarage,
	iconHouse4Tile,
	iconLivingSpace,
	iconNextArrow,
	iconStorage,
	iconUnfinished,
} from "../../../Content/EmbeddedSVGs";

import { StoresInstance } from "../../Stores";
import { useMediaQuery } from "../../../Core/Utils/Media";

interface IProps {
	propertyFeaturesType: (chosenVal: number) => void;
	propertyTitlePart: string;
}

export const PropertyFeatures: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;

	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	const toArray = (ValuesList: string): string[] => {
		return ValuesList.split(",");
	};

	const [selectedItems, setSelectedItems] = useState<string[]>(
		toArray(domainStores.QuoteRequestViewModel.getValue("enclosureType") as string),
	);

	const isSelected = (clickVal: string) => {
		let retVal: string = "panelIcon";
		if (selectedItems.includes(clickVal)) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const [elevator, setElevator] = useState<string>(isSelected("Elevator"));
	const [unfinished, setUnfinished] = useState<string>(isSelected("Unfinished"));
	const [storage, setStorage] = useState<string>(isSelected("Storage"));
	const [garage, setGarage] = useState<string>(isSelected("Garage"));
	const [entrance, setEntrance] = useState<string>(isSelected("Entrance"));
	const [bath, setBath] = useState<string>(isSelected("Bath"));
	const [gameroom, setGameRoom] = useState<string>(isSelected("Game room"));
	const [livingspace, setLivingSpace] = useState<string>(isSelected("Living space"));

	const panelActive = (panelValue: string) => {
		let retVal: string = "panelIcon";
		if (retVal === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: string) => {
		domainStores.QuoteRequestViewModel.setValue("enclosureType", selectedItems.toString());
		props.propertyFeaturesType(6);
	};

	const onClickSelect = (clickVal: string) => {
		let localArray: string[] = selectedItems;
		if (!localArray.includes(clickVal)) {
			//add chosen item to the array
			localArray.push(clickVal);
			setSelectedItems(localArray);
		} else {
			//remove chosen item from array
			let indexVal: number = localArray.indexOf(clickVal);
			delete localArray[indexVal];
			setSelectedItems(localArray);
		}
		switch (clickVal) {
			case "Elevator":
				setElevator(panelActive(elevator));
				break;
			case "Unfinished":
				setUnfinished(panelActive(unfinished));
				break;
			case "Storage":
				setStorage(panelActive(storage));
				break;
			case "Garage":
				setGarage(panelActive(garage));
				break;
			case "Entrance":
				setEntrance(panelActive(entrance));
				break;
			case "Bath":
				setBath(panelActive(bath));
				break;
			case "Game room":
				setGameRoom(panelActive(gameroom));
				break;
			case "Living space":
				setLivingSpace(panelActive(livingspace));
				break;
		}
	};

	const rowOne = (
		<>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={3} className={elevator}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Elevator")}>
							{iconElevator}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Elevator")} className="panelLink">
						Elevator
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={unfinished}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Unfinished")}>
							{iconUnfinished}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Unfinished")} className="panelLink">
						Unfinished
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={storage}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Storage")}>
							{iconStorage}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Storage")} className="panelLink">
						Storage
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={garage}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Garage")}>
							{iconGarage}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Garage")} className="panelLink">
						Garage
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const rowTwo = (
		<>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={3} className={entrance}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Entrance")}>
							{iconEntrance}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Entrance")} className="panelLink">
						Entrance
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={bath}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Bath")}>
							{iconBath}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Bath")} className="panelLink">
						Bath
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={gameroom}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Game room")}>
							{iconGameRoom}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Game room")} className="panelLink">
						Game room
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} className={livingspace}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickSelect("Living space")}>
							{iconLivingSpace}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickSelect("Living space")} className="panelLink">
						Living space
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={isMobile ? "text-align: center;" : "text-align: center; svg {width: 64px;}"}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 30px"}
			>
				{iconHouse4Tile}
				Please select all that apply to the enclosure
			</H1TitleAndImg>
		</>
	);

	return (
		<>
			<GridSpaced item xs={12}>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={8}>
						{headingTitle}
					</GridSpaced>
				</GridSpaced>
				<GridSpaced
					container
					direction="row"
					justify="center"
					alignItems="center"
					margin={isMobile ? "" : "0px 0px 0px 40px"}
				>
					<GridSpaced item xs={12} md={10} additionalcss={isMobile ? "max-width: 90%;" : ""}>
						{rowOne}
						{rowTwo}
					</GridSpaced>
					<GridSpaced item xs={12} md={2} margin={"30px 0px"} additionalcss={isMobile ? "text-align: center;" : ""}>
						<div>
							<Link component="button" variant="body2" onClick={() => onClickDecide("")}>
								{iconNextArrow}
							</Link>
						</div>
						Next
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	);
};
