import { QuoteModel } from "../Models/QuoteModel";
import * as MobX from "mobx";

export class QuoteDeductibleListModel {
	@MobX.observable
	public quotes1000: QuoteModel[] = [];
	public quotes2000: QuoteModel[] = [];
	public quotes5000: QuoteModel[] = [];
	public quotes10000: QuoteModel[] = [];
	public quotes25000: QuoteModel[] = [];
	public quotes50000: QuoteModel[] = [];
	public excess: QuoteModel[] = [];

	public fromDto = (model: QuoteDeductibleListModelDTO) => {};
	public toDto = (model: QuoteDeductibleListModel) => {};
}

export interface QuoteDeductibleListModelDTO {
	quotes1000: QuoteModel[];
	quotes2000: QuoteModel[];
	quotes5000: QuoteModel[];
	quotes10000: QuoteModel[];
	quotes25000: QuoteModel[];
	quotes50000: QuoteModel[];
	excess: QuoteModel[];
}
