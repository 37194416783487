import { observable, action, computed, IComputedValue } from "mobx";
import { IModel } from "./IModel";
import { InputValuesDirty, InputValuesTouched, InputValuesErrors, InputValuesValidity } from "./IModel";
import _ from "lodash";

export abstract class ModelBase<T, TDTO> implements IModel<T> {
	private static self = {} as any;
	@observable public Errors = {} as InputValuesErrors<T>;
	@observable public Valid = {} as InputValuesValidity<T>;
	@observable public Dirty = {} as InputValuesDirty<T>;
	@observable public Touched = {} as InputValuesTouched<T>;

	abstract fromDto(model: TDTO): void;
	abstract toDto(model: T): void;

	constructor() {
		ModelBase.self = this;
		//Loop through added properties setting their default values
		for (let prop in this) {
			if (prop != "Errors" && prop != "Valid" && prop != "Dirty" && prop != "Touched") {
				// @ts-ignore
				this.Errors[prop] = "";
				// @ts-ignore
				this.Valid[prop] = true;
				// @ts-ignore
				this.Dirty[prop] = false;
				// @ts-ignore
				this.Touched[prop] = false;
			}
		}
	}

	@action
	public setValue(fieldName: string, value: string | number | boolean | Date): void {
		this[fieldName] = value;
	}

	public getValue(fieldName: string): string | boolean | Date {
		return _.get(this, fieldName);
	}

	@action
	public setError(fieldName: string, value: string): void {
		// @ts-ignore
		this.Errors[fieldName] = value;
	}

	public getError(fieldName: string): string {
		return this.Errors[fieldName];
	}

	@action
	public setValid(fieldName: string, value: boolean): void {
		// @ts-ignore
		this.Valid[fieldName] = value;
	}

	public getValid(fieldName: string): boolean {
		return this.Valid[fieldName];
	}

	@action
	public setDirty(fieldName: string, value: boolean): void {
		// @ts-ignore
		this.Dirty[fieldName] = value;
	}

	public getDirty(fieldName: string): boolean {
		return this.Dirty[fieldName];
	}

	@action
	public setTouched(fieldName: string, value: boolean): void {
		// @ts-ignore
		this.Touched[fieldName] = value;
	}

	public getTouched(fieldName: string): boolean {
		return this.Touched[fieldName];
	}
}
