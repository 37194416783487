import { GridSpaced, H1TitleAndImg } from "Shoothill.Components/StylesAppSpecific/HomeStyling";
import React, { useContext } from "react";
import { iconElevatedPiers4Tile, iconHouse4Tile, iconNoBig, iconYesBig } from "../../../Content/EmbeddedSVGs";

import { Link } from "@material-ui/core";
import { StoresInstance } from "../../Stores";
import { useMediaQuery } from "../../../Core/Utils/Media";

interface IProps {
	piersEnclosed: (chosenVal: boolean) => void;
	propertyTitlePart: string;
}

export const EnclosedPiers: React.FC<IProps> = props => {
	const domainStores = StoresInstance.domain;
	let isMobile: boolean;
	let isTablet: boolean;
	let smallerThanTab: boolean;
	isMobile = useMediaQuery("screen and (max-width: 767px)") ? true : false;
	isTablet = useMediaQuery("screen and (max-width: 1024px)") ? true : false;

	let activeValue: string = domainStores.QuoteRequestViewModel.getValue("enclosureType") as string;

	const panelActive = (panelValue: any) => {
		let retVal: string = "panelIcon";

		if (activeValue === panelValue) {
			retVal += " panelSelected";
		}
		return retVal;
	};

	const onClickDecide = (clickVal: boolean) => {
		domainStores.QuoteRequestViewModel.setValue("enclosureType", clickVal ? "Piers enclosed" : "");
		domainStores.QuoteRequestViewModel.setValue("buildingDiagram", clickVal ? "6" : "5");
		props.piersEnclosed(clickVal);
	};

	const rowOne = (
		<>
			<GridSpaced container direction="row" justify="center" alignItems="center">
				<GridSpaced item xs={3} md={2} className={panelActive("Piers enclosed")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide(true)}>
							{iconYesBig}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide(true)} className="panelLink">
						YES
					</Link>
				</GridSpaced>
				<GridSpaced item xs={3} md={2} className={panelActive("Piers exposed")}>
					<div>
						<Link component="button" variant="body2" onClick={() => onClickDecide(false)}>
							{iconNoBig}
						</Link>
					</div>
					<Link component="button" variant="body2" onClick={() => onClickDecide(false)} className="panelLink">
						NO
					</Link>
				</GridSpaced>
			</GridSpaced>
		</>
	);

	const headingTitle = (
		<>
			<H1TitleAndImg
				additionalcss={isMobile ? "text-align: center;" : "text-align: center; svg {width: 64px;}"}
				margin={isMobile ? "22px 0px 0px" : "0px 0px 30px"}
			>
				{iconElevatedPiers4Tile}
				Do the elevated piers have an enclosure?
			</H1TitleAndImg>
		</>
	);

	return (
		<>
			<GridSpaced item xs={12}>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12}>
						{headingTitle}
					</GridSpaced>
				</GridSpaced>
				<GridSpaced container direction="row" justify="center" alignItems="center">
					<GridSpaced item xs={12} md={12}>
						{rowOne}
					</GridSpaced>
				</GridSpaced>
			</GridSpaced>
		</>
	);
};
