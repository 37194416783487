export const Client = {
	Root: "/",
};

export const Server = {
	Api: {
		Quotes: {
			GetQuote: "./api/quote/GetQuotes",
			GetExistingQuote: "./api/quote/GetExistingQuote",
			BindQuote: "./api/quote/PostQuote",
		},
		Account: {
			Login: "./api/account/login",
			Logout: "./api/account/logout",
			ResetPassword: "./api/account/resetpassword",
			SetPassword: "./api/account/setpassword",
		},
		LiteFormEmail: {
			PostLiteFormAsync: "/api/LiteForm/PostLiteFormAsync",
		},
	},
};

export const Admin = {
	Root: "/admin",
};
