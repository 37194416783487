import styled, { keyframes } from "styled-components";
import { Button, Box, Grid, Paper, Container } from "@material-ui/core";

export const AppHeader: any = styled(Box)`
	height: 0;
	min-height: 100px;
	@media screen and (min-width: 1040px) {
		min-height: 100px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	background-position: 0 40%;
	max-width: 100%;
	margin: 0 auto;
`;

export const StyledButton = styled.button`
	font-size: 1em;
	margin: 1em;
	padding: 0.25em 1em;
	border-radius: 3px;
	background-color: cyan;

	/* Color the border and text with theme.main */
	color: ${props => props.theme.main};
	border: 2px solid ${props => props.theme.main};
`;

export const Uppercase = styled.div`
	* {
		text-transform: uppercase;
	}
`;

export const Centered = styled.div`
	text-align: center;
`;

export interface StyledProps {
	pb?: string;
	pt?: string;
	mt?: string;
	mb?: string;
	inline?: boolean;
	color?: string;
}
//&& bumps up the importance of the style to override global styles. The more you use the higher it will be
export const Header4 = styled.h4<StyledProps>`
	text-shadow: rgb(255, 255, 255) 2px 2px 5px;
	text-transform: uppercase;
	padding-bottom: ${props => props.pb};
	padding-top: ${props => props.pt};
	margin-top: ${props => props.mt};
	margin-bottom: ${props => props.mb};
	color: ${props => props.color};
	margin: 0px;
	font-size: 52px;
	font-weight: 700;
	display: ${props => (props.inline ? "inline" : "block")};
`;

export const FancyBox = styled(Box)`
	display: inline-block;
	transition: background-color 0.5s ease-in-out;
	&:hover {
		background-color: #f2893e;
		/* animation: color 4s ease-in-out; */
	}
`;

export const GridContainer = styled(Container)`
	padding: 0px;
`;

export const BoxMiddle = styled(Box)`
	text-align: center;
`;

export const ShoothillPaper = styled(Box)`
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	/* color: rgba(0, 0, 0, 0.54); */
	color: ${props => props.theme.palette.text.secondary};
	border-radius: 4px;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const PaperMiddle = styled(ShoothillPaper)`
	text-align: center;
`;

export const PaperFullHeight = styled(Paper)`
	height: 90vh;
	padding-left: 10px;
`;

export const LinkBox = styled(Box)`
	padding: 5px;
	cursor: pointer;
`;

export const RightSide = styled(Grid)`
	overflow: auto;
	height: 90vh;
`;

export const BounceAnimation = keyframes`
	0% { margin-bottom: 0; }
	50% { margin-bottom: 15px }
	100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
	display: flex;
	align-items: flex-end;
`;

export const Dot = styled.div<any>`
	background-color: black;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	/* Animation */
	animation: ${BounceAnimation} 0.5s linear infinite;
	animation-delay: ${props => props.delay};
`;

export const HeaderStyles: any = styled(Box)`
	height: 0;
	min-height: 80px;
	@media screen and (min-width: 1040px) {
		min-height: 80px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	background-position: 0 40%;
	max-width: 100%;
	margin: 0 auto;
`;

export const Row1040: any = styled(Grid)`
	max-width: 1040px;
	margin: 0 auto;
	padding: 0;
`;

export const BtnGrid: any = styled(Grid)`
	margin: 0 auto;
	button {
		max-width: 295px;
	}
`;

export const LeftBox: any = styled(Box)`
	margin: 20px 22px 20px 61px;
	@media (max-width: 1040px) {
		margin: 10px auto;
		max-width: 295px;
	}
`;

export const RightBox: any = styled(LeftBox)`
	margin: 20px 61px 20px 22px;
`;

export const Row1076: any = styled(Row1040)`
	max-width: 1076px;
`;

export const BRFormBox: any = styled(Box)`
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 16px;
	border: none;
	margin: 30px auto 200px;
	@media screen and (max-width: 1024px) {
		margin: 0px auto 60px;
	}
`;
