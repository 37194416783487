import styled from "styled-components";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Link, Box, Grid, TextField } from "@material-ui/core";
import * as MUI from "@material-ui/core";

export const useAdminStyles: any = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	marginTop: {
		marginTop: "40px",
	},
	adminBtn: {
		borderRadius: "10px",
		border: "none",
		backgroundColor: "#000000",
		width: "100%",
		height: "54px",
		color: "#FFFFFF",
		"&:hover": {
			backgroundColor: "#000000",
			color: "#000000",
		},
		textTransform: "none",
		fontWeight: "bold",
		marginRight: "0px",
		marginLeft: "0px",
		maxWidth: "47%",
	},
	activeImage: {
		position: "relative",
		minWidth: "1px",
		display: "table",
		width: "100%",
		maxWidth: "500px",
	},
	removeImage: {
		position: "absolute",
		top: "0",
		right: "0",
		border: "solid 1px red",
		backgroundColor: "red",
		color: "#FFFFFF",
		padding: "2px 8px",
		textTransform: "capitalize",
		cursor: "pointer",
	},
	modalScroll: {
		overflowY: "auto",
	},
	subTitle: {
		marginBottom: "18px",
		paddingBottom: "0px !important",
	},
	form: {
		paddingBottom: "140px",
	},
	cancelButtonAdmin: {
		backgroundColor: "#222120",
		"&:hover": {
			backgroundColor: "#222120",
			color: "#FFFFFF",
		},
	},
	adminHeading: {
		color: "#000000",
		fontSize: "50px",
		fontWeight: "bold",
	},
	adminHeader: {
		position: "relative",
	},
	tabsContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		width: "100%",
		display: "flex",
		justifyContent: "center",
		paddingLeft: "24px",
		paddingRight: "24px",
	},
	tabsWidth: {
		width: "100%",
		maxWidth: "1040px",
	},
	liveRadiogroup: {
		marginLeft: "-10px",
	},
	icon: {
		backgroundColor: "#ffffff",
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
		width: "30px",
		height: "30px",
		color: "#A2A2A2",
		borderRadius: "50%",
		strokeWidth: 1,
		border: "2px solid #A2A2A2",
	},
	checkedIcon: {
		borderRadius: "50%",
		width: "30px",
		height: "30px",
		backgroundColor: "#ffffff",
		border: "2px solid #A2A2A2",
		"&:before": {
			display: "block",
			width: "22px",
			height: "22px",
			content: '""',
			marginLeft: "2px",
			marginTop: "2px",
			backgroundColor: "black",
			borderRadius: "50%",
		},
	},
}));

export const TabsCustom: any = styled(Tabs)`
	.MuiButtonBase-root {
		color: #222120;
		opacity: 0.8;
		background: #d0d0d0 0% 0% no-repeat;
		font-weight: bold;
		font-size: 14px;
		width: 147px;
		height: 53px;
		margin: 0;
		border-radius: 0px;
		z-index: 1;

		hover {
			background: #d0d0d0 0% 0% no-repeat;
		}
	}

	.MuiButtonBase-root.Mui-selected {
		color: #000000;
		opacity: 1;
		background: #ffffff 0% 0% no-repeat;
		/* box-shadow: 0px 0px 2px 3px #00000029; */
		z-index: 2;
		border-bottom: none;

		hover {
			background: #ffffff 0% 0% no-repeat;
		}
	}
`;

export const CreateLink: any = styled(Link)`
	background-color: #000000;
	border-radius: 10px;
	font-size: 16px;
	text-align: center;
	color: #f3f3f3;
	width: 178px;
	height: 54px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CreateBox: any = styled(Box)`
	display: flex;
	justify-content: flex-end;
	margin-top: 0px;
	margin-bottom: 61px;
	margin-left: 10px;
`;

export const ControlsContainer: any = styled(Grid)`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;
`;

export const WhiteToBlueGradAdmin: any = styled.div`
	display: flex;
`;

export const BlueGraditentBoxAdmin: any = styled(Box)`
	background-image: linear-gradient(0deg, rgba(65, 144, 202, 1) 2%, rgba(255, 255, 255, 0.05) 30%);
`;

export const ArticleBoxAdmin: any = styled(Box)`
	text-align: left;
	margin: 0 0 180px;
	padding: 0;
	display: inline-block;
	white-space: pre-line;
	font-size: 18px;

	p,
	h2,
	h3 {
		margin: 0 0 10px;
		padding: 0;
	}

	h2,
	h3 {
		color: #000000;
	}
`;

export const ImageBoxAdmin: any = styled(Box)`
	margin: 0;
	padding: 0 0 40px 40px;
	min-width: 1px;
	max-width: 520px;
	float: right;
	overflow: hidden;
	img {
		width: 100%;
		height: auto;
	}
`;

export const DateSpaceTypoAdmin: any = styled(Box)`
	font-size: 18px;
	margin: 46px 0 29px;
`;

export const ArticleGridAdmin: any = styled(Grid)``;

export const TempBox: any = styled(Box)`
	display: flex;
	flex-direction: column;
	margin: 15% auto;
	width: 60%;
	padding: 10px;
	background-color: #ffffff;
	border: solid 2px #000000;
`;

export const TextFieldAdmin: any = styled(TextField)`
	background-color: #ffffff;
	border: 1px solid #e0daf0;
	height: 54px;
	input {
		margin: 0px !important;
		font-size: 21px !important;
	}
`;

export const GridContainerAdmin: any = styled(Grid)`
	padding-top: 24px;
`;

export const ButtonGridAdmin: any = styled(Grid)`
	width: 100%;
`;

export const ButtonBoxAdmin: any = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const RadioGroup: any = styled(MUI.RadioGroup)`
	flex-direction: row;

	span {
		color: black;
	}
`;
